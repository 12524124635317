import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import moment from "moment";

const ViewAgnihotra = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const agnihotraData = location.state.agnihotraData;

  return (
    <div className="outletPadding">
      <Container>
        <Button className="primaryBtn" onClick={() => navigate(-1)}>
          <Icon.ArrowLeft className="me-2" />
          Back
        </Button>
        <Row className="mb-2 mt-4">
          <h4 className="mb-0 text-center">
            <Icon.Person className="me-1" /> Agnihotra Details
          </h4>
        </Row>
        <Row className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper">
            <table data-aos="fade-up" data-aos-delay="200">
              <tbody>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Title in English
                  </th>
                  <td>{agnihotraData?.titleLang?.en ? agnihotraData?.titleLang?.en : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Title in Marathi
                  </th>
                  <td>{agnihotraData?.titleLang?.mr ? agnihotraData?.titleLang?.mr : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Title in Hindi
                  </th>
                  <td>{agnihotraData?.titleLang?.hin ? agnihotraData?.titleLang?.hin : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Header in English
                  </th>
                  <td>{agnihotraData?.titleHeadLang?.en ? agnihotraData?.titleHeadLang?.en : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Header in Marathi
                  </th>
                  <td>{agnihotraData?.titleHeadLang?.mr ? agnihotraData?.titleHeadLang?.mr : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Header in Hindi
                  </th>
                  <td>{agnihotraData?.titleHeadLang?.hin ? agnihotraData?.titleHeadLang?.hin : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Image Url
                  </th>
                  <td>{agnihotraData?.imageURL ? agnihotraData?.imageURL : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Video Link
                  </th>
                  <td>{agnihotraData?.videoURL ? agnihotraData?.videoURL : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Agnihotra Type
                  </th>
                  <td>{agnihotraData?.type ? agnihotraData?.type : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Duration
                  </th>
                  <td>{agnihotraData?.duration ? agnihotraData?.duration : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Start Date
                  </th>
                  <td>{agnihotraData?.startTime ? moment(agnihotraData?.startTime).format("DD-MM-YY") : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    End Date
                  </th>
                  <td>{agnihotraData?.endTime ? moment(agnihotraData?.endTime).format("DD-MM-YY") : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Created By
                  </th>
                  <td>{agnihotraData?.createdById ? agnihotraData?.createdById : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Description in English
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {agnihotraData?.descriptionLang?.en ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: agnihotraData?.descriptionLang?.en,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Description in Marathi
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {agnihotraData?.descriptionLang?.mr ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: agnihotraData?.descriptionLang?.mr,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row >
      </Container >
    </div >
  );
};

export default ViewAgnihotra;
