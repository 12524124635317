import React, { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import CloudeFlareVideoUpload from '../../../../Utils/CloudeFlareVideoUpload';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const AddVideoModel = ({ show, onHide, loader, setLoader, getEventList, eventid, eventDetails, title }) => {
    const userState = useSelector((state) => state?.cmsLogin?.userData.data);
    const [Video, SetVideo] = useState([]);
    const [cloudFlareRes, setCloudFlareRes] = useState('');
    // Error variables
    const [error, setErrors] = useState({});

    const uploadOnCloudFlare = async (data) => {
        let arr = [];
        arr = [...Video];
        arr.push(data?.preview);
        SetVideo(arr);
        setCloudFlareRes(data);
        setErrors({});
    };

    const remove = (index) => {
        const updatedVideos = Video.filter((_, i) => i !== index);
        SetVideo(updatedVideos);
        if (updatedVideos.length === 0) {
            setErrors({ Video: "*Please upload at least one video" });
        }
    };

    // for validation
    const validate = () => {
        let newErrors = {};
        if (Video.length === 0) {
            newErrors.Video = "*Please upload at least one video";
        }
        setErrors(newErrors);
        if (newErrors.Video) {
            document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
        }
        return Object.keys(newErrors).length === 0;
    };

    // for add event video
    const addEventVideo = async (url, payload) => {
        const isvalid = validate();
        if (!isvalid) return;
        setLoader(true);
        try {
            const result = await fetch(process.env.REACT_APP_BASE_URL + url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState?.token}` },
                body: JSON.stringify(payload)
            });

            const response = await result.json();
            if (response) {
                toast.success("Saved Successfully");
                setLoader(false);
                getEventList();
                onHide();
                SetVideo([]);
                setErrors({});
            }
        } catch (Err) {
            setLoader(false);
        }
    };

    return (
        <Modal size='md' show={show} backdrop='static' keyboard={false} centered onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h4 className='mb-0'>
                        <Icon.CameraVideo size={20} className='me-2' />{title}
                    </h4>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row className='justify-content-center'>
                    <Col md={12}>
                        <Form.Group className='mb-0'>
                            <Form.Label>Video<span>*</span></Form.Label> <br />
                            {Video?.length === 0 ?
                                <>
                                    <CloudeFlareVideoUpload onFileUpload={uploadOnCloudFlare} orientation="landscape" />
                                    {error.Video && <p className="errMsg">{error.Video}</p>}
                                </>
                                :
                                <div className="d-flex mt-3" style={{ flexWrap: "wrap" }}>
                                    {Video && Video.map((video, index) => (
                                        <Card key={index} className="d-flex uploadedFile justify-content-center align-items-center me-4">
                                            <a target="_blank" href={video}>
                                                <Icon.PlayBtnFill style={{ width: 60, height: 60 }} />
                                            </a>
                                            <Icon.XCircleFill
                                                className="removeIcon"
                                                size={18}
                                                onClick={() => remove(index)}
                                            />
                                        </Card>
                                    ))}
                                </div>
                            }
                        </Form.Group>
                    </Col>
                </Row>
            </Modal.Body>

            <Modal.Footer>
                {loader ? (
                    <Spinner variant='primary' />
                ) :
                    <Button className="primaryBtn me-4"
                        onClick={() => addEventVideo('/eventSystem/updateEventVideo', { event_id: eventid, video: Video.toString() })}>
                        <Icon.Save className="me-2" size={16} /> Save
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    );
}

export default AddVideoModel;
