import React, { useState } from "react";
import { Form, Tab, Nav } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "react-quill/dist/quill.snow.css";
import ShortDescription from "../../Common/Description/ShortDescription";
import FullDescription from "../../Common/Description/FullDescription";
import { useSelector } from "react-redux";
window.Buffer = window.Buffer || require("buffer").Buffer;

const ChromeTab = ({ shortdescriptionMr, setShortDescriptionMr, descriptionMr, setDescriptionMr, shortdescription, setShortDescription, descriptionEn, setDescriptionEn
    , shortdescriptionHn, setShortDescriptionHn, descriptionHn, setDescriptionHn }) => {

    // for language
    const [key, setkey] = useState('mr');
    const activeKeyRedux = useSelector((state) => state?.cmsActiveData?.activeNumberKey);

    const handleTabSelect = (key) => {
        setkey(key);
    };


    // Check if Marathi descriptions are filled
    const isMarathiFilled = (
        (shortdescriptionMr?.trim() !== '' && shortdescriptionMr?.trim() !== '<p><br></p>') &&
        (descriptionMr?.trim() !== '' && descriptionMr?.trim() !== '<p><br></p>')
    );

    // Check if English descriptions are filled
    const isEnglishFilled = (
        (shortdescription?.trim() !== '' && shortdescription?.trim() !== '<p><br></p>') &&
        (descriptionEn?.trim() !== '' && descriptionEn?.trim() !== '<p><br></p>')
    );;
    // Check if Hindi descriptions are filled
    const isHindiFilled = (
        ""
        // (shortdescriptionHn?.trim() !== '' && shortdescriptionHn?.trim() !== '<p><br></p>') &&
        // (descriptionHn?.trim() !== '' && descriptionHn?.trim() !== '<p><br></p>')
    );
    return (
        <div className="ChromeTabs">
            <Tab.Container activeKey={key} onSelect={handleTabSelect}>
                <Nav variant="tabs" className="flex-row">
                    <Nav.Item>
                        <Nav.Link eventKey="mr" className={isMarathiFilled ? "filled-tab" : ""}>
                            Marathi
                            {isMarathiFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                            {/* <Icon.InfoCircleFill /> */}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="en" className={isEnglishFilled ? "filled-tab" : ""}>
                            English {isEnglishFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item >
                        <Nav.Link eventKey="hn" className={isHindiFilled ? "filled-tab" : ""} disabled>
                            Hindi {isHindiFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                    {activeKeyRedux?.activeTab === "Article List" &&
                        <p className="mt-2 fs-6">
                            (Recommanded image resolution - 4/5 (1080*1350 px))
                        </p>}
                </Nav>


                <Tab.Content>
                    <Tab.Pane eventKey="mr" className="mt-3">
                        <div data-aos="fade-up">
                            <Form.Group className="mb-3">
                                <Form.Label>Short Description in Marathi <span>*</span></Form.Label>
                                <ShortDescription
                                    value={shortdescriptionMr}
                                    onChange={(text) => setShortDescriptionMr(text)}
                                    key="mr-quill"
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Full Description in Marathi <span>*</span></Form.Label>
                                <FullDescription
                                    value={descriptionMr}
                                    onChange={(text) => setDescriptionMr(text)}
                                    key="mr-quillfull"
                                />
                            </Form.Group>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="en" className="mt-3">
                        {
                            key === "en" &&
                            <div data-aos="fade-up">
                                <Form.Group className="mb-3">
                                    <Form.Label>Short Description in English <span>*</span></Form.Label>
                                    <ShortDescription
                                        value={shortdescription}
                                        onChange={(text) => setShortDescription(text)}
                                        key="en-quill"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Full Description in English <span>*</span></Form.Label>
                                    <FullDescription
                                        value={descriptionEn}
                                        onChange={(text) => setDescriptionEn(text)}
                                        key="en-quillfull"
                                    />
                                </Form.Group>
                            </div>
                        }
                    </Tab.Pane>
                    <Tab.Pane eventKey="hn" className="mt-3" >
                        {
                            key === "hn" &&
                            <div data-aos="fade-up">
                                <Form.Group className="mb-3">
                                    <Form.Label>Short Description in Hindi <span>*</span></Form.Label>
                                    <ShortDescription
                                        value={shortdescriptionHn}
                                        onChange={(text) => setShortDescriptionHn(text)}
                                        key="hn-quill"
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Full Description in Hindi <span>*</span></Form.Label>
                                    <FullDescription
                                        value={descriptionHn}
                                        onChange={(text) => setDescriptionHn(text)}
                                        key="hn-quillfull"
                                    />
                                </Form.Group>
                            </div>
                        }
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default ChromeTab