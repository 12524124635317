import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Card } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import ChromeTab from '../../Common/langugageToggle/ChromeTab';
import ButtonComponent from '../../Common/Button/ButtonComponent';

const AddHomeBanner = () => {

  const userState = useSelector((state) => state?.cmsLogin?.userData?.data);
  const navigate = useNavigate();

  // state for input field
  const [titleEn, setTitleEn] = useState('');
  const [titleMr, setTitleMr] = useState('');
  const [titleHn, setTitleHn] = useState('');
  const [priority, setPriority] = useState("")
  const [bannerImages, setBannerImages] = useState("");
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionMr, setDescriptionMr] = useState('');
  const [descriptionHn, setDescriptionHn] = useState('');
  const [shortdescription, setShortDescription] = useState("");
  const [shortdescriptionMr, setShortDescriptionMr] = useState("");
  const [shortdescriptionHn, setShortDescriptionHn] = useState("");

  // state for loader
  const [loader, setLoader] = useState(false);

  // state for error
  const [error, setErrors] = useState({});
  const [fileEventBanner, setFileEventBanner] = useState('');

  // for empty state field
  const emptyState = () => {
    setTitleEn("")
    setTitleHn("")
    setTitleMr("")
    setPriority("")
    setDescriptionEn("")
    setDescriptionMr("")
    setDescriptionHn("")
    setShortDescription("")
    setShortDescriptionHn("")
    setShortDescriptionMr("")
    setPriority("")
  }

  // for validation
  const createValidate = () => {
    let newErrors = {};

    if (!titleEn) {
      newErrors.titleEn = "*Title in english is required";
    }

    if (!titleMr) {
      newErrors.titleMr = "*मराठीत शीर्षक आवश्यक आहे";
    }

    //  if (!titleHn) {
    //     newErrors.titleHn = "*शीर्षक हिन्दी में आवश्यक है";
    // }

    if (!priority) {
      newErrors.priority = "*Please set priority"
    }


    if (!bannerImages) {
      newErrors.bannerImages = "*Select Banner image";
    }

    if ((!shortdescriptionMr || shortdescriptionMr.trim() === '<p><br></p>') ||
      (!descriptionMr || descriptionMr.trim() === '<p><br></p>') ||
      (!shortdescription || shortdescription.trim() === '<p><br></p>') ||
      (!descriptionEn || descriptionEn.trim() === '<p><br></p>'))
    //  ||(!shortdescriptionHn || shortdescriptionHn.trim() === '<p><br></p>') ||
    //     (!descriptionHn || descriptionHn.trim() === '<p><br></p>')) 
    {
      newErrors.description = "*Both short and full descriptions in English and Marathi are required";
    }
    setErrors(newErrors);

    if (newErrors) {
      document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
    }
    return Object.keys(newErrors).length === 0;
  }

  // for create banner data
  const createHomeBanner = async (status) => {
    const isValid = createValidate();
    if (!isValid) return
    setLoader(true);
    if (isValid) {
      await fetch(process.env.REACT_APP_BASE_URL + '/homeBanner/addHomeBanner', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({
          title: titleMr,
          titleLang: { en: titleEn, mr: titleMr, hin: titleHn },
          description: descriptionMr,
          descriptionLang: { en: descriptionEn, mr: descriptionMr, hin: descriptionHn },
          shortDescription: shortdescriptionMr,
          shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
          bannerImgURL: bannerImages,
          priorityNo: priority,
          isPublish: status,
        })
      }).then((res) => res.json()).then((response) => {
        if (response.message === "Home banner added successfully") {
          toast.success("Added Successfully");
          setLoader(false);
          navigate("/dashboard/homebanner");
          emptyState();
        }
        else if (response.message === "Home banner already exists") {
          toast.error("Home Banner already exist");
          setLoader(false);
        }
      }).catch((Err) => {
        console.log("Error While Creating Product", Err);
        setLoader(false);
      })
    }
  }

  const handleReactS3UltraResBanner = (url) => {
    setBannerImages(url)
  }

  return (

    <div className='AddBanner outletPadding'>
      <ToastContainer />
      <Container>
        <Row>
          <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
            <Icon.ArrowLeft className="me-2" />Back
          </Button>
        </Row>

        <h3 className='text-center d-flex justify-content-center align-items-center'>
          <Icon.BagPlus size={20} className='me-2' />Add Home Banner
        </h3>

        <Row className="mt-4">
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Title in English<span>*</span></Form.Label>
              <Form.Control
                id="titleEn"
                placeholder="Enter Title"
                value={titleEn}
                onChange={(e) => {
                  if (
                    !e.target.value.match(/[\u0900-\u097F]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    setTitleEn(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setTitleEn(e.target.value);
                  }
                }}
              />
              {error.titleEn && <p className="errMsg">{error.titleEn}</p>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Title in Marathi<span>*</span></Form.Label>
              <Form.Control
                id="titleMr"
                placeholder="शीर्षक प्रविष्ट करा"
                value={titleMr}
                onChange={(e) => {
                  const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                  if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                    setTitleMr(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setTitleMr(e.target.value);
                  }
                }}

              />
              {error.titleMr && <p className="errMsg">{error.titleMr}</p>}
            </Form.Group>
          </Col>

          <Col md={4} className="off">
            <Form.Group className="mb-3">
              <Form.Label>Title in Hindi</Form.Label>
              <Form.Control
                id="titleHn"
                placeholder="शीर्षक दर्ज करें"
                value={titleHn}
                onChange={(e) => {
                  const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                  if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                    setTitleHn(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setTitleHn(e.target.value);
                  }
                }}
              />
              {error.titleHn && <p className="errMsg">{error.titleHn}</p>}
            </Form.Group>
          </Col>


          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Home Banner Image<span>*</span></Form.Label> <br />
              <>
                {!bannerImages ?
                  <>
                    <label htmlFor="fileInput" id="uploadBtn">
                      <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                    </label>
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/gif"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={(event) => setFileEventBanner(event)} />
                    <p id='imgNote'>Recommanded image resolution -320*256px(2:1)</p>
                    {error.bannerImages && <p className="error">{error.bannerImages}</p>}
                  </>
                  :
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    <Card className="uploadedFile m-1">
                      <img src={bannerImages} style={{ width: 60, height: 60 }} className='mx-auto' />
                      <Icon.XCircleFill className='removeIcon' size={18}
                        onClick={() => setBannerImages('')} />
                    </Card>
                  </div>
                }
              </>
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Priority<span>*</span></Form.Label>
              <Form.Control
                value={priority}
                placeholder="Enter priority"
                onChange={(e) => {
                  if (
                    !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/)
                  ) { setPriority(e.target.value); }
                }}
              />

              {error.priority && <p className="error">{error.priority}</p>}
            </Form.Group>
          </Col>


          {/* for description  */}
          <ChromeTab
            shortdescriptionMr={shortdescriptionMr}
            setShortDescriptionMr={setShortDescriptionMr}
            descriptionMr={descriptionMr}
            setDescriptionMr={setDescriptionMr}
            shortdescription={shortdescription}
            setShortDescription={setShortDescription}
            descriptionEn={descriptionEn}
            setDescriptionEn={setDescriptionEn}
            shortdescriptionHn={shortdescriptionHn}
            setShortDescriptionHn={setShortDescriptionHn}
            descriptionHn={descriptionHn}
            setDescriptionHn={setDescriptionHn}
          />
          {error.description && (
            <p className="errMsg mt-1">{error.description}</p>
          )}

          <Row className='mb-5'>
            {
              loader ? <center><Spinner variant='primary' /></center> :
                <>
                  <ButtonComponent
                    onSave={createHomeBanner}
                    onSaveAndPublish={createHomeBanner}
                    saveLabel="Save"
                    savePublish="Save & Publish"
                  />
                </>

            }
          </Row>
        </Row>
      </Container>

      {
        fileEventBanner &&
        <ReactS3Ultra fileEvent={fileEventBanner} fileType={process.env.REACT_APP_IMAGE}
          directory="homeBanners" onFileUpload={handleReactS3UltraResBanner} type={2 / 1} />
      }


    </div>
  )
}

export default AddHomeBanner