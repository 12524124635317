import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from '../../Common/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../../Common/ActiveKeySlice';
import PublishModel from '../../Common/Model/PublishModel';
import UnPublishModel from '../../Common/Model/UnPublishModel';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import DeleteModel from '../../Common/Model/DeleteModel';
import PaginationSequence from '../../Common/Pagination/PaginationSequence';


const Shoppee = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const userState = useSelector((state) => state?.cmsLogin?.userData.data);

  const [createLoader, setCreateLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [delId, setDelId] = useState('');
  const [showDel, setShowDel] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const [publishProductId, setPublishProductId] = useState(null);
  // Unpublish video variables
  const [isUnpublish, setIsUnpublish] = useState(false);
  const [unpublishId, setUnpublishId] = useState("");


  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(25);
  const [noOfPages, setNoOfPages] = useState();


  // Function to handle opening the publish modal
  const openPublishModal = (shopeeId) => {
    setPublishProductId(shopeeId);
    setIsPublishModalOpen(true);
  };

  // Function to handle closing the publish modal
  const closePublishModal = () => {
    setIsPublishModalOpen(false);
  };

  const handlePublishModalCancel = () => {
    setIsPublishModalOpen(false);
  };

  // Function to handle opening the unpublish modal
  const openUnpublishModal = (shopeeId) => {
    setUnpublishId(shopeeId);
    setIsUnpublish(true);
  };

  // Function to handle cancelling the unpublish modal
  const closeUnpublishModal = () => {
    setIsUnpublish(false);
  };


  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }


  useEffect(() => {
    getAllShoppee()
  }, [docPerPage, pageNo])

  // ------- Get All Shoppee ----------------------
  const getAllShoppee = async () => {
    try {
      setLoader(true);
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/priority/titleFilter', {
        method: 'POST',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({
          type: "Product",
          title: searchField ? searchField : "",
          documentsPerPage: docPerPage,
          page: pageNo
        })
      })
      const responseData = await response.json();
      if (response.message === "Authorization failed / Forbidden") {
        setLoader(true);
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));

        localStorage.removeItem('persist:root');
        navigate('/');
      } else {
        setData(responseData);
        setNoOfPages(responseData?.noOfPages);
        setLoader(false);
      }

    } catch (Err) {
      console.log("Err while getting activites", Err);
      setLoader(false);
    }
  }

  // for custome hook call
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getAllShoppee, dependencies, setLoader);


  const deleteShoppee = async () => {
    setCreateLoader(true);
    try {
      const result = await fetch(process.env.REACT_APP_BASE_URL + '/shoppee/deleteShoppee', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({ shopeeId: delId })
      });
      const response = await result.json();
      if (response.message === 'Data deleted succussfully') {
        toast.success('Product Deleted Succussfully');
        setShowDel(false);
        setDelId('');
        setCreateLoader(false);
        getAllShoppee();
      }
      else {
        toast.error('Failed to delete, try again');
        setShowDel(false);
        setDelId('');
        setCreateLoader(false);
      }
    } catch (Err) {
      console.log("Err while deleting article", Err);
      setCreateLoader(false);
    }
  }

  // for toggle
  const handlePublishAction = async (shopeeId, isPublish) => {
    try {
      setIsPublishModalOpen(false);
      setIsUnpublish(false);
      const result = await fetch(process.env.REACT_APP_BASE_URL + "/shoppee/publishShoppee", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({
          shopeeId: shopeeId,
          isPublish: isPublish
        }),
      });

      const response = await result.json();
      if (response) {
        getAllShoppee();
      } else {
        console.log("Error toggling publish status");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Function to handle confirming publish modal
  const handlePublishModalConfirm = async () => {
    await handlePublishAction(publishProductId, true);
  };

  // Function to handle confirming unpublish modal
  const handleUnpublishConfirm = async () => {
    await handlePublishAction(unpublishId, false);
  };

  return (
    <div className='Shoppee outletPadding'>
      <PublishModel
        isOpen={isPublishModalOpen}
        onClose={handlePublishModalCancel}
        onConfirm={handlePublishModalConfirm}
        title="Product"
        loader={loader}
      />
      <UnPublishModel
        isOpen={isUnpublish}
        onClose={closeUnpublishModal}
        onConfirm={handleUnpublishConfirm}
        title="Product"
        loader={loader}
      />
      <DeleteModel
        show={showDel}
        onHide={() => { setShowDel(false); setDelId(''); }}
        onDelete={deleteShoppee}
        loader={createLoader}
        label="Product"
      />
      <ToastContainer />
      <Container>
        <Row className='justify-content-between'>
          <Col md={4} className="mb-2 mb-sm-0">
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }
              }}
            ></input>
          </Col>
          <Col md={3} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
            <Button className='primaryBtn' onClick={() => navigate('/dashboard/add-shoppee')}>
              <Icon.BagPlus className='me-2' size={16} />Add Product
            </Button>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
            <table>
              <thead>
                <th>Sr.</th>
                <th>Image</th>
                <th>Product Name</th>
                <th>MarketPlace URL</th>
                <th>Tags</th>
                <th>Status</th>
                <th>Publish</th>
                <th>Action</th>
              </thead>
              {
                loader ? <Skeleton rows={9} cols={9} /> :
                  data?.data !== null && data?.data !== undefined && data?.data?.length > 0 ? data?.data?.map((itm, index) => {
                    const { imageURL, name, marketPlaceURL, tags, status, shopeeId } = itm
                    return (

                      <tr key={Math.random() * 999999999}>
                        <td>{pageNo !== 1 ? (
                          <>{' '} {index + 1 + docPerPage * (pageNo - 1)} </>
                        ) : (<>{index + 1}</>)}</td>
                        <td><img src={imageURL[0]} alt="Shoppee Profile" width={40} /></td>
                        <td style={{ width: '200px' }}>{name}</td>
                        <td className='redirect'
                          style={{
                            maxWidth: '160px', overflow: 'hidden',
                            textOverflow: 'ellipsis', whiteSpace: 'nowrap',
                            cursor: 'pointer'
                          }}
                          onClick={() => window.open(marketPlaceURL, '_blank')}>
                          {marketPlaceURL}</td>
                        <td style={{ width: '230px' }}>{tags?.map((row) => (row)).join(', ')}</td>
                        <td>{status}</td>
                        <td style={{ width: '120px' }}>
                          {itm.isPublish ? (
                            <div
                              style={{
                                backgroundColor: "#8FBC8F",
                                borderRadius: "8px",
                                padding: "4px 8px",
                                color: "white",
                                textAlign: "center",
                                cursor: "pointer"
                              }}
                              onClick={() => openUnpublishModal(itm.shopeeId)}
                            >
                              Published
                            </div>
                          ) : (
                            <div
                              className="switch"
                              onClick={() => openPublishModal(itm.shopeeId)}
                            >
                              <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                              <span className="slider round" onClick={() => openPublishModal(itm.shopeeId)}></span>
                            </div>
                          )}

                        </td>
                        <td >
                          <img src={View} alt="View" className="icon me-3"
                            onClick={() =>
                              navigate("/dashboard/view-shoppee", {
                                state: {
                                  shopee: itm,
                                },
                              })} />

                          <img src={Edit} alt="Edit" className="icon me-3"
                            onClick={() =>
                              navigate("/dashboard/edit-shoppee", {
                                state: itm,

                              })}
                          />

                          <img src={Delete} alt="Delete" className='icon'
                            onClick={() => { setShowDel(true); setDelId(shopeeId); }} />
                        </td>
                      </tr>
                    )
                  })
                    : <p className='noDataFound'>No Data Found</p>
              }
            </table>
          </div>
        </div>

        <PaginationSequence
          data={data?.count}
          pageNo={pageNo}
          noOfPages={noOfPages}
          handlePageChange={handlePageChange}
          handleDocsPerPage={handleDocsPerPage}
          docPerPage={docPerPage}
        />

      </Container>
    </div>
  )
}

export default Shoppee