import React from 'react';
import { Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import { quillFormats, quillModules } from '../../../Utils/GlobalConfigs';

const FullDescription = ({ value, onChange, label, error }) => {
    return (
        <div>
            <Form.Group className="mb-3">
                <ReactQuill
                    style={{ height: 300 }}
                    theme="snow"
                    modules={quillModules}
                    formats={quillFormats}
                    value={value}
                    onChange={onChange}
                />
                <br />
                <br />
                {error && (<p className="error">{error}</p>)}
            </Form.Group>
        </div>
    );
};

export default FullDescription;