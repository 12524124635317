import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Card } from 'react-bootstrap';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import { ToastContainer, toast } from 'react-toastify';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllCategories } from '../../Common/Categories';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import { quillModules, quillFormats } from '../../../Utils/GlobalConfigs';
import GuruNameTab from '../../Common/langugageToggle/GuruNameTab';


const AddTeacher = () => {

  const userState = useSelector((state) => state?.cmsLogin?.userData?.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllCategories({ payload: { categoryType: "course" }, token: userState?.token }));
  }, []);

  const getCategory = useSelector(state => state?.categories?.categoryList?.data);
  // for category list
  const categoryOptions = getCategory?.data?.length > 0 &&
    getCategory?.data !== null &&
    getCategory?.data !== undefined ?
    getCategory?.data?.map(itm =>
      ({ label: itm?.categoryName, value: itm?.categoryName })) : [];
  const navigate = useNavigate();

  const [createLoader, setCreateLoader] = useState(false);

  const [firstName, setFirstName] = useState('');
  const [firstNameMr, setFirstNameMr] = useState('');
  const [firstNameHn, setFirstNameHn] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [middleNameMr, setMiddleNameMr] = useState('');
  const [middleNameHn, setMiddleNameHn] = useState('');
  const [lastName, setLastName] = useState('');
  const [lastNameMr, setLastNameMr] = useState('');
  const [lastNameHn, setLastNameHn] = useState('');

  const [img, setImg] = useState('');
  const [qualification, setQualification] = useState('');
  const [dob, setDob] = useState('');
  const [courseCategory, setCourseCategory] = useState([]);
  const [summary, setSummary] = useState('');
  const [fileEvent, setFileEvent] = useState('');
  const [error, setErrors] = useState({});

  // ------ Upload Image ----------------------------------

  const handleReactS3UltraRes = (url) => {
    setImg(url);
  }

  const addValidate = () => {
    let newErrors = {};

    if ((!firstName || firstName.trim() === '<p><br></p>') ||
      (!lastName || lastName.trim() === '<p><br></p>') ||
      (!firstNameMr || firstNameMr.trim() === '<p><br></p>') ||
      (!lastNameMr || lastNameMr.trim() === '<p><br></p>'))
    //  || (!firstNameHn || firstNameHn.trim() === '<p><br></p>') ||
    //      (!lastNameHn || lastNameHn.trim() === '<p><br></p>')) 
    {
      newErrors.description = "*Both firstname and lastname in English and Marathi are required";
    }

    if (!img) {
      newErrors.img = "*Please upload image";
    }

    if (!qualification) {
      newErrors.qualification = "*qualification is required";
    }

    if (!dob) {
      newErrors.dob = "*DOB required";
    }

    if (courseCategory.length === 0) {
      newErrors.courseCategory = "*Select at least one course category";
    }

    if (!summary || summary.trim() === '<p><br></p>') {
      newErrors.summary = "*Summary is required";
    }

    setErrors(newErrors);
    if (newErrors) {
      document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
    }
    return Object.keys(newErrors).length === 0;
  }

  const addTeacher = async () => {
    const isValid = addValidate();
    if (isValid) {
      setCreateLoader(true);
      await fetch(process.env.REACT_APP_BASE_URL + '/course/addTeacher', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          firstNameLang: { en: firstName, mr: firstNameMr, hin: firstNameHn },
          middleNameLang: { en: middleName, mr: middleNameMr, hin: middleNameHn },
          lastNameLang: { en: lastName, mr: lastNameMr, hin: lastNameHn },
          dob: dob,
          qualification: qualification,
          imageURL: img,
          courseCategories: courseCategory?.map((itm) => itm.value),
          summary: summary,
          createdBy: userState?.data?.user?.name
        })
      }).then((res) => res.json()).then((response) => {
        if (response.message === "Teacher Added Successfully") {
          toast.success("Teacher Created Successfully");
          setCreateLoader(false);
          navigate(-1);
        }
        else if (response.message === "Teacher already exist") {
          toast.error("Activity already exist");
          setCreateLoader(false);
        }
      }).catch((Err) => {
        console.log("Error While Creating Activity", Err);
        setCreateLoader(false);
      })
    }
  }


  return (
    <div className='AddTeacher outletPadding'>
      <ToastContainer />
      <Container>
        <Row>
          <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
            <Icon.ArrowLeft className="me-2" />Back
          </Button>
        </Row>

        <h3 className='text-center d-flex justify-content-center align-items-center'>
          <Icon.PersonWorkspace size={20} className='me-2' />Add Teacher
        </h3>

        <Row className="mt-4">
          {/* for firstname and lastname  */}
          <GuruNameTab
            firstNameMr={firstNameMr}
            setFirstNameMr={setFirstNameMr}
            firstNameEn={firstName}
            setFirstNameEn={setFirstName}
            firstNameHn={firstNameHn}
            setFirstNameHn={setFirstNameHn}
            middleNameMr={middleNameMr}
            setMiddleNameMr={setMiddleNameMr}
            middleName={middleName}
            setMiddleName={setMiddleName}
            middleNameHn={middleNameHn}
            setMiddleNameHn={setMiddleNameHn}
            lastNameMr={lastNameMr}
            setLastNameMr={setLastNameMr}
            lastNameEn={lastName}
            setLastNameEn={setLastName}
            lastNameHn={lastNameHn}
            setLastNameHn={setLastNameHn}
          />

          {error.description && (
            <p className="errMsg">{error.description}</p>
          )}

          <Col md={6}>
            <Form.Group className="mb-3" id="img">
              <Form.Label>Teacher Image<span>*</span></Form.Label> <br />

              {!img ?
                <>
                  <label htmlFor="teacherImg" id="uploadBtn">
                    <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/gif"
                    id="teacherImg"
                    style={{ display: "none" }}
                    onChange={(event) => setFileEvent(event)}
                  />
                  <p id='imgNote'>Recommended image resolution - 1:1</p>
                  {error.img && <p className="errMsg">{error.img}</p>}
                </>
                :
                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                  <Card className="uploadedFile m-1">
                    <img src={img} style={{ width: 60, height: 60 }} className='mx-auto' />
                    <Icon.XCircleFill className='removeIcon' size={18}
                      onClick={() => setImg('')} />
                  </Card>
                </div>
              }

            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>Qualification<span className='errMsg'>*</span></Form.Label>
              <Form.Control placeholder="Enter qualification" id="qualification" value={qualification}
                onChange={(e) => {
                  if (!e.target.value.match(/[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/) && e.target.value.trim()) {
                    setQualification(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setQualification(e.target.value);
                  }
                }} />

              {error.qualification && <p className="errMsg">{error.qualification}</p>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>Date of Birth<span className='errMsg'>*</span></Form.Label>
              <Form.Control max={new Date().toISOString().split('T')[0]}
                type='date' id="dob" value={dob} onChange={(e) => {
                  setDob(e.target.value)
                }} />
              {error.dob && <p className="errMsg">{error.dob}</p>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>Course Category<span>*</span></Form.Label>
              <Select
                isMulti={true}
                id="courseCategory"
                value={courseCategory}
                onChange={setCourseCategory}
                options={categoryOptions}
                placeholder="Select Course Category"
              />
              {error.courseCategory && <p className="errMsg">{error.courseCategory}</p>}
            </Form.Group>
          </Col>


          <Col md={12}>
            <Form.Group className='mb-3'>
              <Form.Label>Summary<span className='errMsg'>*</span></Form.Label>
              <ReactQuill
                id="summary"
                style={{ height: 150 }}
                theme="snow"
                modules={quillModules}
                formats={quillFormats}
                value={summary}
                onChange={(itm) => { setSummary(itm) }}
              />
              <br />
              <br />
              <br className='d-block d-md-none' />
              <br className='d-block d-md-none' />
              {error.summary && <p className="errMsg">{error.summary}</p>}
            </Form.Group>
          </Col>
        </Row>

        <Row className='mb-5'>
          {
            createLoader ? <center><Spinner variant='primary' /></center> :
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5%", marginTop: "4%" }}>
                <Button className='primaryBtn w-auto mx-auto' onClick={() => addTeacher()}>
                  <Icon.PersonAdd className='me-2' />Add Teacher
                </Button>
              </div>
          }
        </Row>
      </Container>

      {
        fileEvent &&
        <ReactS3Ultra fileEvent={fileEvent} fileType={process.env.REACT_APP_IMAGE}
          directory="TeacherImages" onFileUpload={handleReactS3UltraRes} />
      }
    </div>
  )
}

export default AddTeacher;