import React from 'react';
import { Tab, Nav } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import CourseTopic from './CourseTopic';
import CourceTask from './CourceTask';
import { setActiveCourseTab } from '../../Common/ActiveKeySlice';
import { useDispatch, useSelector } from 'react-redux';

const TopicAndTaskList = () => {

    const dispatch = useDispatch();
    const activeCourseTab = useSelector((state) => state?.cmsActiveData?.activecourseTab);

    return (
        <div className='GuruAndQuotes outletPadding'>
            <Tab.Container activeKey={activeCourseTab} onSelect={(k) => dispatch(setActiveCourseTab(k))}>
                <Nav variant="pills" className="flex-row justify-content-center mx-auto">
                    <Nav.Item>
                        <Nav.Link eventKey="topic">
                            <Icon.JournalPlus className='me-2' />Course Topic</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="task">
                            <Icon.JournalText className='me-2' />Course Task</Nav.Link>
                    </Nav.Item>
                </Nav>

                <Tab.Content>
                    <Tab.Pane eventKey="topic">
                        <CourseTopic />
                    </Tab.Pane>
                    <Tab.Pane eventKey="task">
                        <CourceTask />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default TopicAndTaskList;