import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Form, Spinner, Card } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Select from 'react-select';
import ReactS3 from '../../../Utils/ReactS3';
import Lottie from 'react-lottie';
import imageUploading from "../../../Assets/ImageUploading.json";
import { useSelector } from 'react-redux';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import CloudeFlareVideoUpload from '../../../Utils/CloudeFlareVideoUpload';
import PDF from '../../../Assets/Pdf.svg';
import MP3 from '../../../Assets/Mp3.svg';
import ChromeTab from '../../Common/langugageToggle/ChromeTab';
import ButtonComponent from '../../Common/Button/ButtonComponent';
import { options, typeTopicOptions } from '../../../Utils/GlobalConfigs';

const AddTopic = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const location = useLocation()
    const navigate = useNavigate()
    const { courseId, courseLessonId, courseTitleLang, lessonTitleLang } = location?.state;

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: imageUploading,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const [s3Data, setS3Data] = useState({
        accessKey: "AKIA4GWLDQ2LFZGE32BJ",
        secretKey: "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",
        bucket: "familyguide",
        region: "ap-south-1",
    });

    const [createLoader, setCreateLoader] = useState(false);
    const [uploading, setUploading] = useState('');
    const [titleHeaderEn, setTitleHeaderEn] = useState('');
    const [titleHeaderMr, setTitleHeaderMr] = useState('');
    const [titleEn, setTitleEn] = useState('');
    const [titleMr, setTitleMr] = useState('');
    const [titleHn, setTitleHn] = useState('');
    const [topicImages, settopicImages] = useState('');
    const [topicVideos, settopicVideos] = useState('');
    const [duration, setDuration] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionMr, setDescriptionMr] = useState('');
    const [descriptionHn, setDescriptionHn] = useState('');
    const [shortdescription, setShortDescription] = useState("");
    const [shortdescriptionMr, setShortDescriptionMr] = useState("");
    const [shortdescriptionHn, setShortDescriptionHn] = useState("");
    const [srno, setSrNo] = useState('');
    const [error, setErrors] = useState({});
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedFile, setSelectedFile] = useState('');
    const [type, setType] = useState("")
    const [fileEventVideoImg, setFileEventVideoImg] = useState('');
    const [publishdate, setPublishDate] = useState("")

    // for upload images ,videos,files
    const uploadImage = async (e, directory) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            try {
                setUploading(directory);
                const uploadedFile = await ReactS3(file, s3Data, directory);
                let arr = Array.isArray(selectedFile) ? [...selectedFile] : [];
                arr.push(uploadedFile);

                switch (directory) {
                    case "topicImages":
                        settopicImages(arr);
                        break;
                    case "topicVideos":
                        settopicVideos(arr);
                        break;
                    case "topicFiles":
                        setSelectedFile(arr);
                        break;
                    default:
                        console.error("Invalid directory:", directory);
                        break;
                }

                e.target.value = null;
            } catch (error) {
                console.error("Error while uploading file", error);
            } finally {
                setUploading('');
            }
        }
    };

    // for validation
    const createValidate = () => {
        let newErrors = {};
        if (!srno) {
            newErrors.srNo = "*Sr.No is required";
        }
        if (!titleEn) {
            newErrors.titleEn = "*Title in English is required";
        }
        if (!titleMr) {
            newErrors.titleMr = "*Title in Marathi is required";
        }
        if (!titleHeaderEn) {
            newErrors.titleHeaderEn = "*Title header in english is required";
        }
        if (!titleHeaderMr) {
            newErrors.titleHeaderMr = "*Title header in marathi is required";
        }
        if (topicImages.length === 0) {
            newErrors.topicImages = "*Please upload at least one image";
        }
        if (topicVideos.length === 0) {
            newErrors.topicVideos = "*Please upload at least one video";
        }

        if (type.length === 0) {
            newErrors.type = "*At least Select one video type for topic";
        }
        if (!duration) {
            newErrors.duration = "*duration in min is required";
        }
        if (!publishdate) {
            newErrors.publishdate = "*Required field"
        }
        if ((!shortdescriptionMr || shortdescriptionMr.trim() === '<p><br></p>') ||
            (!descriptionMr || descriptionMr.trim() === '<p><br></p>') ||
            (!shortdescription || shortdescription.trim() === '<p><br></p>') ||
            (!description || description.trim() === '<p><br></p>'))
        //  ||(!shortdescriptionHn || shortdescriptionHn.trim() === '<p><br></p>') ||
        //     (!descriptionHn || descriptionHn.trim() === '<p><br></p>')) 
        {
            newErrors.description = "*Both short and full descriptions in English and Marathi are required";
        }
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0;
    }

    // for create course lesson model
    const createCourseTopic = async (status) => {
        const isValid = createValidate();
        if (!isValid) return
        setCreateLoader(true);
        if (isValid) {
            await fetch(process.env.REACT_APP_BASE_URL + '/courseTopic/createCourseTopic', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({
                    courseId: courseId,
                    courseTitle: courseTitleLang,
                    courseTitleLang: {
                        mr: courseTitleLang ? courseTitleLang : '',
                        // hin: courseTitleLang?.hin ? courseTitleLang?.hin : '',
                        // en: courseTitleLang?.en ? courseTitleLang?.en : ''
                    },
                    courseLessonId: courseLessonId,
                    courseLessonTitle: lessonTitleLang,
                    courseLessonTitleLang: {
                        mr: lessonTitleLang ? lessonTitleLang : '',
                        // hin: lessonTitleLang?.hin ? lessonTitleLang?.hin : '',
                        // en: lessonTitleLang?.en ? lessonTitleLang?.en : ''
                    },
                    title: titleEn,
                    titleLang: { en: titleEn, mr: titleMr, hin: titleHn },
                    titleHeader: titleHeaderMr,
                    titleHeaderLang: { en: titleHeaderEn, mr: titleHeaderMr, hin: titleHeaderMr },
                    description: descriptionMr,
                    descriptionLang: { en: description, mr: descriptionMr, hin: descriptionHn },
                    shortDescription: shortdescriptionMr,
                    shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
                    srNo: srno,
                    duration: duration,
                    imageURL: topicImages?.join(", "),
                    imageURLLang: { mr: topicImages?.join(", "), hin: '', en: topicImages?.join(", ") },
                    videoURL: topicVideos?.join(", "),
                    videoURLLang: { mr: topicVideos?.join(", "), hin: '', en: topicVideos?.join(", ") },
                    content: [{ type: selectedOption?.value, url: selectedFile }],
                    createdBy: userState?.data?.user?.name,
                    isPublish: status,
                    toPublishDate: publishdate,
                    isLandscape: type?.value,
                })
            })
                .then((res) => res.json())
                .then((response) => {
                    if (response.message === "Course Lesson Created Successfully") {
                        toast.success("CourseTopic Created Successfully");
                        navigate("/dashboard/coursetopic", { state: { courseId: response?.data?.savedCourseTopics?.courseId, courseLessonId: response?.data?.savedCourseTopics?.courseLessonId, courseTitleLang: response?.data?.savedCourseTopics?.courseTitleLang?.mr, lessonTitleLang: response?.data?.savedCourseTopics?.courseLessonTitleLang?.mr } })
                        setCreateLoader(false);
                    }
                    else if (response.message === "Course Topics already exist") {
                        toast.error("This Course Topics already exist");
                        setCreateLoader(false);
                    }
                })
                .catch((Err) => {
                    console.log("Error While Creating CourseTopic", Err);
                    setCreateLoader(false);
                })
        }
    }

    // for remove images ,videos,files
    const remove = (index, directory) => {
        let arr = [];

        switch (directory) {
            case "topicImages":
                arr = [...topicImages];
                arr.splice(index, 1);
                settopicImages(arr);
                break;
            case "topicVideos":
                arr = [...topicVideos];
                arr.splice(index, 1);
                settopicVideos(arr);
                break;
            case "topicFiles":
                break;
        }
    };


    const handleReactS3UltraVideoImg = (url) => {
        if (url) {
            let arr = [];
            arr = [...topicImages];
            arr.push(url);
            settopicImages(arr);
        }
    }

    const uploadOnCloudFlare = async (data) => {
        let arr = [];
        arr = [...topicVideos];
        arr.push(data?.preview);
        settopicVideos(arr);
    }


    return (
        <div className="outletPadding">
            <ToastContainer />
            <Container>
                <Row>
                    <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-2" />Back
                    </Button>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center mb-4'>
                    <Icon.JournalPlus size={20} className='me-2' />Add Topic

                </h3>
                <Row className='mt-4'>

                    {/* <Col md={6} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Course Title</Form.Label>
                            <Form.Control value={courseTitleLang?.en} disabled />
                        </Form.Group>
                    </Col> */}

                    {courseTitleLang && <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Course Title in Marathi</Form.Label>
                            <Form.Control value={courseTitleLang} disabled />
                        </Form.Group>
                    </Col>}

                    {/* {courseTitleLang?.hin &&
                        <Col md={6} className='off'>
                            <Form.Group className='mb-3'>
                                <Form.Label>Course Title in Hindi</Form.Label>
                                <Form.Control value={courseTitleLang?.hin} disabled />
                            </Form.Group>
                        </Col>} */}

                    {/* <Col md={6} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Lesson Title </Form.Label>
                            <Form.Control value={lessonTitleLang?.en} disabled />

                        </Form.Group>
                    </Col> */}

                    {lessonTitleLang &&
                        <Col md={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>Lesson Title in Marathi</Form.Label>
                                <Form.Control value={lessonTitleLang} disabled />
                            </Form.Group>
                        </Col>}

                    {/* {lessonTitleLang?.hin &&
                        <Col md={6} className='off'>
                            <Form.Group className='mb-3'>
                                <Form.Label>Lesson Title in Hindi</Form.Label>
                                <Form.Control value={lessonTitleLang?.hin} disabled />
                            </Form.Group>
                        </Col>} */}

                    <Col md={2}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Lesson number<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Lesson number"
                                value={srno}
                                onChange={(e) => {
                                    if (/^\d*$/.test(e.target.value)) {
                                        setSrNo(e.target.value);
                                    }
                                }}
                            />
                            {error.srNo && <p className="errMsg">{error.srNo}</p>}
                        </Form.Group>
                    </Col>

                    {/* <Col md={5}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Topic Title<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="" value={title} onChange={(e) => {
                                if (!e.target.value.match(/[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/) &&
                                    e.target.value.trim()
                                ) {
                                    setTitle(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitle(e.target.value);
                                }
                            }} />
                            {error.title && <p className="errMsg">{error.title}</p>}
                        </Form.Group>
                    </Col> */}

                    <Col md={5} >
                        <Form.Group className='mb-3'>
                            <Form.Label>Topic Title in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title" id="" value={titleEn} onChange={(e) => {
                                if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                    e.target.value.trim()
                                ) {
                                    setTitleEn(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitleEn(e.target.value);
                                }
                            }} />
                            {error.titleEn && <p className="errMsg">{error.titleEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={5}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Topic Title in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="शीर्षक प्रविष्ट करा" id="" value={titleMr}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleMr(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleMr(e.target.value);
                                    }
                                }} />
                            {error.titleMr && <p className="errMsg">{error.titleMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={5} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Topic Title in Hindi</Form.Label>
                            <Form.Control placeholder="शीर्षक दर्ज करें" id="" value={titleHn}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleHn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHn(e.target.value);
                                    }
                                }} />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="titleHeader" value={titleHeaderEn}
                                onChange={(e) => {
                                    if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                        e.target.value.trim()
                                    ) {
                                        setTitleHeaderEn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHeaderEn(e.target.value);
                                    }
                                }}
                            />
                            {error.titleHeaderEn && <p className="errMsg">{error.titleHeaderEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="titleHeader" value={titleHeaderMr} onChange={(e) => {
                                const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                    setTitleHeaderMr(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitleHeaderMr(e.target.value);
                                }
                            }} />
                            {error.titleHeaderMr && <p className="errMsg">{error.titleHeaderMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3" id='topicImages'>
                            <Form.Label>Topic Image<span>*</span></Form.Label> <br />
                            {topicImages?.length === 0 ?
                                <>
                                    <label htmlFor="fileInput" id="uploadBtn">
                                        <Icon.CloudArrowUp size={20} className="me-2" />
                                        Upload Image
                                    </label>

                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        id="fileInput"
                                        style={{ display: "none" }}
                                        onChange={(event) => setFileEventVideoImg(event)} />
                                    <p id='imgNote'>Recommended image resolution - 1:1</p>
                                    {error.topicImages && <p className="errMsg">{error.topicImages}</p>}
                                </>
                                :
                                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                    {topicImages?.map((row, index) => {
                                        return (
                                            <Card className="uploadedFile mt-2 me-3">
                                                <img src={row} style={{ width: 60, height: 60 }} className='mx-auto' />
                                                <Icon.XCircleFill className='removeIcon' size={18}
                                                    onClick={() => remove(index, "topicImages")} />

                                            </Card>
                                        );
                                    })}
                                </div>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Video Type<span>*</span></Form.Label>
                            <Select
                                value={type}
                                onChange={(option) => setType(option)}
                                options={typeTopicOptions}
                            />
                            {error.type && (
                                <p className="errMsg">{error.type}</p>
                            )}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Topic Video<span>*</span></Form.Label>
                            {topicVideos?.length === 0 ?
                                <>
                                    <CloudeFlareVideoUpload onFileUpload={uploadOnCloudFlare} orientation={type.label} />
                                    {error.topicVideos && <p className="errMsg">{error.topicVideos}</p>}
                                    {type && <p id='imgNote'>
                                        {`Recommended Video resolution - ${type.label === "landscape" ? '16/9 (1920*1080 px)' : '9/16 (1080*1920 px)'}`}</p>}
                                </>
                                :
                                (
                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                        {topicVideos?.map((row, index) => {
                                            return (
                                                <Card className="d-flex uploadedFile justify-content-center align-items-center">
                                                    <a target="_blank" href={row}>
                                                        <Icon.PlayBtnFill style={{ width: 60, height: 60 }} />
                                                    </a>
                                                    <Icon.XCircleFill className='removeIcon' size={18}
                                                        onClick={() => remove(index, "topicVideos")} />
                                                </Card>
                                            );
                                        })}
                                    </div>
                                )}
                        </Form.Group>
                    </Col>



                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Duration (Min)<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter duration in Min" value={duration} onChange={(e) => {
                                if (e.target.value.match(/^[0-9]+$/)) {
                                    setDuration(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setDuration(e.target.value);
                                }
                            }} />
                            {error.duration && <p className="errMsg">{error.duration}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Content</Form.Label>
                            <Select
                                value={selectedOption}
                                onChange={(option) => { setSelectedOption(option); setSelectedFile(null); }}
                                options={options}
                            />
                        </Form.Group>
                        {/* {error.selectedOption && <p className="errMsg">{error.selectedOption}</p>} */}
                    </Col>


                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Upload {selectedOption ? selectedOption?.label : 'File'}</Form.Label> <br />
                            <>
                                {
                                    uploading === "topicFiles" ? <Lottie options={defaultOptions} className='mx-auto' width={120} /> :
                                        <label htmlFor="topicFiles" id="uploadBtn">
                                            <Icon.CloudArrowUp size={20} className="me-2" />
                                            Upload File
                                        </label>
                                }
                                <input
                                    type="file"
                                    accept={selectedOption ? `.${selectedOption.value}` : ''}
                                    id="topicFiles"
                                    style={{ display: "none" }}
                                    onChange={(event) => uploadImage(event, "topicFiles")}
                                    disabled={!selectedOption}
                                />
                                {error.selectedFile && <p className="errMsg">{error.selectedFile}</p>}
                                <br />
                                {selectedFile?.length > 0 ? (
                                    <div className="d-flex align-items-center">

                                        {selectedOption?.value === 'pdf' && (
                                            <Card className="d-flex uploadedFile justify-content-center align-items-center m-1">
                                                <a href={selectedFile} target="_blank">
                                                    <img src={PDF} width={40} />
                                                </a>
                                                <Icon.XCircleFill className='removeIcon' size={18} onClick={() => setSelectedFile(null)} />
                                            </Card>

                                        )}  {selectedOption?.value === 'audio' && (
                                            <Card className="d-flex uploadedFile justify-content-center align-items-center m-1">
                                                <a href={selectedFile} target="_blank">
                                                    <img src={MP3} width={40} />
                                                </a>
                                                <Icon.XCircleFill className='removeIcon' size={18} onClick={() => setSelectedFile(null)} />
                                            </Card>

                                        )}
                                    </div>
                                ) : null}
                            </>
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className="mb-3">
                            <Form.Label>Publish Date<span>*</span></Form.Label>
                            <Form.Control
                                className=""
                                type="date"
                                name="publish Date"
                                value={publishdate}
                                min={new Date().toISOString().split("T")[0]}
                                onKeyDown={(event) => event.preventDefault()}
                                onChange={(e) => setPublishDate(e.target.value)}
                            />
                            {error.publishdate && <p className="errMsg">{error.publishdate}</p>}
                        </Form.Group>
                    </Col>

                    {/* for description  */}
                    <ChromeTab
                        shortdescriptionMr={shortdescriptionMr}
                        setShortDescriptionMr={setShortDescriptionMr}
                        descriptionMr={descriptionMr}
                        setDescriptionMr={setDescriptionMr}
                        shortdescription={shortdescription}
                        setShortDescription={setShortDescription}
                        descriptionEn={description}
                        setDescriptionEn={setDescription}
                        shortdescriptionHn={shortdescriptionHn}
                        setShortDescriptionHn={setShortDescriptionHn}
                        descriptionHn={descriptionHn}
                        setDescriptionHn={setDescriptionHn}
                    />
                    {error.description && (
                        <p className="errMsg mt-1">{error.description}</p>
                    )}


                </Row>

                {
                    createLoader ? <Spinner variant='primary' className='mx-auto' /> :
                        <>
                            <ButtonComponent
                                onSave={createCourseTopic}
                                onSaveAndPublish={createCourseTopic}
                                saveLabel="Save"
                                savePublish="Save & Publish"
                            />
                        </>
                }
            </Container>

            {
                fileEventVideoImg &&
                <ReactS3Ultra fileEvent={fileEventVideoImg} fileType={process.env.REACT_APP_IMAGE}
                    directory="topicImages" onFileUpload={handleReactS3UltraVideoImg} />
            }
        </div>
    )
}

export default AddTopic
