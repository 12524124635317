import React from 'react'
import {
    Button,
    Spinner,
    Modal,
    Form
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

const PublishModel = ({ isOpen, onClose, onConfirm, title, loader }) => {
    return (
        <>
            <Modal
                size="md"
                show={isOpen}
                onHide={onClose}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Publish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to publish this {title}?
                </Modal.Body>
                <Modal.Footer>
                    {loader ? (
                        <Spinner animation="border" variant="primary" />
                    ) : (
                        <>
                            <Button className='primaryBtn me-3' onClick={() => onConfirm(true)} >
                                <Icon.HandThumbsUp className='me-1' />Yes</Button>
                            <Button className='secondaryBtn' onClick={oncancel} >
                                <Icon.HandThumbsDown className='me-1' />No</Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PublishModel