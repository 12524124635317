import React, { useState } from 'react';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import CloudeFlareVideoUpload from '../../../../Utils/CloudeFlareVideoUpload';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { typeTopicOptions } from '../../../../Utils/GlobalConfigs';
import Select from "react-select"
import ChromeTabShortDesc from '../../../Common/langugageToggle/ChromeTabShortdesc';
import ReactS3Ultra from '../../../../Utils/ReactS3Ultra';

const UploadEventPostVideoModel = ({ show, onHide, loader, setLoader, eventid, eventDetails, title, getPostEventVideo }) => {
    const userState = useSelector((state) => state?.cmsLogin?.userData.data);
    const [Video, SetVideo] = useState([]);
    const [image, setImage] = useState('');
    const [shortdescription, setShortDescription] = useState("");
    const [shortdescriptionMr, setShortDescriptionMr] = useState("");
    const [shortdescriptionHn, setShortDescriptionHn] = useState("");
    const [fileImg, setFileImg] = useState('');
    const [type, setType] = useState("")
    const [cloudFlareRes, setCloudFlareRes] = useState('');
    // Error variables
    const [error, setErrors] = useState({});

    const uploadOnCloudFlare = async (data) => {
        let arr = [];
        arr = [...Video];
        arr.push(data?.preview);
        SetVideo(arr);
        setCloudFlareRes(data);
        setErrors({});
    };

    const remove = (index) => {
        const updatedVideos = Video.filter((_, i) => i !== index);
        SetVideo(updatedVideos);
        if (updatedVideos.length === 0) {
            setErrors({ Video: "*Please upload at least one video" });
        }
    };

    const handleReactS3UltraImg = (url) => {
        setImage(url);
    }

    // for validation
    const validate = () => {
        let newErrors = {};
        if (Video.length === 0) {
            newErrors.Video = "*Please upload at least one video";
        }
        if (!image) {
            newErrors.image = "*Please upload image";
        }
        if (type?.length === 0) {
            newErrors.videotype = "*At least Select one type for upload video";
        }
        if ((!shortdescriptionMr || shortdescriptionMr?.trim() === '<p><br></p>') ||
            (!shortdescription || shortdescription?.trim() === '<p><br></p>')
            //  ||
            // (!shortdescriptionHn || shortdescriptionHn?.trim() === '<p><br></p>')
        ) {
            newErrors.description = "* short descriptions in English and Marathi are required";
        }

        setErrors(newErrors);
        if (newErrors.Video) {
            document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
        }
        return Object.keys(newErrors).length === 0;
    };

    // for add event video
    const addEventVideo = async () => {
        const payload = {
            eventVideoUrl: Video.toString(),
            eventName: eventDetails?.event_title,
            eventId: eventDetails?.event_id,
            cloudFlareResponse: cloudFlareRes,
            videoShortDesc: shortdescription,
            videoShortDescLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
            imageURL: image,
            isLandscape: type?.value,

        }
        const isvalid = validate();
        if (!isvalid) return;
        setLoader(true);
        try {
            const result = await fetch(process.env.REACT_APP_BASE_URL + '/eventVideos/addEventVideo', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState?.token}` },
                body: JSON.stringify(payload)
            });

            const response = await result.json();
            if (response) {
                toast.success("Upload Successfully");
                getPostEventVideo()
                setLoader(false);
                onHide();
                SetVideo([]);
                setImage('')
                setShortDescription('')
                setShortDescriptionMr('')
                setShortDescriptionHn('')
                setType('')
                setErrors({});
            }
        } catch (Err) {
            setLoader(false);
        }
    };

    return (
        <React.Fragment>
            <Modal size="lg" show={show} backdrop='static' keyboard={false} centered onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4 className='mb-0'>
                            <Icon.CameraVideo size={20} className='me-2' />{title}
                        </h4>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row className='justify-content-center'>
                        <Col md={12}>
                            <Form.Group className="mb-3" id="VideoImage">
                                <Form.Label>Video Image<span>*</span></Form.Label> <br />
                                {!image ?
                                    <>
                                        <label htmlFor="fileInput" id="uploadBtn">
                                            <Icon.CloudArrowUp size={20} className="me-2" />
                                            Upload Image
                                        </label>

                                        <input
                                            type="file"
                                            accept="image/jpeg, image/png, image/gif"
                                            id="fileInput"
                                            style={{ display: "none" }}
                                            onChange={(event) => {
                                                setFileImg(event);
                                            }} />
                                        <p id='imgNote'>Recommended image resolution - 4/5 (1080*1350 px)</p>
                                        {error.image && <p className="errMsg">{error.image}</p>}
                                    </>
                                    :

                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>

                                        <Card className="uploadedFile my-1 me-3">
                                            <img src={image} style={{ width: 60, height: 60 }} className='mx-auto' />
                                            <Icon.XCircleFill className='removeIcon' size={18}
                                                onClick={() => setImage("")} />
                                        </Card>

                                    </div>
                                }
                            </Form.Group>
                        </Col >

                        <Col md={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Video Type<span>*</span></Form.Label>
                                <Select
                                    value={type}
                                    onChange={(option) => setType(option)}
                                    options={typeTopicOptions}
                                />
                                {error.videotype && (
                                    <p className="errMsg">{error.videotype}</p>
                                )}
                            </Form.Group>
                        </Col>

                        <Col md={6}>
                            <Form.Group className='mb-3'>
                                <Form.Label>Video<span>*</span></Form.Label> <br />
                                {Video?.length === 0 ?
                                    <>
                                        <CloudeFlareVideoUpload onFileUpload={uploadOnCloudFlare} orientation={type.label} />
                                        {error.Video && <p className="errMsg">{error.Video}</p>}
                                        {type && <p id='imgNote'>
                                            {`Recommended Video resolution - ${type.label === "landscape" ? '16/9 (1920*1080 px)' : '9/16 (1080*1920 px)'}`}</p>}
                                    </>
                                    :
                                    <div className="d-flex mt-3" style={{ flexWrap: "wrap" }}>
                                        {Video && Video.map((video, index) => (
                                            <Card key={index} className="d-flex uploadedFile justify-content-center align-items-center me-4">
                                                <a target="_blank" href={video}>
                                                    <Icon.PlayBtnFill style={{ width: 60, height: 60 }} />
                                                </a>
                                                <Icon.XCircleFill
                                                    className="removeIcon"
                                                    size={18}
                                                    onClick={() => remove(index)}
                                                />
                                            </Card>
                                        ))}
                                    </div>
                                }
                            </Form.Group>
                        </Col>

                        {/* for description */}
                        <ChromeTabShortDesc
                            shortdescriptionMr={shortdescriptionMr}
                            setShortDescriptionMr={setShortDescriptionMr}
                            shortdescription={shortdescription}
                            setShortDescription={setShortDescription}
                            shortdescriptionHn={shortdescriptionHn}
                            setShortDescriptionHn={setShortDescriptionHn}
                            label="Short"
                        />

                        {
                            error.description && (
                                <p className="errMsg mt-1">{error.description}</p>
                            )
                        }

                    </Row >
                </Modal.Body >

                <Modal.Footer>
                    {loader ? (
                        <Spinner variant='primary' />
                    ) : (
                        title === "Upload Event Intro Video" ? (
                            <Button className="primaryBtn me-4"
                                onClick={() => addEventVideo('/eventSystem/updateEventVideo', { event_id: eventid, video: Video.toString() })}>
                                <Icon.Save className="me-2" size={16} /> Save
                            </Button>
                        ) : (
                            <Button className="primaryBtn me-4" onClick={addEventVideo}>
                                <Icon.Save className="me-2" size={16} />
                                Save
                            </Button>
                            // <Button className="primaryBtn me-4" onClick={() => addEventVideo('/eventVideos/addEventVideo',
                            //     {
                            //         eventVideoUrl: Video[0], eventName: eventDetails?.event_title, eventId: eventDetails?.event_id,
                            //         videoShortDesc: eventDetails?.event_description, cloudFlareResponse: cloudFlareRes
                            //     })}>
                            //     <Icon.Save className="me-2" size={16} />
                            //     Save
                            // </Button>
                        )
                    )}
                </Modal.Footer>
            </Modal >

            {
                fileImg &&
                <ReactS3Ultra fileEvent={fileImg} fileType={process.env.REACT_APP_IMAGE}
                    directory="VideoImage" onFileUpload={handleReactS3UltraImg} type={4 / 5} />
            }
        </React.Fragment >
    );
}

export default UploadEventPostVideoModel;
