import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Navigate } from "react-router-dom";


const withPrivateRoute = (WrappedComponent) => {
    return (props) => {
        const userState = useSelector(state => state?.cmsLogin?.userData?.data);
        return userState ? <WrappedComponent {...props} /> : <Navigate to="/" />
    }
}
export default withPrivateRoute