import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';

const useContentType = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const [contenttypelist, setContentTypeList] = useState([])

    useEffect(() => {
        const getActivityContent = async () => {
            try {
                const response = await fetch(process.env.REACT_APP_BASE_URL + "/activityContentType/getActivityContentType", {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
                })

                const result = await response.json()
                if (result) {
                    const resultData = result?.data?.map((itm) => ({
                        label: itm.contentType, value: itm.contentType
                    }));
                    setContentTypeList(resultData)
                } else {
                    console.log(result.message || 'Failed to fetch tag categories');
                    setContentTypeList([]);
                }
            } catch (error) {
                console.log("Error:", error)
            }
        }
        getActivityContent()

    }, [])

    return { contenttypelist }
}

export default useContentType