import React, { useState } from 'react'
import SubscriptionUser from './SubscriptionUser';
import { Nav, Tab } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from 'react-redux';
import NotSubscriptionUser from './NotSubscriptionUser';
import { setActiveNav } from '../../Common/ActiveKeySlice';

const SubscriptionUnsubscription = () => {
    const dispatch = useDispatch();
    const activeNav = useSelector((state) => state?.cmsActiveData?.activeNav);
    return (
        <>
            <div className='GuruAndQuotes outletPadding'>
                <Tab.Container activeKey={activeNav} onSelect={(k) => dispatch(setActiveNav(k))}>
                    <Nav variant="pills" className="flex-row justify-content-center mx-auto">
                        <Nav.Item>
                            <Nav.Link eventKey="subscribed">
                                <Icon.People className='me-2' />Subscribed Users</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="unsubscribed">
                                <Icon.People className='me-2' />Not Subscribed Users</Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <Tab.Content>
                        <Tab.Pane eventKey="subscribed">
                            <SubscriptionUser />
                        </Tab.Pane>
                        <Tab.Pane eventKey="unsubscribed">
                            <NotSubscriptionUser />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
        </>
    )
}

export default SubscriptionUnsubscription
