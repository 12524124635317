import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Form, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import ChromeTab from '../../Common/langugageToggle/ChromeTab';
import ButtonComponent from '../../Common/Button/ButtonComponent';

const AddLesson = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const navigate = useNavigate()
    const location = useLocation()
    const { courseId, courseLessonId, courseTitleLang } = location?.state
    const [createLoader, setCreateLoader] = useState(false);


    const [title, setTitle] = useState('');
    const [titleMr, setTitleMr] = useState('');
    const [titleHn, setTitleHn] = useState('');
    const [titleHeaderMr, setTitleHeaderMr] = useState('');
    const [titleHeaderEn, setTitleHeaderEn] = useState('');
    const [courseImages, setCourseImages] = useState('');
    const [duration, setDuration] = useState('');
    const [description, setDescription] = useState('');
    const [descriptionMr, setDescriptionMr] = useState('');
    const [descriptionHn, setDescriptionHn] = useState('');
    const [shortdescription, setShortDescription] = useState("");
    const [shortdescriptionMr, setShortDescriptionMr] = useState("");
    const [shortdescriptionHn, setShortDescriptionHn] = useState("");
    const [srno, setSrNo] = useState('');
    const [error, setErrors] = useState({});


    const createValidate = () => {
        let newErrors = {};

        if (!srno) {
            newErrors.srNo = "*Sr.No is required";
        }
        if (!title) {
            newErrors.title = "*Title in english is required";
        }

        if (!titleMr) {
            newErrors.titleMr = "*Title in Marathi is required";
        }

        if (!titleHeaderEn) {
            newErrors.titleHeaderEn = "*Title header in english is required";
        }

        if (!titleHeaderMr) {
            newErrors.titleHeaderMr = "*Title header in marathi is required";
        }

        if ((!shortdescriptionMr || shortdescriptionMr.trim() === '<p><br></p>') ||
            (!descriptionMr || descriptionMr.trim() === '<p><br></p>') ||
            (!shortdescription || shortdescription.trim() === '<p><br></p>') ||
            (!description || description.trim() === '<p><br></p>'))
        //  ||(!shortdescriptionHn || shortdescriptionHn.trim() === '<p><br></p>') ||
        //     (!descriptionHn || descriptionHn.trim() === '<p><br></p>')) 
        {
            newErrors.description = "*Both short and full descriptions in English and Marathi are required";
        }
        setErrors(newErrors)
        return Object.keys(newErrors).length === 0;
    }

    // for create course lesson model
    const createCourseLesson = async (status) => {
        const isValid = createValidate();
        if (!isValid) return;
        setCreateLoader(true);
        if (isValid) {
            await fetch(process.env.REACT_APP_BASE_URL + '/courseLesson/createCourseLesson', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({
                    courseId: courseId,
                    courseTitle: courseTitleLang,
                    courseTitleLang: {
                        en: courseTitleLang.en ? courseTitleLang.en : "",
                        mr: courseTitleLang ? courseTitleLang : '',
                        hin: courseTitleLang.hin ? courseTitleLang.hin : ''
                    },
                    title: title,
                    titleLang: { en: title, mr: titleMr, hin: titleHn },
                    titleHeader: titleHeaderMr,
                    titleHeaderLang: { en: titleHeaderEn, mr: titleHeaderMr, hin: titleHeaderMr },
                    description: descriptionMr,
                    descriptionLang: { en: description, mr: descriptionMr, hin: descriptionHn },
                    shortDescription: shortdescriptionMr,
                    shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
                    srNo: srno,
                    duration: duration,
                    imageURL: courseImages,
                    createdBy: userState?.data?.user?.name,
                    isPublish: status
                })
            })
                .then((res) => res.json())
                .then((response) => {

                    if (response.message === "Course Lesson Created Successfully") {
                        toast.success("CourseLesson Added Successfully");
                        navigate("/dashboard/courselesson", { state: { courseId: response?.data?.courseId, courseTitleLang: response?.data?.courseTitle } })
                        setCreateLoader(false);
                    }
                    else if (response.message === "CourseLesson already exist") {
                        toast.error("This CourseLesson already exist");
                        setCreateLoader(false);
                    }
                })
                .catch((Err) => {
                    console.log("Error While Creating CourseLesson", Err);
                    setCreateLoader(false);
                })
        }
    }
    return (
        <div className="outletPadding">
            <ToastContainer />
            <Container>
                <Row>
                    <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-2" />Back
                    </Button>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center mb-4'>
                    <Icon.JournalPlus size={20} className='me-2' />Add Lesson
                </h3>
                <Row className='mt-4'>
                    <Col md={3} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Course Title</Form.Label>
                            <Form.Control value={courseTitleLang?.en} disabled />
                        </Form.Group>
                    </Col>

                    {courseTitleLang && <Col md={3}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Course Title in Marathi</Form.Label>
                            <Form.Control value={courseTitleLang} disabled />
                        </Form.Group>
                    </Col>}

                    {courseTitleLang?.hin &&
                        <Col md={3} className='off'>
                            <Form.Group className='mb-3'>
                                <Form.Label>Course Title in Hindi</Form.Label>
                                <Form.Control value={courseTitleLang?.hin} disabled />
                            </Form.Group>
                        </Col>}

                    <Col md={1}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Sr.No<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Sr.No"
                                value={srno}
                                onChange={(e) => {
                                    if (/^\d*$/.test(e.target.value)) {
                                        setSrNo(e.target.value);
                                    }
                                }}
                            />
                            {error.srNo && <p className="errMsg">{error.srNo}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title in english" id="" value={title} onChange={(e) => {
                                if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                    e.target.value.trim()
                                ) {
                                    setTitle(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitle(e.target.value);
                                }
                            }} />
                            {error.title && <p className="errMsg">{error.title}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="शीर्षक प्रविष्ट करा" id="" value={titleMr}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleMr(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleMr(e.target.value);
                                    }
                                }} />
                            {error.titleMr && <p className="errMsg">{error.titleMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4} className='off'>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title in Hindi</Form.Label>
                            <Form.Control placeholder="शीर्षक दर्ज करें" id="" value={titleHn}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleHn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHn(e.target.value);
                                    }
                                }} />
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in English<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="titleHeader" value={titleHeaderEn}
                                onChange={(e) => {
                                    if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                        e.target.value.trim()
                                    ) {
                                        setTitleHeaderEn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHeaderEn(e.target.value);
                                    }
                                }}
                            />
                            {error.titleHeaderEn && <p className="errMsg">{error.titleHeaderEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Title Header in Marathi<span>*</span></Form.Label>
                            <Form.Control placeholder="Enter Title Header" id="titleHeader" value={titleHeaderMr} onChange={(e) => {
                                const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                    setTitleHeaderMr(e.target.value);
                                } else if (e.target.value.length === 0) {
                                    setTitleHeaderMr(e.target.value);
                                }
                            }} />
                            {error.titleHeaderMr && <p className="errMsg">{error.titleHeaderMr}</p>}
                        </Form.Group>
                    </Col>

                    {/* for description  */}
                    <ChromeTab
                        shortdescriptionMr={shortdescriptionMr}
                        setShortDescriptionMr={setShortDescriptionMr}
                        descriptionMr={descriptionMr}
                        setDescriptionMr={setDescriptionMr}
                        shortdescription={shortdescription}
                        setShortDescription={setShortDescription}
                        descriptionEn={description}
                        setDescriptionEn={setDescription}
                        shortdescriptionHn={shortdescriptionHn}
                        setShortDescriptionHn={setShortDescriptionHn}
                        descriptionHn={descriptionHn}
                        setDescriptionHn={setDescriptionHn}
                    />
                    {error.description && (
                        <p className="errMsg mt-1">{error.description}</p>
                    )}

                </Row>

                {
                    createLoader ? <Spinner variant='primary' className='mx-auto' /> :
                        <>
                            <ButtonComponent
                                onSave={createCourseLesson}
                                onSaveAndPublish={createCourseLesson}
                                saveLabel="Save"
                                savePublish="Save & Publish"
                            />
                        </>

                }
            </Container>
        </div>
    )
}

export default AddLesson
