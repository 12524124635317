import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons"
import CloudeFlareVideoUpload from '../../../../Utils/CloudeFlareVideoUpload';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const EditVideoModel = ({ show, onHide, loader, setLoader, getEventList, eventdata }) => {

    const userState = useSelector((state) => state?.cmsLogin?.userData.data);
    const [Video, SetVideo] = useState('')
    const [error, setErrors] = useState({});

    useEffect(() => {
        SetVideo((eventdata?.videos || " "));
    }, [eventdata]);

    const uploadOnCloudFlare = async (data) => {
        SetVideo(data?.preview);
        setErrors({});
    };

    const remove = (directory) => {
        switch (directory) {
            case "video":
                SetVideo(null);
                setErrors({ Video: '*Please upload atleast one video' });
                break;
            default:
                console.error("Invalid directory:", directory);
                break;
        }
    };

    // for validation
    const validate = () => {
        let newErrors = {};
        if (!Video) {
            newErrors.Video = '*Please upload atleast one video';
        }
        setErrors(newErrors);
        if (newErrors.Video) {
            document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
        }
        return Object.keys(newErrors).length === 0;
    };


    // for add event video
    const updateEventVideo = async () => {
        const isvalid = validate();
        if (!isvalid) return;
        try {
            setLoader(true);
            const result = await fetch(process.env.REACT_APP_BASE_URL + '/eventSystem/updateEventVideo', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState?.token}` },
                body: JSON.stringify({
                    event_id: eventdata?.event_id,
                    video: Video,
                })
            });

            const response = await result.json();
            if (response) {
                toast.success("Update Successfully");
                setLoader(false);
                getEventList();
                onHide();
                setErrors({});
            }
        } catch (Err) {
            setLoader(false);
        }
    }


    return (
        <Modal size='md' show={show} backdrop='static' keyboard={false} centered onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title> <h4 className='mb-0'>
                    <Icon.ChatQuote size={20} className='me-2' />Update Event Video</h4>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <Row className='justify-content-center'>
                    <Col md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Video<span>*</span></Form.Label>
                            {!Video ?
                                <>
                                    <CloudeFlareVideoUpload onFileUpload={uploadOnCloudFlare} orientation="landscape" />
                                    {error.Video && <p className="errMsg">{error.Video}</p>}
                                </>
                                :
                                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                    <Card className="d-flex uploadedFile justify-content-center align-items-center me-4">
                                        <a target="_blank" href={Video}>
                                            <Icon.PlayBtnFill style={{ width: 60, height: 60 }} />
                                        </a>
                                        <Icon.XCircleFill className='removeIcon' size={18}
                                            onClick={() => remove("video")} />
                                    </Card>
                                </div>
                            }


                        </Form.Group>
                    </Col>
                </Row>

            </Modal.Body>

            <Modal.Footer>
                {
                    loader ? <Spinner variant='primary' /> :
                        <Button className="primaryBtn me-4" onClick={updateEventVideo}>
                            <Icon.Save className="me-1" />
                            Update
                        </Button>
                }
            </Modal.Footer>
        </Modal >

    );
}

export default EditVideoModel


