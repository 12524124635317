import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from '../../Common/Skeleton';
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import PublishModel from '../../Common/Model/PublishModel';
import UnPublishModel from '../../Common/Model/UnPublishModel';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import DeleteModel from '../../Common/Model/DeleteModel';
import PaginationSequence from '../../Common/Pagination/PaginationSequence';

const CourceTask = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const navigate = useNavigate()
    const location = useLocation()
    const { courseLessonId, lessonTitleLang } = location?.state

    const [searchField, setSearchField] = useState("");
    const [loader, setLoader] = useState(false);
    const [topicData, setTopicData] = useState([]);
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [publishCourseTaskId, setPublishCourseTaskId] = useState(null);

    // Unpublish video variables
    const [isUnpublish, setIsUnpublish] = useState(false);
    const [unpublishId, setUnpublishId] = useState("");

    const [showAdd, setShowAdd] = useState(false);
    const [delId, setDelId] = useState('');
    const [showDel, setShowDel] = useState(false);


    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(25);
    const [noOfPages, setNoOfPages] = useState();

    // Function to handle opening the publish modal 
    const openPublishModal = (taskId) => {
        setPublishCourseTaskId(taskId);
        setIsPublishModalOpen(true);
    };

    // Function to handle closing the publish modal
    const closePublishModal = () => {
        setIsPublishModalOpen(false);
    };

    const handlePublishModalCancel = () => {
        setIsPublishModalOpen(false);
    };

    // Function to handle opening the unpublish modal
    const openUnpublishModal = (taskId) => {
        setUnpublishId(taskId);
        setIsUnpublish(true);
    };

    // Function to handle cancelling the unpublish modal
    const closeUnpublishModal = () => {
        setIsUnpublish(false);
    };

    useEffect(() => {
        getAllCourseTask()
    }, [docPerPage, pageNo])

    // for get all course lesson data
    const getAllCourseTask = async () => {
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllCourseTaskD', {
            method: 'POST',
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
            body: JSON.stringify({
                entityid: courseLessonId,
                documentsPerPage: docPerPage,
                page: pageNo
            })
        })
            .then((res) => res.json())
            .then((response) => {
                if (response) {
                    setTopicData(response);
                    setNoOfPages(response?.noOfPages);
                }
                setLoader(false);
            }).catch((err) => {
                console.log("Err while getting users", err);
                setLoader(false);
            })
    }

    // for custome hook call
    const dependencies = [searchField ? searchField : ""];
    useDebouncedApiCall(getAllCourseTask, dependencies, setLoader);


    // for deleter courseLesson
    const deleteCourseTask = async () => {
        setLoader(true);

        try {
            const result = await fetch(process.env.REACT_APP_BASE_URL + '/courseTask/deleteCourseTask', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({ taskId: delId })
            });
            const response = await result.json();
            if (response.message === 'Data deleted succussfully') {
                toast.success('CourseTask Deleted Succussfully');
                setShowDel(false);
                setDelId('');
                setLoader(false);
                getAllCourseTask();
            }
            else {
                toast.error('Failed to delete, try again');
                setShowDel(false);
                setDelId('');
                setLoader(false);
            }
        } catch (Err) {
            console.log("Err while deleting article", Err);
            setLoader(false);
        }
    }


    // function for pagination
    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }

    // for toggle
    const handlePublishAction = async (taskId, isPublish) => {
        try {
            setIsPublishModalOpen(false);
            setIsUnpublish(false);

            const result = await fetch(process.env.REACT_APP_BASE_URL + "/courseTask/publishCourseTask", {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({
                    taskId: taskId,
                    isPublish: isPublish
                }),
            });

            const response = await result.json();
            if (response) {
                getAllCourseTask();
            } else {
                console.log("Error toggling publish status");
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Function to handle confirming publish modal
    const handlePublishModalConfirm = async () => {
        await handlePublishAction(publishCourseTaskId, true);
    };

    // Function to handle confirming unpublish modal
    const handleUnpublishConfirm = async () => {
        await handlePublishAction(unpublishId, false);
    };

    return (
        <div className='Course '>
            <PublishModel
                isOpen={isPublishModalOpen}
                onClose={handlePublishModalCancel}
                onConfirm={handlePublishModalConfirm}
                title="Course task"
                loader={loader}
            />
            <UnPublishModel
                isOpen={isUnpublish}
                onClose={closeUnpublishModal}
                onConfirm={handleUnpublishConfirm}
                title="Course task"
                loader={loader}
            />
            <DeleteModel
                show={showDel}
                onHide={() => { setShowDel(false); setDelId(''); }}
                onDelete={deleteCourseTask}
                loader={loader}
                label="CourseTask"
            />
            <ToastContainer />
            <Container>
                <Row className='justify-content-end'>
                    <Col md={12} className='d-flex justify-content-between my-auto mt-3 mt-md-0'>
                        <Button className="backBtn" onClick={() => navigate(-1)}>
                            <Icon.ArrowLeft className="me-2" />
                            Back
                        </Button>
                    </Col>
                </Row>

                <Row className='justify-content-between mt-2 mb-2'>
                    <Col md={4} className="mb-2 mb-sm-0">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Search here"
                            name="search"
                            value={searchField}
                            onChange={(e) => {
                                if (e.target.value.trim()) {
                                    setSearchField(e.target.value)
                                    setPageNo(1);
                                } else if (e.target.value.length === 0) {
                                    setSearchField(e.target.value)
                                    setPageNo(1);
                                }

                            }}
                        ></input>
                    </Col>

                    <Col md={6} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
                        <Button className='primaryBtn' onClick={() => navigate("/dashboard/addTask", { state: { courseLessonId: courseLessonId, lessonTitleLang: lessonTitleLang } })}>
                            <Icon.JournalPlus className='me-2' size={16} />Add Task
                        </Button>
                    </Col>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center mb-4'>
                    <Icon.JournalPlus size={20} className='me-2' />Course Task
                </h3>


                <div className="outer-wrapper mx-auto mt-4">
                    <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
                        <tr><th>Lesson Title :</th> <td>{lessonTitleLang}</td> </tr>

                        <table className='mt-2'>
                            <thead>
                                <th>Sr.</th>
                                <th>Image</th>
                                <th >Task Title</th>
                                <th>Content Type</th>
                                <th>Duration</th>
                                <th>Publish</th>
                                <th>Action</th>
                            </thead>
                            {loader ? (
                                <Skeleton rows={10} cols={11} key={Math.random() * 999999999} />
                            ) :
                                (
                                    topicData?.data !== null && topicData?.data !== undefined && topicData?.data?.length > 0 ? (
                                        topicData?.data?.map((itm, index) => {
                                            const { imageUrl, title, titleLang, duration, content, taskId, entitytype, entityid } = itm;
                                            return (
                                                <tr key={index}>
                                                    <td>{pageNo !== 1 ? (
                                                        <>
                                                            {' '}
                                                            {index + 1 + docPerPage * (pageNo - 1)}
                                                        </>
                                                    ) : (
                                                        <>{index + 1}</>
                                                    )}</td>
                                                    <td key={index} className='d-flex flex-column'>
                                                        {imageUrl && (
                                                            <img src={itm?.imageUrl} alt={`Course ${index + 1} Img`} className='mb-3' width={40} />
                                                        )}
                                                    </td>
                                                    <td style={{ width: '200px' }}>{titleLang?.mr}</td>
                                                    <td style={{ width: '300px' }}>{content[0]}</td>
                                                    <td>{duration ? duration : "-"}</td>
                                                    <td style={{ width: '120px' }}>
                                                        {itm.isPublish ? (
                                                            <div
                                                                style={{
                                                                    backgroundColor: "#8FBC8F",
                                                                    borderRadius: "8px",
                                                                    padding: "4px 8px",
                                                                    color: "white",
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => openUnpublishModal(itm.taskId)}
                                                            >
                                                                Published
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="switch"
                                                                onClick={() => openPublishModal(itm.taskId)}
                                                            >
                                                                <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                                                                <span className="slider round" onClick={() => openPublishModal(itm.taskId)}></span>
                                                            </div>
                                                        )}

                                                    </td>

                                                    <td className="d-flex">
                                                        <img src={View} alt="View" className="icon me-3"
                                                            onClick={() => navigate("/dashboard/viewcoursetask", { state: { rowData: itm } })} />

                                                        <img src={Edit} alt="Edit" className="icon me-3" onClick={() => navigate("/dashboard/edittask", { state: { rowData: itm, courseLessonId: courseLessonId, lessonTitleLang: lessonTitleLang } })}
                                                        />

                                                        <img src={Delete} alt="Delete" className='icon'
                                                            onClick={() => { setShowDel(true); setDelId(taskId); }} />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <p className='noDataFound'>No Data Found</p>
                                    )
                                )
                            }

                        </table>
                    </div>
                </div>

                <PaginationSequence
                    data={topicData?.count}
                    pageNo={pageNo}
                    noOfPages={noOfPages}
                    handlePageChange={handlePageChange}
                    handleDocsPerPage={handleDocsPerPage}
                    docPerPage={docPerPage}
                />

            </Container>

        </div>
    )
}

export default CourceTask
