import { React, useState, useEffect } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Skeleton from '../../Common/Skeleton';
import moment from 'moment';
import Pagination from '../../Common/Pagination';
import { useNavigate } from 'react-router-dom';
import View from '../../../Assets/View.svg';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import * as Icon from 'react-bootstrap-icons';
import active from "../../../Assets/Animation - 1713857538350.gif"
import normal from "../../../Assets/gif/frame-88.png";
import inactive from "../../../Assets/gif/Ripple-1s-200px (1).gif";

const SubscriptionUser = () => {
  const navigate = useNavigate()
  const userState = useSelector((state) => state?.cmsLogin?.userData);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(25);
  const [noOfPages, setNoOfPages] = useState();

  const [loader, setLoader] = useState(false)
  const [subscriptionUsers, setSubscriptionUsers] = useState([])
  const [searchField, setSearchField] = useState("");
  const [subStartDate, setSubStartDate] = useState("")
  const [subEndDate, setSubEndDate] = useState("")

  useEffect(() => {
    getSubscriptionUsers()
  }, [pageNo, docPerPage])

  const getSubscriptionUsers = async (subStartDate, subEndDate) => {
    setLoader(true)
    await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllUserSubscriptions', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
      body: JSON.stringify({
        fromDate: subStartDate,
        toDate: subEndDate,
        name: searchField ? searchField : "",
        documentsPerPage: docPerPage,
        page: pageNo
      })
    })
      .then((response) => response.json())
      .then((res) => {
        setSubscriptionUsers(res?.data)
        setNoOfPages(res?.data?.noOfPages);
        setLoader(false)
      })
      .catch((error) => {
        setLoader(false)
        console.log("error", error);
      })
  }

  // for debounding
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getSubscriptionUsers, dependencies, setLoader);

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }

  return (
    <div className='mt-5'>
      <ToastContainer />
      <Container>
        <Row className='d-flex justify-content-between'>
          <Col md={4} className='mb-3 mb-md-0'>
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }
              }}
            />
          </Col>

          <Col md={8} className='d-flex justify-content-end align-items-center'>
            <div className='d-flex flex-row'>
              <input
                type="date"
                className="form-control me-2"
                placeholder="Search here"
                name="fromDate"
                value={subStartDate ? new Date(subStartDate).toISOString().split('T')[0] : ''}
                onChange={(e) => {
                  setSubStartDate(e.target.value)
                }}
                max={subEndDate}
              />

              <input
                type="date"
                className="form-control me-2"
                placeholder="Search here"
                name="toDate"
                value={subEndDate ? new Date(subEndDate).toISOString().split('T')[0] : ''}
                disabled={!subStartDate}
                min={subStartDate}
                onChange={(e) => {
                  setSubEndDate(e.target.value)
                }}
              />
              <Button className='primaryBtn me-2' onClick={() => { getSubscriptionUsers(subStartDate, subEndDate) }}><Icon.Search /></Button>

              <Button className='primaryBtn' onClick={() => { getSubscriptionUsers(setSubStartDate(""), setSubEndDate("")) }}><Icon.ArrowClockwise size={20} /></Button>
            </div>
          </Col>
        </Row>

        <Row className='mt-3'>
          <h5>Total Count : <span>{subscriptionUsers?.count}</span></h5>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
            <table>
              <thead>
                <th>Sr.No.</th>
                <th>Name</th>
                <th>Subscription Start Date</th>
                <th>Subscription End Date</th>
                <th>Subscription Status</th>
                <th>Payment Status</th>
                {/* <th>Action</th> */}
              </thead>
              {
                loader ? <Skeleton rows={10} cols={6} /> :
                  subscriptionUsers?.data !== null && subscriptionUsers?.data !== undefined && subscriptionUsers?.data?.length > 0 ? subscriptionUsers?.data?.map((itm, index) => {
                    const { fullName, subscriptionEndDate, subscriptionStartDate, isSbusciption, isPayment } = itm
                    return (
                      isSbusciption === true ?
                        <tr key={Math.random() * 999999999}>
                          <td>{pageNo !== 1 ? (<>{index + 1 + docPerPage * (pageNo - 1)}</>) : (<>{index + 1}</>)}</td>
                          <td style={{ width: '180px' }}>{fullName}</td>
                          <td style={{ width: '150px' }}>{moment(subscriptionStartDate).format("DD-MM-YYYY")}</td>
                          <td style={{ width: '150px' }}>{moment(subscriptionEndDate).format("DD-MM-YYYY")}</td>
                          <td>{isSbusciption === true ?
                            <>
                              <img width={50} height={50} src={normal} alt='Active' data-toggle="tooltip" title="Active" />
                              <span>Active</span>
                            </> :
                            <>
                              <img width={50} height={50} src={inactive} alt='Inactive' data-toggle="tooltip" title="Inactive" />
                              <span>Inactive</span>
                            </>}</td>
                          <td>{isPayment === true ? "Paid" : "Not Paid"}</td>
                          {/* <td className="d-flex">
                            <img src={View} alt="View" className="icon me-3"
                              onClick={() =>
                                navigate("/dashboard/view-subscription-user", {
                                  state: {
                                    userId: itm?.userId,
                                  },
                                })} />
                          </td> */}
                        </tr> : ""
                    )
                  })
                    : <p className='noDataFound'>No Data Found</p>
              }
            </table>
          </div>
        </div>

        <Row>
          <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
            <h6 className='text-start mb-0'>
              {/* <Icon.People className='me-2' />Total - <strong>{data?.count}</strong> */}
            </h6>
          </Col>
          <Col md={8} className='d-flex justify-content-end'>
            <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SubscriptionUser
