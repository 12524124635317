import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Button, Spinner, Modal } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { displayQuestionForOption } from '../../../../Utils/GlobalConfigs';
import QueAnsTab from '../../../Common/langugageToggle/QueAnsTab';

const EditFAQ = ({ show, onHide, getFAQList, faqid }) => {

    // use state for input field
    const [question, setQuestion] = useState("");
    const [questionmr, setQuestionmr] = useState("");
    const [questionhin, setQuestionHin] = useState("");
    const [answer, setAnswer] = useState("")
    const [answermr, setAnswerMr] = useState("")
    const [answerhin, setAnswerHin] = useState("")
    const [questionfor, setQuestionFor] = useState("")
    // Error variables
    const [error, setErrors] = useState({});

    // for loader
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        if (faqid) {
            setQuestion(faqid?.questionLang?.en || "");
            setQuestionmr(faqid?.questionLang?.mr || "");
            setQuestionHin(faqid?.questionLang?.hin || "")
            setAnswer(faqid?.answerLang?.en || "");
            setAnswerMr(faqid?.answerLang?.mr || "");
            setAnswerHin(faqid?.answerLang?.hin || "");
            setQuestionFor({ label: faqid.questionFor, value: faqid.questionFor } || "");
        }
    }, [faqid]);


    const validate = () => {
        let newErrors = {};

        if ((!question || question.trim() === '<p><br></p>') ||
            (!answer || answer.trim() === '<p><br></p>') ||
            (!questionmr || questionmr.trim() === '<p><br></p>') ||
            (!answermr || answermr.trim() === '<p><br></p>'))
        //  || (!questionhin || questionhin.trim() === '<p><br></p>') ||
        //      (!answerhin || answerhin.trim() === '<p><br></p>')) 
        {
            newErrors.description = "*Both question and answer in English and Marathi are required";
        }

        // if (!questionfor) {
        //     newErrors.questionfor = "*Required field";
        // }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const editFAQData = async () => {
        const isValid = validate();
        if (!isValid) return;
        setLoader(true)
        if (isValid) {
            let payload = {
                faqId: faqid?.faqId,
                question: question,
                answer: answer,
                questionFor: questionfor?.value,
                displayOn: "mobile",
                questionLang: { en: question, mr: questionmr, hin: questionhin },
                answerLang: { en: answer, mr: answermr, hin: answerhin }
            };

            await fetch(process.env.REACT_APP_BASE_URL + "/faq/updateFaq", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
            })
                .then((res) => res.json())
                .then((response) => {
                    if (response.message === "Updated Successfully") {
                        toast.success("Updated Successfully")
                        getFAQList()
                        setQuestion('')
                        setAnswer('')
                        setQuestionFor('')
                        onHide()
                        setLoader(false)
                    }
                }).catch((err) => {
                    console.log("Error", err);
                    setLoader(false)
                })
        }
    };

    return (
        <>
            <ToastContainer />
            <Modal size='lg' show={show} backdrop='static' keyboard={false} centered onHide={onHide}>
                <Modal.Header closeButton>
                    <Modal.Title> <h4 className='mb-0'>
                        <Icon.ChatQuote size={20} className='me-2' />Edit FAQ</h4>
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Row>
                            {/* for question answer  */}
                            <QueAnsTab
                                questionmr={questionmr}
                                setQuestionmr={setQuestionmr}
                                answermr={answermr}
                                setAnswerMr={setAnswerMr}
                                question={question}
                                setQuestion={setQuestion}
                                answer={answer}
                                setAnswer={setAnswer}
                                questionhin={questionhin}
                                setQuestionHin={setQuestionHin}
                                answerhin={answerhin}
                                setAnswerHin={setAnswerHin}
                            />
                        </Row>
                        {error.description && (
                            <p className="errMsg mt-1">{error.description}</p>
                        )}

                        <Col md={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Question For</Form.Label>
                                <Select
                                    placeholder="Select QuestionFor"
                                    value={questionfor}
                                    onChange={(option) => setQuestionFor(option)}
                                    options={displayQuestionForOption}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                </Modal.Body>

                <Modal.Footer>
                    {loader ? (
                        <Spinner variant="primary" />
                    ) : (
                        <>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5%", marginTop: '4%' }}>
                                <Button className="primaryBtn me-4" onClick={editFAQData}>
                                    <Icon.Save className="me-1" />
                                    Update
                                </Button>

                            </div>
                        </>
                    )}
                </Modal.Footer>
            </Modal >
        </>


    )
}

export default EditFAQ
