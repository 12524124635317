import React from 'react';
import { Modal, Form, Button } from 'react-bootstrap';
import Select from 'react-select';
import { genderOptions, ageOptions } from '../../../Utils/GlobalConfigs';

const NotificationFilterModel = ({ show, handleClose, handleFilterSubmit, handleFilterReset, pincodeOptions, pincode, setPincode, age, setAge, gender, setGender, minage, maxage, setMinAge, setMaxAge }) => {

    const handleAgeChange = (selectedOption) => {
        if (selectedOption) {
            setMinAge(selectedOption.minAge);
            setMaxAge(selectedOption.maxAge);
        } else {
            setMinAge('');
            setMaxAge('');
        }
    };
    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Filter Users</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group className="mb-3" controlId="pincode">
                        <Form.Label>Pincode</Form.Label>
                        <Select
                            value={pincode}
                            onChange={(selectedOption) => setPincode(selectedOption)}
                            options={pincodeOptions.map((option) => ({ value: option.value, label: option.label }))}
                            placeholder="Select Pincode"
                            isSearchable
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="age">
                        <Form.Label>Age</Form.Label>
                        <Select
                            value={ageOptions.find(option => option.minAge === minage && option.maxAge === maxage) || null}
                            onChange={handleAgeChange}
                            options={ageOptions}
                            getOptionLabel={(option) => `${option.minAge}-${option.maxAge}`}
                            getOptionValue={(option) => `${option.minAge}-${option.maxAge}`}
                            placeholder="Select Age"
                            isSearchable
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="pincode">
                        <Form.Label>Gender</Form.Label>
                        <Select
                            value={gender}
                            onChange={(selectedOption) => setGender(selectedOption)}
                            options={genderOptions.map((option) => ({ value: option.value, label: option.label }))}
                            placeholder="Select Gender"
                            isSearchable
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button className="primaryBtn" onClick={handleFilterSubmit}>Submit</Button>
                <Button className="secondaryBtn" onClick={handleFilterReset}>Reset</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default NotificationFilterModel;
