import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Icon from "react-bootstrap-icons";
import videoLogo from "../../../../Assets/video-icon.svg";
import NoImage from '../../../../Assets/NoImage.svg';


const UploadEventVideoList = ({ eventvideolist, setEventVideoList }) => {
    const userState = useSelector((state) => state?.cmsLogin?.userData.data);

    // for delete upload post event video
    const handlerRemoveVideo = async (eventVideoId) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/eventVideos/deleteEventVideo`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${userState.token}`
                },
                body: JSON.stringify({ eventVideoId: eventVideoId })
            });
            const data = await response.json();
            if (response.ok) {
                setEventVideoList(eventvideolist.filter(video => video.eventVideoId !== eventVideoId));
            } else {
                console.error("Failed to delete video", data);
            }
        } catch (error) {
            console.log("Error:", error);
        }
    };
    return (
        <>
            {/* for upload post event video list */}
            <tr>
                <th>
                    <Icon.Code className="me-2" />
                    Upload Post Event Video
                </th>

                <td>
                    {eventvideolist?.length > 0 ? (
                        <Row className="mt-3" style={{ flexWrap: "wrap" }}>
                            {eventvideolist?.map((itm, index) => (
                                <Col md={3} key={index}>
                                    <Card className="d-flex uploadedFile mb-3 h-100">
                                        <Card.Body className="d-flex justify-content-between align-items-center p-0">
                                            <a target="_blank" href={itm?.eventVideoUrl}>
                                                <img src={videoLogo} alt="Video" style={{ width: 60, height: 60 }} />
                                            </a>

                                            <a target="_blank" href={itm?.imageURL}>
                                                <img src={itm?.imageURL} style={{ width: 60, height: 60 }} alt="Image" />
                                            </a>

                                            <Icon.XCircleFill
                                                className="removeIcon"
                                                size={18}
                                                onClick={() => handlerRemoveVideo(itm?.eventVideoId)}
                                                style={{ cursor: "pointer" }}
                                            />
                                        </Card.Body>

                                        <Card.Body className='p-0'>
                                            <Card.Text><p className="fw-bold m-0 " style={{ fontSize: '13px' }}>Post Event Description:</p>{itm?.videoShortDescLang?.en ? (
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: itm?.videoShortDescLang?.en,
                                                    }}
                                                ></div>
                                            ) : (
                                                "-"
                                            )}
                                            </Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <img src={NoImage} alt="No Video" width={60} />
                    )}
                </td>
            </tr>

        </>
    )
}

export default UploadEventVideoList