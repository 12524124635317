import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from '../../Common/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../../Common/ActiveKeySlice';
import PublishModel from '../../Common/Model/PublishModel';
import UnPublishModel from '../../Common/Model/UnPublishModel';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import DeleteModel from '../../Common/Model/DeleteModel';
import PaginationSequence from '../../Common/Pagination/PaginationSequence';


const HomeBannerList = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userState = useSelector((state) => state?.cmsLogin?.userData.data);

    // state for data
    const [data, setData] = useState([]);

    //state for loader
    const [loader, setLoader] = useState(false);
    const [searchField, setSearchField] = useState("");
    const [delId, setDelId] = useState('');
    const [showDel, setShowDel] = useState(false);
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [publishProductId, setPublishProductId] = useState(null);

    // Unpublish video variables
    const [isUnpublish, setIsUnpublish] = useState(false);
    const [unpublishId, setUnpublishId] = useState("");


    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(25);
    const [noOfPages, setNoOfPages] = useState();


    // Function to handle opening the publish modal
    const openPublishModal = (shopeeId) => {
        setPublishProductId(shopeeId);
        setIsPublishModalOpen(true);
    };

    const handlePublishModalCancel = () => {
        setIsPublishModalOpen(false);
    };

    // Function to handle opening the unpublish modal
    const openUnpublishModal = (shopeeId) => {
        setUnpublishId(shopeeId);
        setIsUnpublish(true);
    };

    // Function to handle cancelling the unpublish modal
    const closeUnpublishModal = () => {
        setIsUnpublish(false);
    };


    // function for pagination
    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }

    useEffect(() => {
        getAllHomeBanner()
    }, [docPerPage, pageNo])

    // ------- Get All Home Banner Data ----------------------
    const getAllHomeBanner = async () => {
        try {
            setLoader(true);
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/homeBanner/getAllHomeBannersPGN', {
                method: 'POST',
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
                body: JSON.stringify({
                    title: searchField ? searchField : "",
                    documentsPerPage: docPerPage,
                    page: pageNo
                })
            })
            const responseData = await response.json();
            if (response.message === "Authorization failed / Forbidden") {
                setLoader(true);
                dispatch(setLogout(null));
                dispatch(setActiveKey(null));

                localStorage.removeItem('persist:root');
                navigate('/');
            } else {
                setData(responseData);
                setNoOfPages(responseData?.noOfPages);
                setLoader(false);
            }

        } catch (Err) {
            console.log("Err while getting activites", Err);
            setLoader(false);
        }
    }

    // for custome hook call
    const dependencies = [searchField ? searchField : ""];
    useDebouncedApiCall(getAllHomeBanner, dependencies, setLoader);


    // for delete home banner data
    const deleteHomeBanner = async () => {
        setLoader(true);
        try {
            const result = await fetch(process.env.REACT_APP_BASE_URL + '/homeBanner/deleteHomeBanner', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.token}` },
                body: JSON.stringify({ homeBannerId: delId })
            });
            const response = await result.json();
            if (response.message === 'Home banner deleted successfully') {
                toast.success('Deleted Succussfully');
                setShowDel(false);
                setDelId('');
                getAllHomeBanner();
            }
            else {
                toast.error('Failed to delete, try again');
                setShowDel(false);
                setDelId('');
            }
        } catch (Err) {
            console.log("Err while deleting article", Err);
        } finally {
            setLoader(false);
        }
    }

    // for toggle
    const handlePublishAction = async (homeBannerId, isPublish) => {
        try {
            setIsPublishModalOpen(false);
            setIsUnpublish(false);
            const result = await fetch(process.env.REACT_APP_BASE_URL + "/homeBanner/publishHomeBanner", {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
                body: JSON.stringify({
                    homeBannerId: homeBannerId,
                    isPublish: isPublish
                }),
            });

            const response = await result.json();
            if (response) {
                getAllHomeBanner();
            } else {
                console.log("Error toggling publish status");
            }
        } catch (error) {
            console.log(error);
        }
    };
    // Function to handle confirming publish modal
    const handlePublishModalConfirm = async () => {
        await handlePublishAction(publishProductId, true);
    };

    // Function to handle confirming unpublish modal
    const handleUnpublishConfirm = async () => {
        await handlePublishAction(unpublishId, false);
    };


    return (
        <div className='HomeBanner outletPadding'>
            <PublishModel
                isOpen={isPublishModalOpen}
                onClose={handlePublishModalCancel}
                onConfirm={handlePublishModalConfirm}
                title="Home Banner"
                loader={loader}
            />
            <UnPublishModel
                isOpen={isUnpublish}
                onClose={closeUnpublishModal}
                onConfirm={handleUnpublishConfirm}
                title="Home Banner"
                loader={loader}
            />
            <DeleteModel
                show={showDel}
                onHide={() => { setShowDel(false); setDelId(''); }}
                onDelete={deleteHomeBanner}
                loader={loader}
                label="Home Banner"
            />
            <ToastContainer />
            <Container>
                <Row className='justify-content-between'>
                    <Col md={4} className="mb-2 mb-sm-0">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Search here"
                            name="search"
                            value={searchField}
                            onChange={(e) => {
                                if (e.target.value.trim()) {
                                    setSearchField(e.target.value)
                                    setPageNo(1);
                                } else if (e.target.value.length === 0) {
                                    setSearchField(e.target.value)
                                    setPageNo(1);
                                }
                            }}
                        ></input>
                    </Col>
                    <Col md={3} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
                        <Button className='primaryBtn' onClick={() => navigate('/dashboard/add-homebanner')}>
                            <Icon.BagPlus className='me-2' size={16} />Add Home Banner
                        </Button>
                    </Col>
                </Row>

                <div className="outer-wrapper mx-auto mt-4">
                    <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
                        <table>
                            <thead>
                                <th>Sr.</th>
                                <th>Banner Image</th>
                                <th>Title</th>
                                <th>Priority</th>
                                <th>Publish</th>
                                <th>Action</th>
                            </thead>
                            {
                                loader ? <Skeleton rows={9} cols={9} /> :
                                    data?.data !== null && data?.data !== undefined && data?.data?.length > 0 ? data?.data?.map((itm, index) => {
                                        const { bannerImgURL, title, priorityNo, homeBannerId } = itm
                                        return (

                                            <tr key={Math.random() * 999999999}>
                                                <td>{pageNo !== 1 ? (
                                                    <>{' '} {index + 1 + docPerPage * (pageNo - 1)} </>
                                                ) : (<>{index + 1}</>)}</td>
                                                <td><img src={bannerImgURL} alt="HomeBanner Profile" width={40} /></td>
                                                <td style={{ width: '200px' }}>{title}</td>
                                                <td>{priorityNo}</td>
                                                <td style={{ width: '120px' }}>
                                                    {itm.isPublish ? (
                                                        <div
                                                            style={{
                                                                backgroundColor: "#8FBC8F",
                                                                borderRadius: "8px",
                                                                padding: "4px 8px",
                                                                color: "white",
                                                                textAlign: "center",
                                                                cursor: "pointer"
                                                            }}
                                                            onClick={() => openUnpublishModal(itm.homeBannerId)}
                                                        >
                                                            Published
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="switch"
                                                            onClick={() => openPublishModal(itm.homeBannerId)}
                                                        >
                                                            <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                                                            <span className="slider round" onClick={() => openPublishModal(itm.homeBannerId)}></span>
                                                        </div>
                                                    )}

                                                </td>
                                                <td className="d-flex">
                                                    <img src={View} alt="View" className="icon me-3"
                                                        onClick={() =>
                                                            navigate("/dashboard/view-homebanner", {
                                                                state: {
                                                                    homebanner: itm,
                                                                },
                                                            })} />

                                                    <img src={Edit} alt="Edit" className="icon me-3"
                                                        onClick={() =>
                                                            navigate("/dashboard/edit-homebanner", {
                                                                state: itm,

                                                            })}
                                                    />

                                                    <img src={Delete} alt="Delete" className='icon'
                                                        onClick={() => { setShowDel(true); setDelId(homeBannerId); }} />
                                                </td>
                                            </tr>
                                        )
                                    })
                                        : <p className='noDataFound'>No Data Found</p>
                            }
                        </table>
                    </div>
                </div>

                <PaginationSequence
                    data={data?.count}
                    pageNo={pageNo}
                    noOfPages={noOfPages}
                    handlePageChange={handlePageChange}
                    handleDocsPerPage={handleDocsPerPage}
                    docPerPage={docPerPage}
                />

            </Container>
        </div>
    )
}

export default HomeBannerList