import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row, Form, Spinner, InputGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import * as Icon from "react-bootstrap-icons";
import normal from "../../../Assets/gif/frame-88.png";
import inactive from "../../../Assets/gif/Ripple-1s-200px (1).gif";
import { setActiveNav, setActiveTab } from '../../Common/ActiveKeySlice';
import moment from 'moment';

const ViewNotSubscriptionUser = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const location = useLocation()
    const userData = location?.state?.user


    // usestate
    const [showsub, setShowSub] = useState(false);
    const [loader, setLoader] = useState(false);
    const [subuserid, setSubUserId] = useState('');

    const addSubscriptions = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/cms/updateUserSubscription', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState?.data?.token}` },
            body: JSON.stringify({
                userId: subuserid
            })
        })
            .then((response) => response.json())
            .then((res) => {
                if (res?.message == "Update User Subscription successfully ") {
                    toast.success("User subscription updated !")
                }
                dispatch(setActiveNav("subscribed"))
                navigate("/dashboard/subscription-user")
                setLoader(false)
                setShowSub(false)
            })
            .catch((error) => {
                console.log("error", error);
            })
    }

    return (
        <>
            <div className='profile outletPadding' >
                <Container>
                    <div className='d-flex justify-content-between'>
                        <Button className="backBtn" onClick={() => navigate(-1)}>
                            <Icon.ArrowLeft className="me-2" />
                            Back
                        </Button>
                        <Button className='primaryBtn w-auto' onClick={() => { setShowSub(true); setSubUserId(userData?.userId) }}><Icon.Lock className='me-1' />Add Subscription</Button>
                    </div>

                    <Row className='mt-3'>
                        <Col md={5}>
                            <h5>User Non-Subscription Details</h5>
                            <Card>
                                <div>
                                    <h6><Icon.PersonFill className='me-2' />FullName -</h6>
                                    <p>{userData ? userData?.fullName : "-"}</p>
                                </div>
                                <div>
                                    <h6><Icon.TelephoneFill className='me-2' />Mobile Number -</h6>
                                    <p>{userData ? userData?.mobile : "-"}</p>
                                </div>
                                <div>
                                    <h6><Icon.Calendar2DateFill className='me-2' />Date of Birth -</h6>
                                    <p>{moment(userData?.dob).format("DD-MM-YYYY")}</p>
                                </div>
                                <div>
                                    <h6>{userData?.isCoursePurchase === true ? <Icon.ToggleOn className='me-2' /> : <Icon.ToggleOff className='me-2' />}Subscription Status - </h6>
                                    <p>{userData?.isCoursePurchase === true ?
                                        <>
                                            <img width={50} height={50} src={normal} alt='Active' data-toggle="tooltip" title="Active" />
                                            <span>Active</span>
                                        </> :
                                        <>
                                            <img width={50} height={50} src={inactive} alt='Inactive' data-toggle="tooltip" title="Inactive" />
                                            <span>Inactive</span>
                                        </>}</p>
                                </div>

                            </Card>
                        </Col>
                    </Row>

                </Container >

            </div >

            {/* model for confirmation subscription */}
            <Modal Modal
                size="md"
                show={showsub}
                onHide={() => setShowSub(false)}
                backdrop="static"
                keyboard={false}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to Subscribe user?
                </Modal.Body>
                <Modal.Footer>
                    {loader ? (
                        <Spinner animation="border" variant="primary" />
                    ) : (
                        <>
                            <Button className='primaryBtn me-3' onClick={addSubscriptions} >
                                <Icon.HandThumbsUp className='me-1' />Yes</Button>
                            <Button className='secondaryBtn' onClick={() => setShowSub(false)} >
                                <Icon.HandThumbsDown className='me-1' />No</Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal >

        </>
    )
}

export default ViewNotSubscriptionUser
