import React, { useState, useEffect } from 'react';

// Custom hook for debouncing API calls
function useDebouncedApiCall(apiCall, dependencies, setLoading, delay = 2000) {
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (!initialLoad) {
            setLoading(true);
            const delayDebounce = setTimeout(async () => {
                await apiCall();
                setLoading(false);
            }, delay);

            return () => clearTimeout(delayDebounce);
        } else {
            apiCall().then(() => setLoading(false));
            setInitialLoad(false);
        }
    }, dependencies);

    return initialLoad;
}
export default useDebouncedApiCall;