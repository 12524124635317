import React from 'react'
import * as Icon from "react-bootstrap-icons"
import { Button, Modal, Spinner } from 'react-bootstrap';

const DeleteModel = ({ show, onHide, onDelete, loader, label }) => {
    return (
        <Modal size='md' show={show} backdrop='static' keyboard={false} centered onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <h5 className='mb-0'>
                        <Icon.XCircleFill size={20} className='me-2' />Delete {label}
                    </h5>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className='text-center'>
                <div data-aos='zoom-in'>
                    <Icon.XCircleFill color='red' width={80} height={80} />
                    <h4 style={{ fontWeight: '600', marginTop: '12px' }}>Are you sure to delete this {label} ?</h4>
                </div>
            </Modal.Body>

            <Modal.Footer>
                {
                    loader ? <Spinner variant='primary' /> :
                        <>
                            <Button className='primaryBtn me-3' onClick={onDelete}>
                                <Icon.HandThumbsUp className='me-1' />Yes</Button>
                            <Button className='secondaryBtn' onClick={onHide}>
                                <Icon.HandThumbsDown className='me-1' />No</Button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteModel