import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';

const initialState = {
  userData: '',
  loading: 'idle', // idle, pending, failed
};

export const getUserData = createAsyncThunk("getUserDataPromise", async (data, { rejectWithValue }) => {
  const response = await fetch(process.env.REACT_APP_BASE_URL + '/user/login', {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data)
  })
  try {
    const result = await response.json();
    sessionStorage.setItem("userInfo", JSON.stringify(result));
    useNavigate('/dashboard')
    return result;
  } catch (error) {
    return rejectWithValue(error);
  }
}
)

const loginSlice = createSlice({
  name: 'userData',
  initialState,

  // for empty all state after logout
  reducers: {
    setLogout(state, action) {
      // console.log("SetLogout", state, action)
    },
    setUserData(state, action) {
      state.userData = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUserData.pending, (s, a) => {
        s.loading = 'pending';
      })
      .addCase(getUserData.rejected, (s, a) => {
        s.loading = 'idle';
        alert('Failed');
      })
      .addCase(getUserData.fulfilled, (s, a) => {
        s.userData = a.payload;
        s.loading = 'success';
      });
  },
});


export const { setLogout, setUserData } = loginSlice.actions;
export default loginSlice.reducer;