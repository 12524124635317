import React, { useState } from "react";
import { Form, Tab, Nav } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
window.Buffer = window.Buffer || require("buffer").Buffer;

const GuruNameTab = ({ firstNameMr, setFirstNameMr, firstNameEn, setFirstNameEn, firstNameHn, setFirstNameHn,
    middleNameMr, setMiddleNameMr, middleName, setMiddleName, middleNameHn, setMiddleNameHn, lastNameMr,
    setLastNameMr, lastNameEn, setLastNameEn, lastNameHn, setLastNameHn, }) => {

    // for language
    const [key, setkey] = useState('mr');
    const activeKeyRedux = useSelector((state) => state?.cmsActiveData?.activeNumberKey);

    const handleTabSelect = (key) => {
        setkey(key);
    };


    // Check if Marathi descriptions are filled
    const isMarathiFilled = (
        (firstNameMr?.trim() !== '' && firstNameMr?.trim() !== '<p><br></p>') &&
        (lastNameMr?.trim() !== '' && lastNameMr?.trim() !== '<p><br></p>')
    );

    // Check if English descriptions are filled
    const isEnglishFilled = (
        (firstNameEn?.trim() !== '' && firstNameEn?.trim() !== '<p><br></p>') &&
        (lastNameEn?.trim() !== '' && lastNameEn?.trim() !== '<p><br></p>')
    );;
    // Check if Hindi descriptions are filled
    const isHindiFilled = (
        ""
        // (firstNameHn?.trim() !== '' && firstNameHn?.trim() !== '<p><br></p>') &&
        // (lastNameHn?.trim() !== '' && lastNameHn?.trim() !== '<p><br></p>')
    );
    return (
        <div className="ChromeTabs">
            <Tab.Container activeKey={key} onSelect={handleTabSelect}>
                <Nav variant="tabs" className="flex-row">
                    <Nav.Item>
                        <Nav.Link eventKey="mr" className={isMarathiFilled ? "filled-tab" : ""}>
                            Marathi
                            {isMarathiFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="en" className={isEnglishFilled ? "filled-tab" : ""}>
                            English {isEnglishFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item >
                        <Nav.Link eventKey="hn" className={isHindiFilled ? "filled-tab" : ""} disabled>
                            Hindi {isHindiFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                    {activeKeyRedux?.activeTab === "Article List" &&
                        <p className="mt-2 fs-6">
                            (Recommanded image resolution - 4/5 (1080*1350 px))
                        </p>}
                </Nav>


                <Tab.Content>
                    <Tab.Pane eventKey="mr" className="mt-3">
                        <div data-aos="fade-up">

                            <Form.Group className='mb-3'>
                                <Form.Label>First Name in Marathi<span className='errMsg'>*</span></Form.Label>
                                <Form.Control placeholder="Enter first name in marathi" id="firstNameMr" value={firstNameMr}
                                    onChange={(e) => {
                                        const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                        if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                            setFirstNameMr(e.target.value);
                                        } else if (e.target.value.length === 0) {
                                            setFirstNameMr(e.target.value);
                                        }
                                    }} />

                            </Form.Group>
                            {activeKeyRedux?.activeTab === "Teacher" &&
                                <Form.Group className='mb-3'>
                                    <Form.Label>Middle Name in Marathi</Form.Label>
                                    <Form.Control placeholder="Enter middle Name in marathi" id="middleName" value={middleNameMr}
                                        onChange={(e) => {
                                            const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                            if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                                setMiddleNameMr(e.target.value);
                                            } else if (e.target.value.length === 0) {
                                                setMiddleNameMr(e.target.value);
                                            }
                                        }} />
                                </Form.Group>
                            }


                            <Form.Group className='mb-3'>
                                <Form.Label>Last Name in Marathi<span className='errMsg'>*</span></Form.Label>
                                <Form.Control placeholder="Enter last name in marathi" id="lastNameMr" value={lastNameMr}
                                    onChange={(e) => {
                                        const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                        if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                            setLastNameMr(e.target.value);
                                        } else if (e.target.value.length === 0) {
                                            setLastNameMr(e.target.value);
                                        }
                                    }} />

                            </Form.Group>

                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="en" className="mt-3">
                        {
                            key === "en" &&
                            <div data-aos="fade-up">

                                <Form.Group className='mb-3'>
                                    <Form.Label>First Name in English<span className='errMsg'>*</span></Form.Label>
                                    <Form.Control placeholder="Enter first name in english" id="firstNameEn" value={firstNameEn}
                                        onChange={(e) => {
                                            if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                                e.target.value.trim()
                                            ) {
                                                setFirstNameEn(e.target.value);
                                            } else if (e.target.value.length === 0) {
                                                setFirstNameEn(e.target.value);
                                            }
                                        }} />

                                </Form.Group>
                                {activeKeyRedux?.activeTab === "Teacher" &&
                                    <Form.Group className='mb-3'>
                                        <Form.Label>Middle Name in English</Form.Label>
                                        <Form.Control placeholder="Enter middle Name in english" id="middleName" value={middleName}
                                            onChange={(e) => {
                                                if (!e.target.value.match(/[\u0900-\u097F]/) && e.target.value.trim()) {
                                                    setMiddleName(e.target.value);
                                                } else if (e.target.value.length === 0) {
                                                    setMiddleName(e.target.value);
                                                }
                                            }} />
                                    </Form.Group>
                                }

                                <Form.Group className='mb-3'>
                                    <Form.Label>Last Name in English<span className='errMsg'>*</span></Form.Label>
                                    <Form.Control placeholder="Enter last name in english" id="lastNameEn" value={lastNameEn}
                                        onChange={(e) => {
                                            if (!e.target.value.match(/[\u0900-\u097F]/) &&
                                                e.target.value.trim()
                                            ) {
                                                setLastNameEn(e.target.value);
                                            } else if (e.target.value.length === 0) {
                                                setLastNameEn(e.target.value);
                                            }
                                        }} />

                                </Form.Group>

                            </div>
                        }
                    </Tab.Pane>
                    <Tab.Pane eventKey="hn" className="mt-3" >
                        {
                            key === "hn" &&
                            <div data-aos="fade-up">

                                <Form.Group className='mb-3'>
                                    <Form.Label>First Name in Hindi<span className='errMsg'>*</span></Form.Label>
                                    <Form.Control placeholder="Enter first name in hindi" id="firstNameHn" value={firstNameHn}
                                        onChange={(e) => {
                                            const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                            if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                                setFirstNameHn(e.target.value);
                                            } else if (e.target.value.length === 0) {
                                                setFirstNameHn(e.target.value);
                                            }
                                        }} />
                                </Form.Group>


                                {activeKeyRedux?.activeTab === "Teacher" &&

                                    <Form.Group className='mb-3'>
                                        <Form.Label>Middle Name in Hindi</Form.Label>
                                        <Form.Control placeholder="Enter middle Name in hindi" id="middleName" value={middleNameHn}
                                            onChange={(e) => {
                                                if (!e.target.value.match(/[\u0900-\u097F]/) && e.target.value.trim()) {
                                                    setMiddleNameHn(e.target.value);
                                                } else if (e.target.value.length === 0) {
                                                    setMiddleNameHn(e.target.value);
                                                }
                                            }} />
                                    </Form.Group>
                                }


                                <Form.Group className='mb-3'>
                                    <Form.Label>Last Name in Hindi<span className='errMsg'>*</span></Form.Label>
                                    <Form.Control placeholder="Enter last name in hindi" id="lastNameHn" value={lastNameHn}
                                        onChange={(e) => {
                                            const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                            if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                                setLastNameHn(e.target.value);
                                            } else if (e.target.value.length === 0) {
                                                setLastNameHn(e.target.value);
                                            }
                                        }} />

                                </Form.Group>

                            </div>
                        }
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div >
    )
}

export default GuruNameTab