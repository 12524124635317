import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../ActiveKeySlice';
import { useNavigate } from 'react-router-dom';


const useContentList = (token, setLoader) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [contentList, setContentList] = useState([]);


    const getVideoList = async () => {
        try {
            setLoader(true);
            const result = await fetch(process.env.REACT_APP_BASE_URL + "/priority/titleTypeFilter", {
                method: "POST",
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
                body: JSON.stringify({
                    type: "Video",
                })
            });

            const response = await result.json();
            if (response.message === "Authorization failed / Forbidden") {
                setLoader(true);
                dispatch(setLogout(null));
                dispatch(setActiveKey(null));

                localStorage.removeItem('persist:root');
                navigate('/');
            } else if (response.status === 200) {
                setContentList(response?.data);
                setLoader(false);
            } else {
                setContentList([]);
                setLoader(false);
            }
        } catch (err) {
            console.log("Error while getting video list", err);
            setLoader(false);
        }
    };

    return { contentList, getVideoList }
}

export default useContentList