import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getAllCategories } from '../../Common/Categories';
import { Col, Container, Row, Button, Form, Spinner, Card } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons";
import Select from "react-select";
import { toast } from "react-toastify";
import ReactS3 from "../../../Utils/ReactS3";
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import { default as ReactSelect } from "react-select";
import moment from 'moment';
import Lottie from 'react-lottie';
import imageUploading from "../../../Assets/ImageUploading.json"
import ChromeTab from '../../Common/langugageToggle/ChromeTab';
import ButtonComponent from '../../Common/Button/ButtonComponent';
import { eventViaOption, typeOption, onOffOption, YesNoOption, cityInfo } from "../../../Utils/GlobalConfigs";
import useGetPageList from '../../Common/Reuse/useGetPageList';

const EditEvent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();
  const event = location?.state?.event;

  const userState = useSelector((state) => state?.cmsLogin?.userData.data.user);
  const token = useSelector((state) => state?.cmsLogin?.userData.data.token);
  const getData = useSelector(state => state.categories?.categoryList?.data);

  // call custom hook for page title
  const { pageData } = useGetPageList();

  // Update event variables
  const [uploading, setUploading] = useState('');
  const [loader, setLoader] = useState(false);
  const [title, setTitle] = useState(event?.event_titleLang?.en);
  const [titleMr, setTitleMr] = useState(event?.event_titleLang?.mr)
  const [titleHn, setTitleHn] = useState(event?.event_titleLang?.hin)
  const [eventVenue, setEventVenue] = useState(event?.event_venue);
  const [eventType, setEventType] = useState({ label: event?.event_type, value: event?.event_type });
  const [category, setCategory] = useState({ label: event?.event_category, value: event?.event_category })
  const [eventvia, setEventVia] = useState({ label: event?.event_via, value: event?.event_via });
  const [eventViaLink, setEventViaLink] = useState(event?.eventOutSideLink);
  const [eventMedia, setEventMedia] = useState({ label: event?.event_media, value: event?.event_media });
  const [eventLink, setEventLink] = useState(event?.event_link);
  const [eventImages, SetEventImages] = useState(event?.event_images.map((itm) => itm));
  const [eventDocs, SetEventDocs] = useState(event?.event_documents.map((itm) => itm));
  const [bannerImages, SetBannerImages] = useState(event?.promotion_banner_images.map((itm) => itm));

  const [state, setState] = useState({ label: event?.state, value: event?.state });
  const [cities, SetCities] = useState(event?.cities?.map((itm) => ({ label: itm, value: itm })));
  const [eventYoutubeVideo, setEventYoutubeVideo] = useState(event?.event_youtube_video);

  const [eventDate, setEventDate] = useState(moment(event?.event_date).format('YYYY-MM-DD'));
  const [eventEndDate, setEventEndDate] = useState(moment(event?.event_end_date).format('YYYY-MM-DD'));
  const [eventLastdateReg, setEventLastdateReg] = useState(moment(event?.event_last_date_registration).format('YYYY-MM-DD'));
  const [eventStartTime, setEventStartTime] = useState(event?.event_start_time);
  const [eventEndTime, setEventEndTime] = useState(event?.event_end_time);

  const [tags, setTags] = useState(event?.tags.map((itm) => ({ label: itm, value: itm })));
  const [isRegistrationOver, setIsRegistrationOver] = useState({ label: event?.is_registration_over === true ? "Yes" : "No", value: event?.is_registration_over === true ? "Yes" : "No" });
  const [isPostpone, setIsPostpone] = useState({ label: event?.isDatePostpone === true ? "Yes" : "No", value: event?.isDatePostpone === true ? "Yes" : "No" });
  const [isPaid, setIsPaid] = useState({ label: event?.is_paid === true ? "Yes" : "No", value: event?.is_paid === true ? "Yes" : "No" });
  const [pageTitle, setpageTitle] = useState({ label: event?.pageName, value: event?.pageId, Lang: event?.pageNameLang })


  const [eventAmount, setEventAmount] = useState(0);
  const [eventGroupAmount, setEventGroupAmount] = useState(0);
  const [eventAmountWithoutGST, setEventAmountWithoutGST] = useState(0);
  const [eventGroupAmountWithoutGST, setEventGroupAmountWithoutGST] = useState(0);
  const [eventDiscount, setEventDiscount] = useState(0);

  const [minCapacity, setMinCapacity] = useState(1);
  const [maxCapacity, setMaxCapacity] = useState(1);
  const [eventPinCode, setEventPinCode] = useState(event?.event_pincode);
  const [eventAddress1, setEventAddress1] = useState(event?.event_address);
  const [eventAddress2, setEventAddress2] = useState(event?.event_address2);
  const [googleLink, setGoogleLink] = useState(event?.event_map_link)

  const [isScheduledInfo, setIsScheduledInfo] = useState({ label: event?.isScheduledAdded === true ? "Yes" : "No", value: event?.isScheduledAdded === true ? "Yes" : "No" });
  const [isSeatFillMessage, SetIsSeatFillMessage] = useState({ label: event?.isScheduledAdded === true ? "Yes" : "No", value: event?.isScheduledAdded === true ? "Yes" : "No" });
  const [scheduledInfo, setScheduledInfo] = useState(event?.scheduledInfo);
  const [seatFillMessage, setSeatFillMessage] = useState(event?.seatFillMessage);

  const [description, setDescription] = useState(event?.event_descriptioLang?.en);
  const [descriptionMr, setDescriptionMr] = useState(event?.event_descriptioLang?.mr);
  const [descriptionHn, setDescriptionHn] = useState(event?.event_descriptioLang?.hin);
  const [fullDescription, setFullDescription] = useState(event?.event_full_descriptionLang?.en);
  const [fullDescriptionMr, setFullDescriptionMr] = useState(event?.event_full_descriptionLang.mr);
  const [fullDescriptionHn, setFullDescriptionHn] = useState(event?.event_full_descriptionLang.hin);
  const [sponsers, SetSponsers] = useState([]);
  const [videos, SetVideos] = useState("");
  const [gallary, SetGallary] = useState([]);
  const [eventTypeDesc, setEventTypeDesc] = useState("");
  const [tagList, setTagList] = useState([]);


  const [fileEvent, setFileEvent] = useState('');
  const [fileEventBanner, setFileEventBanner] = useState('');

  const [error, setErrors] = useState({});

  const [s3Data, setS3Data] = useState({
    accessKey: "AKIA4GWLDQ2LFZGE32BJ",
    secretKey: "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",
    bucket: "familyguide",
    region: "ap-south-1",
  });

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: imageUploading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const handleChange = (selected) => {
    SetCities(selected);
  };

  const handleTagChange = (selected) => {
    setTags(selected);
  };


  //image Upload configs
  const config = {
    bucketName: s3Data?.bucketName,
    dirName: s3Data?.dirName,
    region: s3Data?.region,
    accessKeyId: s3Data?.accessKeyId,
    secretAccessKey: s3Data?.secretAccessKey,
    s3Url: s3Data?.s3Url,
  };

  const uploadImage = async (e, directory) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      try {
        // toast.success("Going to upload Image");
        const image = await ReactS3(file, s3Data, directory);

        let arr = [];

        switch (directory) {
          case "eventImages":
            arr = [...eventImages];
            arr.push(image);
            SetEventImages(arr);
            break;
          case "gallery":
            arr = [...gallary];
            arr.push(image);
            SetGallary(arr);
            break;
          case "sponsers":
            arr = [...sponsers];
            arr.push(image);
            SetSponsers(arr);
            break;
          case "Docs":
            arr = [...eventDocs];
            arr.push(image);
            SetEventDocs(arr);
            break;
          case "banner":
            arr = [...bannerImages];
            arr.push(image);
            SetBannerImages(arr);
            break;
        }
      } catch (error) {
        console.log("Err while Image Compression", error);
      }
    }
  };

  const remove = (index, directory) => {
    let arr = [];

    switch (directory) {
      case "eventImages":
        arr = [...eventImages];
        arr.splice(index, 1);
        SetEventImages(arr);
        break;
      case "gallery":
        arr = [...gallary];
        arr.splice(index, 1);
        SetGallary(arr);
        break;
      case "sponsers":
        arr = [...sponsers];
        arr.splice(index, 1);
        SetSponsers(arr);
        break;
      case "Docs":
        arr = [...eventDocs];
        arr.splice(index, 1);
        SetEventDocs(arr);
        break;
      case "banner":
        arr = [...bannerImages];
        arr.splice(index, 1);
        SetBannerImages(arr);
        break;
    }
  };

  useEffect(() => {
    getAllTags();
    dispatch(getAllCategories({ payload: { categoryType: "event" }, token: token }))
  }, []);

  const getAllTags = async () => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + "/cms/getAllTags", {
      method: "GET",
      headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          let arr = [];
          response.data.map((row) => {
            arr.push({
              ...row,
              label: row.tag,
              value: row.tag,
            });
          });

          setTagList(arr);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  // get category data
  const getEventCategory = Array.isArray(getData?.data) && getData?.data !== undefined && getData?.data !== null && getData?.data?.length > 0 ?
    getData?.data?.map((itm) => {
      return ({
        label: itm.categoryName, value: itm.categoryName
      }
      )
    }) : []

  const validate = () => {

    let newErrors = {};

    if (!title) {
      newErrors.title = "*Title is Required";
    }

    if (!titleMr) {
      newErrors.titleMr = "*Title in marathi is required";
    }

    // if (!pageTitle) {
    //   newErrors.pageTitle = "*Page Title is Required";
    // }

    if (!eventVenue) {
      newErrors.eventVenue = "*Address is required";
    }

    if (tags.length === 0) {
      newErrors.tags = "*Select at least one tag";
    }

    if (!category) {
      newErrors.category = "*Event category is required";
    }

    if (!eventvia) {
      newErrors.eventvia = "*Please select event via";
    }

    if (!eventViaLink && eventvia.label === "Online Event Registration") {
      newErrors.eventViaLink = "*Event registration link is required"
    }

    if (eventImages.length === 0) {
      newErrors.eventImages = "*Add Atleast one Event Images";
    }

    if (bannerImages.length === 0) {
      newErrors.bannerImages = "*Select Atleast one Banner Images";
    }

    if (!eventMedia) {
      newErrors.eventMedia = "*Please select event medium";
    }

    if (state.length === 0) {
      newErrors.state = "*Please Select State";
    }

    if (cities.length === 0) {
      newErrors.cities = "*Select at least one city";
    }


    if (!eventDate) {
      newErrors.eventDate = "*Please select event start date";
    }

    if (!eventEndDate) {
      newErrors.eventEndDate = "*Please select event end date";
    }

    if (!eventLastdateReg) {
      newErrors.eventLastdateReg = "*Please select event last date of registration";
    }

    if (!eventStartTime) {
      newErrors.eventStartTime = "*Please select event start time";
    }

    if (!eventEndTime) {
      newErrors.eventEndTime = "*Please select event end time";
    }

    if (!isRegistrationOver) {
      newErrors.isRegistrationOver = "*Please select event registration is over or not";
    }

    if (!isPostpone) {
      newErrors.isPostpone = "*Please select event is postpone or not";
    }

    if (!isPaid) {
      newErrors.isPaid = "*Please select event is paid or not";
    }

    if (isPaid?.label == "YES" && eventType?.label === 'INDIVIDUAL' && !eventAmount) {
      newErrors.eventAmount = "*Enter event amount";
    }

    if (isPaid?.label == "YES" && eventType?.label === 'INDIVIDUAL' && !eventAmountWithoutGST) {
      newErrors.eventAmountWithoutGST = "*Enter event amount without GST";
    }

    if (isPaid?.label == "YES" && eventType?.label === 'GROUP' && !eventGroupAmount) {
      newErrors.eventGroupAmount = "*Enter event group amount";
    }

    if (isPaid?.label == "YES" && eventType?.label === 'GROUP' && !eventGroupAmountWithoutGST) {
      newErrors.eventGroupAmountWithoutGST = "*Enter event group amount without GST";
    }

    if (isPaid?.label == "YES" && !eventDiscount) {
      newErrors.eventDiscount = "*Please enter event discount";
    }

    if (!minCapacity) {
      newErrors.minCapacity = "*Please enter event minimum capacity";
    }

    if (!maxCapacity) {
      newErrors.maxCapacity = "*Please enter event maximum capacity";
    }


    if (!isScheduledInfo) {
      newErrors.isScheduledInfo = "*Please select is scheduled info";
    }

    // if (isScheduledInfo?.label === "Yes" && !scheduledInfo) {
    //   newErrors.scheduledInfo = "*Please enter event scheduled info";
    // }

    if (!isSeatFillMessage) {
      newErrors.isSeatFillMessage = "*Please select is seat fill message";
    }

    if (isSeatFillMessage?.label === "Yes" && !seatFillMessage) {
      newErrors.seatFillMessage = "*Please enter event seat fill message";
    }

    if ((!descriptionMr || descriptionMr.trim() === '<p><br></p>') ||
      (!fullDescriptionMr || fullDescriptionMr.trim() === '<p><br></p>') ||
      (!description || description.trim() === '<p><br></p>') ||
      (!fullDescription || fullDescription.trim() === '<p><br></p>'))
    //  || (!descriptionHn || descriptionHn.trim() === '<p><br></p>') ||
    //    (!fullDescriptionHn || fullDescriptionHn.trim() === '<p><br></p>')) 
    {
      newErrors.description = "*Both short and full descriptions in English and Marathi are required";
    }
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const handleReactS3UltraRes = (url) => {
    if (url) {
      let arr = [];
      arr = [...eventImages];
      arr.push(url);
      SetEventImages(arr);
    }
  }
  const handleReactS3UltraResBanner = (url) => {
    if (url) {
      let arr = [];
      arr = [...bannerImages];
      arr.push(url);
      SetBannerImages(arr);
    }
  }

  const editEvent = async (status) => {
    const isValid = validate();
    if (isValid) {
      let payload = {
        event_id: event?.event_id,
        event_title: title,
        event_titleLang: { en: title, mr: titleMr, hin: titleHn },
        event_descriptioLang: { en: description, mr: descriptionMr, hin: descriptionHn },
        event_full_descriptionLang: { en: fullDescription, mr: fullDescriptionMr, hin: fullDescriptionHn },
        event_description: descriptionMr,
        categoryType: category,
        event_venue: eventVenue,
        event_full_description: fullDescriptionMr,
        event_images: eventImages,
        sponsors: sponsers,
        videos: videos,
        event_youtube_video: eventYoutubeVideo,
        gallery: gallary,
        event_documents: eventDocs,
        event_type: eventType.label,
        event_category: category.label,
        eventTypeDesc: eventTypeDesc,
        event_media: eventMedia.label,
        owner_entity_type: "EVENT",
        cities: cities.map((row) => row.label),
        state: "Maharashtra",
        stateCode: "MH",
        isActive: false,
        is_published: false,
        event_via: eventvia.label,
        eventRegistrationOutside: eventvia.label === "Online Event Registration" ? true : false,
        eventOutSideLink: eventViaLink,
        event_link: eventLink,
        event_date: eventDate,
        event_last_date_registration: eventLastdateReg,
        is_registration_over: isRegistrationOver.label === "Yes" ? true : false,
        event_end_date: eventEndDate,
        event_start_time: eventStartTime,
        event_end_time: eventEndTime,
        isDatePostpone: isPostpone.label === "Yes" ? true : false,
        tags: tags.map((item) => item.label),
        tagsId: tags.map((item) => item.tagId),
        is_paid: isPaid.label === "Yes" ? true : false,
        event_amount: eventAmount,
        eventGroupAmount: eventGroupAmount,
        eventAmountWithoutGst: eventAmountWithoutGST,
        eventGroupAmountWithoutGst: eventGroupAmountWithoutGST,
        event_discount: eventDiscount,
        event_min_capacity: minCapacity,
        event_max_capacity: maxCapacity,
        event_address: eventAddress1,
        event_address2: eventAddress2,
        event_pincode: eventPinCode,
        event_map_link: googleLink,
        is_navigation_added: false,
        is_promotion_banner_added: true,
        promotion_banner_images: bannerImages,
        isScheduledAdded: isScheduledInfo.label === "Yes" ? true : false,
        scheduledInfo: scheduledInfo,
        isSeatFillMessage: isSeatFillMessage.label === "Yes" ? true : false,
        seatFillMessage: seatFillMessage,
        isSeatDisplay: false,
        isActiveEvent: false,
        isHead: false,
        headId: "",
        event_updated_by: userState.userId,
        isPublish: status,
        pageId: pageTitle?.value ? pageTitle?.value : "",
        pageName: pageTitle?.label ? pageTitle?.label : "",
        pageNameLang: pageTitle?.Lang,
      };

      await fetch(
        process.env.REACT_APP_BASE_URL + "/eventSystem/updateEventSystem",
        {
          method: "POST",
          headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
          body: JSON.stringify(payload),
        }
      )
        .then((res) => res.json())
        .then((response) => {
          if (response.message === "Updated Successfully") {
            toast.success("Event updated successfully !")
            navigate("/dashboard/eventList");
          } else {
            toast.error("API Issue");
          }
        });
    }
  }

  return (
    <div className="outletPadding">
      <Container>

        <Row>
          <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
            <Icon.ArrowLeft className="me-1" />Back
          </Button>
        </Row>

        <h3 className='text-center d-flex justify-content-center align-items-center'>
          <Icon.Calendar2Week size={20} className='me-2' />Edit Event
        </h3>

        <Row>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Title in English<span>*</span></Form.Label>
              <Form.Control
                placeholder="Enter Title"
                value={title}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[\u0900-\u097F]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    setTitle(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setTitle(e.target.value);
                  }
                }}
              />
              {error.title && <p className="error">{error.title}</p>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Title in Marathi<span>*</span></Form.Label>
              <Form.Control
                placeholder="शीर्षक प्रविष्ट करा"
                value={titleMr}
                onChange={(e) => {
                  const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                  if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                    setTitleMr(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setTitleMr(e.target.value);
                  }
                }}
              />
              {error.titleMr && <p className="error">{error.titleMr}</p>}
            </Form.Group>
          </Col>

          <Col md={4} className='off'>
            <Form.Group className="mb-3">
              <Form.Label>Title in Hindi</Form.Label>
              <Form.Control
                placeholder="शीर्षक दर्ज करें"
                value={titleHn}
                onChange={(e) => {
                  const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                  if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                    setTitleHn(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setTitleHn(e.target.value);
                  }
                }}
              />
            </Form.Group>
          </Col>

          {/* Venue */}
          {/* <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event venue<span>*</span></Form.Label>
              <Form.Control
                placeholder="Enter Event venue"
                value={eventVenue}
                onChange={(e) => {
                  if (
                    // !e.target.value.match(
                    //   /[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/
                    // ) &&
                    e.target.value.trim()
                  ) {
                    setEventVenue(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setEventVenue(e.target.value);
                  }
                }}
              />
              {error.eventVenue && <p className="error">{error.eventVenue}</p>}
            </Form.Group>
          </Col> */}

          {/* Tags */}

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Event Category<span>*</span></Form.Label>
              <Select
                value={category}
                options={getEventCategory}
                onChange={(option) => setCategory(option)}
              />
              {error.category && <p className="error">{error.category}</p>}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Event Tags<span>*</span></Form.Label>
              <ReactSelect
                options={tagList}
                isMulti
                closeMenuOnSelect={false}
                // hideSelectedOptions={false}
                // components={{
                //   Option,
                // }}
                onChange={handleTagChange}
                allowSelectAll={true}
                value={tags}
              />
              {error.tags && <p className="error">{error.tags}</p>}
            </Form.Group>
          </Col>



          {/* 2st Row */}

          {/* for page */}
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Page</Form.Label>
              <Select
                isMulti={false}
                options={pageData}
                value={pageTitle}
                onChange={(option) => {
                  setpageTitle(option);
                }}
              />
              {/* {error.pageTitle && (<p className="error">{error.pageTitle}</p>)} */}
            </Form.Group>
          </Col>

          {/* Youtube video */}
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Youtube Video Link</Form.Label>
              <Form.Control
                placeholder="Enter Title"
                value={eventYoutubeVideo}
                onChange={(e) => {
                  if (
                    e.target.value.trim()
                  ) {
                    setEventYoutubeVideo(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setEventYoutubeVideo(e.target.value);
                  }
                  // e.target.value.length > 0
                  //   ? setNameErr(false)
                  //   : setNameErr(true);
                }}
              />
              {/* {error.eventYoutubeVideo && <p className="error">{error.eventYoutubeVideo}</p>} */}
            </Form.Group>
          </Col>


          {/* via */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event Registration Via<span>*</span></Form.Label>
              <Select
                isMulti={false}
                options={eventViaOption}
                value={eventvia}
                onChange={(option) => {
                  setEventVia(option);
                  // setRoleErr(false);
                }}
              />
              {error.eventvia && <p className="error">{error.eventvia}</p>}
            </Form.Group>
          </Col>

          {
            eventvia.label === "Online Event Registration" ?
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Event Registration Link<span>*</span></Form.Label>
                  <Form.Control
                    placeholder="Enter Event Registration Link"
                    value={eventViaLink}
                    onChange={(e) => {
                      if (
                        e.target.value.trim()
                      ) {
                        setEventViaLink(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setEventViaLink(e.target.value);
                      }
                    }}
                  />
                  {error.eventViaLink && <p className="error">{error.eventViaLink}</p>}
                </Form.Group>
              </Col> : null
          }

          {/* 3st Row */}
          {/* Images */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Images<span>*</span></Form.Label> <br />
              <>
                {
                  uploading === "eventImages" ? <Lottie options={defaultOptions} className='mx-auto' width={120} /> :
                    <label htmlFor="fileInput" id="uploadBtn">
                      <Icon.CloudArrowUp size={20} className="me-2" />
                      Upload Images
                    </label>
                }
                <input
                  type="file"
                  accept="image/jpeg, image/png, image/gif"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={(event) => setFileEvent(event)}
                />
                <p id='imgNote'>Recommanded image resolution - 1:1</p>
                {error.eventImages && <p className="error">{error.eventImages}</p>}
                <br />
                {eventImages.length > 0 ? (
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    {eventImages.map((row, index) => {
                      return (
                        <Card className="uploadedFile my-1 me-3">
                          <img src={row} style={{ width: 60, height: 60 }} className='mx-auto' />
                          <Icon.XCircleFill className='removeIcon' size={18}
                            onClick={() => remove(index, "eventImages")} />
                        </Card>
                      );
                    })}
                  </div>
                ) : null}
              </>
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Banner Images <span>*</span></Form.Label> <br />
              <>
                {
                  uploading === "bannerImages" ? <Lottie options={defaultOptions} className='mx-auto' width={120} /> :
                    <label htmlFor="bannerInput" id="uploadBtn">
                      <Icon.CloudArrowUp size={20} className="me-2" />
                      Add Banner Images
                    </label>
                }
                <input
                  accept="image/jpeg, image/png, image/gif"
                  type="file"
                  id="bannerInput"
                  style={{ display: "none" }}
                  onChange={(event) => setFileEventBanner(event)}
                />
                <p id='imgNote'>Recommanded image resolution - 4/5 (1080*1350 px)</p>
                {error.bannerImages && <p className="error">{error.bannerImages}</p>}
                <br />
                {bannerImages.length > 0 ? (
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    {bannerImages.map((row, index) => {
                      return (
                        <Card className="uploadedFile my-1 me-3">
                          <img src={row} style={{ width: 60, height: 60 }} className='mx-auto' />
                          <Icon.XCircleFill className='removeIcon' size={18}
                            onClick={() => remove(index, "banner")} />
                        </Card>
                      );
                    })}
                  </div>
                ) : null}
              </>
            </Form.Group>
          </Col>


          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event Docs</Form.Label> <br />
              <>
                {
                  uploading === "eventDocs" ? <Lottie options={defaultOptions} className='mx-auto' width={120} /> :
                    <label htmlFor="docInput" id="uploadBtn">
                      <Icon.CloudArrowUp size={20} className="me-2" />
                      Add Event Docs
                    </label>
                }
                <input
                  type="file"
                  accept=".pdf,.doc,.docx"
                  id="docInput"
                  style={{ display: "none" }}
                  onChange={(event) => uploadImage(event, "Docs")}
                />
                {/* {error.eventDocs && <p className="error">{error.eventDocs}</p>} */}
                <br />
                {eventDocs.length > 0 ? (
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    {eventDocs.map((row, index) => {
                      return (
                        <Card className="uploadedFile my-1 me-3">
                          <img src={row} style={{ width: 60, height: 60 }} className='mx-auto' />
                          <Icon.XCircleFill className='removeIcon' size={18}
                            onClick={() => remove(index, "Docs")} />
                        </Card>
                      );
                    })}
                  </div>
                ) : null}
              </>
            </Form.Group>
          </Col>

          {/* Event Lat */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Google map link</Form.Label>
              <Form.Control
                placeholder="Enter Google Map Link"
                value={googleLink}
                onChange={(e) => { setGoogleLink(e.target.value) }}
              />
              {/* {error.googleLink && <p className="error">{error.googleLink}</p>} */}
            </Form.Group>
          </Col>

          {/* Medium */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event Medium<span>*</span></Form.Label>
              <Select
                isMulti={false}
                options={onOffOption}
                value={eventMedia}
                onChange={(option) => {
                  setEventMedia(option);
                  // setRoleErr(false);
                }}
              />
              {error.eventMedia && <p className="error">{error.eventMedia}</p>}
            </Form.Group>
          </Col>

          {/* Event link */}
          {eventMedia.label === "ONLINE" ?
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Event Link<span>*</span></Form.Label>
                <Form.Control
                  placeholder="Enter Event Link"
                  value={eventLink}
                  onChange={(e) => {
                    if (
                      e.target.value.trim()
                    ) {
                      setEventLink(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setEventLink(e.target.value);
                    }
                    // e.target.value.length > 0
                    //   ? setNameErr(false)
                    //   : setNameErr(true);
                  }}
                />
                {error.eventLink && <p className="error">{error.eventLink}</p>}
              </Form.Group>
            </Col>
            : null}

          {/* 5th Row */}
          {/* STate */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>State<span>*</span></Form.Label>
              <Select
                isMulti={false}
                options={[
                  {
                    label: "MAHARASHTRA",
                    value: "MAHARASHTRA",
                    stateCode: "MH",
                  },
                ]}
                value={state}
                onChange={(option) => {
                  setState(option);
                  // setRoleErr(false);
                }}
              />
              {error.state && <p className="errMsg">{error.state}</p>}

            </Form.Group>
          </Col>

          {/* City */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>District<span>*</span></Form.Label>
              <ReactSelect
                isDisabled={state === ""}
                options={cityInfo}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(option) => handleChange(option)}
                allowSelectAll={true}
                value={cities}
              />
              {error.cities && <p className="error">{error.cities}</p>}
            </Form.Group>
          </Col>

          {/* PinCode */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event Pincode</Form.Label>
              <Form.Control
                placeholder="Enter Pincode"
                value={eventPinCode}
                maxLength={6}
                onChange={(e) => {
                  if (e.target.value.match(/^\d*$/) && e.target.value.trim()) {
                    setEventPinCode(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setEventPinCode(e.target.value);
                  }
                  // e.target.value.length > 0
                  //   ? setNameErr(false)
                  //   : setNameErr(true);
                }}
              />
              {/* {error.eventPinCode && <p className="error">{error.eventPinCode}</p>} */}
            </Form.Group>
          </Col>

          {/* 6th Row */}
          {/* Event Date */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event Start Date<span>*</span></Form.Label>
              <Form.Control
                className=""
                type="date"
                name="Event Date"
                value={eventDate}
                // min={new Date().toISOString().split("T")[0]}
                onKeyDown={(event) => event.preventDefault()}
                onChange={(e) => {
                  setEventDate(e.target.value);
                  // e.target.value.length < 0
                  //   ? setSessionStartTimeError(true)
                  //   : setSessionStartTimeError(false);
                }}
              />
              {error.eventDate && <p className="error">{error.eventDate}</p>}
            </Form.Group>
          </Col>

          {/* End Date */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event End Date<span>*</span></Form.Label>
              <Form.Control
                className=""
                type="date"
                name="Event End Date"
                value={eventEndDate}
                min={eventDate}
                // min={new Date().toISOString().split("T")[0]}
                onKeyDown={(event) => event.preventDefault()}
                onChange={(e) => {
                  setEventEndDate(e.target.value);
                  // e.target.value.length < 0
                  //   ? setSessionStartTimeError(true)
                  //   : setSessionStartTimeError(false);
                }}
              />
              {error.eventEndDate && <p className="error">{error.eventEndDate}</p>}
            </Form.Group>
          </Col>

          {/* Last Date of Register */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event Last Date of Register<span>*</span></Form.Label>
              <Form.Control
                className=""
                type="date"
                name="Event Last Date of Register"
                value={eventLastdateReg}
                min={eventDate}
                onKeyDown={(event) => event.preventDefault()}
                onChange={(e) => {
                  setEventLastdateReg(e.target.value);
                  // e.target.value.length < 0
                  //   ? setSessionStartTimeError(true)
                  //   : setSessionStartTimeError(false);
                }}
              />
              {error.eventLastdateReg && <p className="error">{error.eventLastdateReg}</p>}
            </Form.Group>
          </Col>

          {/* 7th Row */}
          {/* Event Start Time */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event Start Time<span>*</span></Form.Label>
              <Form.Control
                className=""
                type="time"
                name="Event Start Time"
                value={eventStartTime}
                // min={new Date().toISOString().split("T")[0]}
                onKeyDown={(event) => event.preventDefault()}
                onChange={(e) => {
                  setEventStartTime(e.target.value);
                  // e.target.value.length < 0
                  //   ? setSessionStartTimeError(true)
                  //   : setSessionStartTimeError(false);
                }}
              />
              {error.eventStartTime && <p className="error">{error.eventStartTime}</p>}
            </Form.Group>
          </Col>

          {/* End Time */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event End Time<span>*</span></Form.Label>
              <Form.Control
                className=""
                type="time"
                name="Event End Time"
                value={eventEndTime}
                // min={new Date().toISOString().split("T")[0]}
                onKeyDown={(event) => event.preventDefault()}
                onChange={(e) => {
                  setEventEndTime(e.target.value);
                  // e.target.value.length < 0
                  //   ? setSessionStartTimeError(true)
                  //   : setSessionStartTimeError(false);
                }}
              />
              {error.eventEndTime && <p className="error">{error.eventEndTime}</p>}
            </Form.Group>
          </Col>

          {/* Registration Over */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Is Registration Over<span>*</span></Form.Label>
              <Select
                isMulti={false}
                options={YesNoOption}
                value={isRegistrationOver}
                onChange={(option) => {
                  setIsRegistrationOver(option);
                  // setRoleErr(false);
                }}
              />
              {error.isRegistrationOver && <p className="error">{error.isRegistrationOver}</p>}
            </Form.Group>
          </Col>

          {/* Yes No Row */}
          {/* postPone */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Is Postponed<span>*</span></Form.Label>
              <Select
                isMulti={false}
                options={YesNoOption}
                value={isPostpone}
                onChange={(option) => {
                  setIsPostpone(option);
                  // setRoleErr(false);
                }}
              />
              {error.isPostpone && <p className="error">{error.isPostpone}</p>}
            </Form.Group>
          </Col>

          {/* Type */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event Type<span>*</span></Form.Label>
              <Select
                isMulti={false}
                options={typeOption}
                value={eventType}
                onChange={(option) => {
                  setEventType(option);
                  // setRoleErr(false);
                }}
              />
              {error.eventType && <p className="error">{error.eventType}</p>}
            </Form.Group>
          </Col>

          {/* Paid or not */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Is Paid<span>*</span></Form.Label>
              <Select
                isMulti={false}
                options={YesNoOption}
                value={isPaid}
                onChange={(option) => {
                  setIsPaid(option);
                  // setRoleErr(false);
                }}
              />
              {error.isPaid && <p className="error">{error.isPaid}</p>}
            </Form.Group>
          </Col>

          {isPaid?.label === "Yes" ? (
            <Row>
              {/* Event AMount */}
              {eventType.label === "INDIVIDUAL" ? (
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Event AMount</Form.Label>
                    <Form.Control
                      placeholder="Enter Event AMount"
                      value={eventAmount}
                      onChange={(e) => {
                        if (
                          e.target.value.match(/^\d*\.?\d{0,2}$/) &&
                          e.target.value.trim()
                        ) {
                          setEventAmount(e.target.value);
                        } else if (e.target.value.length === 0) {
                          setEventAmount(e.target.value);
                        }
                        // e.target.value.length > 0
                        //   ? setNameErr(false)
                        //   : setNameErr(true);
                      }}
                    />
                    {error.eventAmount && <p className="error">{error.eventAmount}</p>}
                  </Form.Group>
                </Col>
              ) : null}

              {/* Event Group Amount */}
              {eventType.label === "GROUP" ? (
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Event Group Amount</Form.Label>
                    <Form.Control
                      placeholder="Enter Group Amount"
                      value={eventGroupAmount}
                      onChange={(e) => {
                        if (
                          e.target.value.match(/^\d*\.?\d{0,2}$/) &&
                          e.target.value.trim()
                        ) {
                          setEventGroupAmount(e.target.value);
                        } else if (e.target.value.length === 0) {
                          setEventGroupAmount(e.target.value);
                        }
                        // e.target.value.length > 0
                        //   ? setNameErr(false)
                        //   : setNameErr(true);
                      }}
                    />
                    {error.eventGroupAmount && <p className="error">{error.eventGroupAmount}</p>}
                  </Form.Group>
                </Col>
              ) : null}

              {/* Event AMount */}
              {eventType.label === "INDIVIDUAL" ? (
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Event AMount without GST</Form.Label>
                    <Form.Control
                      placeholder="Enter Event AMount without GST"
                      value={eventAmountWithoutGST}
                      onChange={(e) => {
                        if (
                          e.target.value.match(/^\d*\.?\d{0,2}$/) &&
                          e.target.value.trim()
                        ) {
                          setEventAmountWithoutGST(e.target.value);
                        } else if (e.target.value.length === 0) {
                          setEventAmountWithoutGST(e.target.value);
                        }
                        // e.target.value.length > 0
                        //   ? setNameErr(false)
                        //   : setNameErr(true);
                      }}
                    />
                    {error.eventAmountWithoutGST && <p className="error">{error.eventAmountWithoutGST}</p>}
                  </Form.Group>
                </Col>
              ) : null}

              {/* Event Group Amount */}
              {eventType.label === "GROUP" ? (
                <Col md={4}>
                  <Form.Group className="mb-3">
                    <Form.Label>Event Group Amount without GST</Form.Label>
                    <Form.Control
                      placeholder="Enter Group Amount without GST"
                      value={eventGroupAmountWithoutGST}
                      onChange={(e) => {
                        if (
                          e.target.value.match(/^\d*\.?\d{0,2}$/) &&
                          e.target.value.trim()
                        ) {
                          setEventGroupAmountWithoutGST(e.target.value);
                        } else if (e.target.value.length === 0) {
                          setEventGroupAmountWithoutGST(e.target.value);
                        }
                        // e.target.value.length > 0
                        //   ? setNameErr(false)
                        //   : setNameErr(true);
                      }}
                    />
                    {error.eventGroupAmountWithoutGST && <p className="error">{error.eventGroupAmountWithoutGST}</p>}
                  </Form.Group>
                </Col>
              ) : null}

              {/* Discount */}
              <Col md={4}>
                <Form.Group className="mb-3">
                  <Form.Label>Event Discount<span>*</span></Form.Label>
                  <Form.Control
                    placeholder="Enter Discount"
                    value={eventDiscount}
                    onChange={(e) => {
                      if (
                        e.target.value.match(/^\d*\.?\d{0,2}$/) &&
                        e.target.value.trim()
                      ) {
                        setEventDiscount(e.target.value);
                      } else if (e.target.value.length === 0) {
                        setEventDiscount(e.target.value);
                      }
                      // e.target.value.length > 0
                      //   ? setNameErr(false)
                      //   : setNameErr(true);
                    }}
                  />
                  {error.eventDiscount && <p className="error">{error.eventDiscount}</p>}
                </Form.Group>
              </Col>
            </Row>
          ) : null}
        </Row>

        {/* 9th Row */}
        <Row>
          {/* MinCapacity */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event Minimum Capacity<span>*</span></Form.Label>
              <Form.Control
                placeholder="Enter Minimum Capacity"
                value={minCapacity}
                onChange={(e) => {
                  if (
                    e.target.value.match(/^\d*\.?\d{0,2}$/) &&
                    e.target.value.trim()
                  ) {
                    setMinCapacity(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setMinCapacity(e.target.value);
                  }
                  // e.target.value.length > 0
                  //   ? setNameErr(false)
                  //   : setNameErr(true);
                }}
              />
              {error.minCapacity && <p className="error">{error.minCapacity}</p>}
            </Form.Group>
          </Col>

          {/* MaxCapacity */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event Maximum Capacity<span>*</span></Form.Label>
              <Form.Control
                placeholder="Enter Maximum Capacity"
                value={maxCapacity}
                onChange={(e) => {
                  if (
                    e.target.value.match(/^\d*\.?\d{0,2}$/) &&
                    e.target.value.trim()
                  ) {
                    setMaxCapacity(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setMaxCapacity(e.target.value);
                  }
                  // e.target.value.length > 0
                  //   ? setNameErr(false)
                  //   : setNameErr(true);
                }}
              />
              {error.maxCapacity && <p className="error">{error.maxCapacity}</p>}
            </Form.Group>
          </Col>

          {/* Event Address 1 */}

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event Address Line 1<span>*</span></Form.Label>
              <Form.Control
                placeholder="Enter Event Address Line 1"
                value={eventVenue}
                onChange={(e) => {
                  if (
                    // !e.target.value.match(
                    //   /[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/
                    // ) &&
                    e.target.value.trim()
                  ) {
                    setEventVenue(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setEventVenue(e.target.value);
                  }
                }}
              />
              {error.eventVenue && <p className="error">{error.eventVenue}</p>}
            </Form.Group>
          </Col>

          {/* <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event Address Line 1</Form.Label>
              <Form.Control
                placeholder="Event Address Line 1"
                value={eventAddress1}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    setEventAddress1(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setEventAddress1(e.target.value);
                  }
                  // e.target.value.length > 0
                  //   ? setNameErr(false)
                  //   : setNameErr(true);
                }}
              />
              {error.eventAddress1 && <p className="error">{error.eventAddress1}</p>}
            </Form.Group>
          </Col> */}

        </Row>

        {/* 10th Row */}
        <Row>
          {/* Event Address 2 */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Event Address Line 2</Form.Label>
              <Form.Control
                placeholder="Event Address Line 2"
                value={eventAddress2}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    setEventAddress2(e.target.value);
                  } else if (e.target.value.length === 0) {
                    setEventAddress2(e.target.value);
                  }
                  // e.target.value.length > 0
                  //   ? setNameErr(false)
                  //   : setNameErr(true);
                }}
              />
              {/* {error.eventAddress2 && <p className="error">{error.eventAddress2}</p>} */}
            </Form.Group>
          </Col>

          {/* Event isScheduledInfo */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Is Scheduled Added<span>*</span></Form.Label>
              <Select
                isMulti={false}
                options={YesNoOption}
                value={isScheduledInfo}
                onChange={(option) => {
                  setIsScheduledInfo(option);
                  // setRoleErr(false);
                }}
              />
              {error.isScheduledInfo && <p className="error">{error.isScheduledInfo}</p>}
            </Form.Group>

            {isScheduledInfo?.label === "Yes" ? (
              <Form.Group className="mb-3">
                <Form.Label>Scheduled Info<span>*</span></Form.Label>
                <Form.Control
                  placeholder="Scheduled In"
                  value={scheduledInfo}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/
                      ) &&
                      e.target.value.trim()
                    ) {
                      setScheduledInfo(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setScheduledInfo(e.target.value);
                    }
                    // e.target.value.length > 0
                    //   ? setNameErr(false)
                    //   : setNameErr(true);
                  }}
                />
                {error.scheduledInfo && <p className="error">{error.scheduledInfo}</p>}
              </Form.Group>
            ) : null}
          </Col>

          {/* Event Seat Fill */}
          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Seat Fill Message Added<span>*</span></Form.Label>
              <Select
                isMulti={false}
                options={YesNoOption}
                value={isSeatFillMessage}
                onChange={(option) => {
                  SetIsSeatFillMessage(option);
                  // setRoleErr(false);
                }}
              />
              {error.isSeatFillMessage && <p className="error">{error.isSeatFillMessage}</p>}
            </Form.Group>

            {isSeatFillMessage?.label === "Yes" ? (
              <Form.Group className="mb-3">
                <Form.Label>Seat Fill Message<span>*</span></Form.Label>
                <Form.Control
                  placeholder="Seat Fill Message"
                  value={seatFillMessage}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/
                      ) &&
                      e.target.value.trim()
                    ) {
                      setSeatFillMessage(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setSeatFillMessage(e.target.value);
                    }
                    // e.target.value.length > 0
                    //   ? setNameErr(false)
                    //   : setNameErr(true);
                  }}
                />
                {error.seatFillMessage && <p className="error">{error.seatFillMessage}</p>}
              </Form.Group>
            ) : null}
          </Col>
        </Row>



        {/* Description Editor Row */}
        <Row className="mb-5 mt-3">
          {/* for description  */}
          <ChromeTab
            shortdescriptionMr={descriptionMr}
            setShortDescriptionMr={setDescriptionMr}
            descriptionMr={fullDescriptionMr}
            setDescriptionMr={setFullDescriptionMr}
            shortdescription={description}
            setShortDescription={setDescription}
            descriptionEn={fullDescription}
            setDescriptionEn={setFullDescription}
            shortdescriptionHn={descriptionHn}
            setShortDescriptionHn={setDescriptionHn}
            descriptionHn={fullDescriptionHn}
            setDescriptionHn={setFullDescriptionHn}
          />
          {error.description && (
            <p className="errMsg mt-1">{error.description}</p>
          )}
        </Row>

        {loader ? (
          <Spinner variant="primary" />
        ) : (
          <>
            <ButtonComponent
              onSave={editEvent}
              onSaveAndPublish={editEvent}
              saveLabel="Update"
              savePublish="Update & Publish"
            />
          </>
        )}
      </Container>

      {
        fileEvent &&
        <ReactS3Ultra fileEvent={fileEvent} fileType={process.env.REACT_APP_IMAGE}
          directory="eventImages" onFileUpload={handleReactS3UltraRes} />
      }
      {
        fileEventBanner &&
        <ReactS3Ultra fileEvent={fileEventBanner} fileType={process.env.REACT_APP_IMAGE}
          directory="eventBanners" onFileUpload={handleReactS3UltraResBanner} type={4 / 5} />
      }
    </div>
  )
}

export default EditEvent
