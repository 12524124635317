import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from '../../Common/Skeleton';
import Pagination from '../../Common/Pagination';
import * as Icon from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../../Common/ActiveKeySlice';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import moment from 'moment';

const Transactions = () => {

  const userState = useSelector((state) => state?.cmsLogin?.userData);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(20);
  const [noOfPages, setNoOfPages] = useState();
  const [searchField, setSearchField] = useState("");
  const [StartDate, setStartDate] = useState("")
  const [EndDate, setEndDate] = useState("")


  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }
  useEffect(() => {
    getAllTransaction()
  }, [docPerPage, pageNo])

  const getAllTransaction = async (StartDate, EndDate) => {
    try {
      setLoader(true);
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/transaction/getAllTransaction', {
        method: 'POST',
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
        body: JSON.stringify({
          searchText: searchField ? searchField : "",
          fromDate: StartDate,
          toDate: EndDate,
          documentsPerPage: docPerPage,
          page: pageNo,
        }),

      })
      const responseData = await response.json();
      if (responseData.message === "Authorization failed / Forbidden") {
        setLoader(true);
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));

        localStorage.removeItem('persist:root');
        navigate('/');
      } else {
        setNoOfPages(responseData?.data?.noOfPages);
        setData(responseData?.data);
        setLoader(false);
      }

    } catch (Err) {
      console.log("Err while getting activites", Err);
      setLoader(false);

    }
  }

  // for custome hook call
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getAllTransaction, dependencies, setLoader);

  return (
    <div className='Transactions outletPadding'>
      <Container>

        <Row className='d-flex justify-content-between'>
          <Col md={4} className='mb-3 mb-md-0'>
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }
              }}
            />
          </Col>

          <Col md={8} className='d-flex justify-content-end align-items-center'>
            <div className='d-flex flex-row'>
              <input
                type="date"
                className="form-control me-2"
                placeholder="Search here"
                name="fromDate"
                value={StartDate ? new Date(StartDate).toISOString().split('T')[0] : ''}
                onChange={(e) => {
                  setStartDate(e.target.value);
                }}
                max={EndDate}
              />
              <input
                type="date"
                className="form-control me-2"
                placeholder="Search here"
                name="toDate"
                value={EndDate ? new Date(EndDate).toISOString().split('T')[0] : ''}
                min={StartDate}
                disabled={!StartDate}
                onChange={(e) => {
                  setEndDate(e.target.value)
                }}
              />
              <Button className='primaryBtn' onClick={() => getAllTransaction(StartDate, EndDate)}>
                <Icon.Search size={20} />
              </Button>
              <Button className='primaryBtn ms-2' onClick={() => getAllTransaction(setStartDate(""), setEndDate(""))}>
                <Icon.ArrowClockwise size={20} />
              </Button>

            </div>
          </Col>
        </Row>

        <Row className='mt-3'>
          <Col md={12}>
            <h6 className='text-start mb-0'>
              Total Count- <strong>{data?.count}</strong>
            </h6>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ height: '80vh' }}>
            <table>
              <thead>
                <th>Sr.</th>
                <th>Username</th>
                <th>Email</th>
                <th>Mobile</th>
                <th>Entity Type</th>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Membership Plan</th>
                <th>Transaction Date</th>

              </thead>
              {
                loader ? <Skeleton rows={10} cols={9} /> :
                  data?.data !== null && data?.data !== undefined && data?.data.length > 0 ? data?.data?.map((itm, index) => {
                    const { userName, email, mobile, entityType, transactionId, amount, membershipPlan, createdAt } = itm
                    return (

                      <tr key={Math.random() * 999999999}>
                        <td>{pageNo !== 1 ? (
                          <>
                            {' '}
                            {index + 1 + docPerPage * (pageNo - 1)}
                          </>
                        ) : (
                          <>{index + 1}</>
                        )}</td>
                        <td style={{ width: '55px' }}>{userName}</td>
                        <td>{email}</td>
                        <td>{mobile}</td>
                        <td>{entityType}</td>
                        <td style={{ width: '100px' }}>{transactionId}</td>
                        <td style={{ width: '80px' }}>{amount}</td>
                        <td >{membershipPlan}</td>
                        <td style={{ width: '100px' }}>{moment(createdAt).format("DD-MM-YYYY")}</td>
                      </tr>
                    )
                  })
                    : <p className='noDataFound'>No Data Found</p>
              }
            </table>
          </div>
        </div>

        <Row>
          <Col md={12} className='d-flex justify-content-end'>
            <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
              onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
          </Col>
        </Row>

      </Container>
    </div >
  )
}

export default Transactions; 