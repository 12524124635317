import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import TextToSpeech from "../../Common/Reuse/TextToSpeech/TextToSpeech";

const ViewCourseTopic = () => {
    const location = useLocation();
    const courseTopicData = location.state.rowData;
    const navigate = useNavigate();

    return (
        <div className="outletPadding">
            <Container>
                <Button className="backBtn" onClick={() => navigate(-1)}>
                    <Icon.ArrowLeft className="me-2" />
                    Back
                </Button>
                <Row className="mb-2 mt-4">
                    <h4 className="mb-0 text-center">
                        <Icon.Person className="me-1" /> Course Topic Details
                    </h4>
                </Row>
                <Row className="outer-wrapper mx-auto mt-4">
                    <div className="table-wrapper">
                        <table data-aos="fade-up" data-aos-delay="200">
                            <tbody>
                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Course Name
                                    </th>
                                    <td>{courseTopicData?.courseTitle ? courseTopicData?.courseTitle : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Course Lesson Name
                                    </th>
                                    <td>{courseTopicData?.courseLessonTitle ? courseTopicData?.courseLessonTitle : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Topic Name in English
                                    </th>
                                    <td>{courseTopicData?.titleLang?.en ? courseTopicData?.titleLang?.en : "-"}</td>
                                </tr>

                                <tr className="off">
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Topic Name in Hindi
                                    </th>
                                    <td>{courseTopicData?.titleLang?.hin ? courseTopicData?.titleLang?.hin : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Topic Name in Marathi
                                    </th>
                                    <td>{courseTopicData?.titleLang?.mr ? courseTopicData?.titleLang?.mr : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Topic Header in English
                                    </th>
                                    <td>{courseTopicData?.titleHeaderLang?.en ? courseTopicData?.titleHeaderLang?.en : "-"}</td>
                                </tr>
                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Topic Header in Marathi
                                    </th>
                                    <td>{courseTopicData?.titleHeaderLang?.mr ? courseTopicData?.titleHeaderLang?.mr : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Topic Video Link
                                    </th>
                                    <td>{courseTopicData?.videoURL ? courseTopicData?.videoURL : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Topic Image Link
                                    </th>
                                    <td>{courseTopicData?.imageURL ? courseTopicData?.imageURL : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Content Type
                                    </th>
                                    <td>{courseTopicData?.content[0].type ? courseTopicData?.content[0].type : "-"}</td>

                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Content File
                                    </th>
                                    <td>{courseTopicData?.content[0].url ? courseTopicData?.content[0].url : "-"}</td>

                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Duration
                                    </th>
                                    <td>{courseTopicData?.duration ? courseTopicData?.duration : "-"}</td>

                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Short Description in English
                                    </th>
                                    <td>{courseTopicData?.shortDescriptionLang ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: courseTopicData?.shortDescriptionLang?.en,
                                            }}
                                        ></div>
                                    ) : (
                                        "-"
                                    )}</td>
                                </tr>

                                <tr >
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Short Description in Marathi
                                    </th>
                                    <td>{courseTopicData?.shortDescriptionLang ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: courseTopicData?.shortDescriptionLang?.mr,
                                            }}
                                        ></div>
                                    ) : (
                                        "-"
                                    )}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Full Description in English
                                    </th>
                                    <td>{courseTopicData?.descriptionLang.en ? (
                                        <>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: courseTopicData?.descriptionLang.en,
                                                }}
                                            ></div>
                                            <TextToSpeech htmlContent={courseTopicData?.descriptionLang.en} language="mr" />
                                        </>
                                    ) : (
                                        "-"
                                    )}</td>
                                </tr>


                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Full Description in Marathi
                                    </th>
                                    <td>{courseTopicData?.descriptionLang.mr ? (
                                        <>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: courseTopicData?.descriptionLang.mr,
                                                }}
                                            >
                                            </div>
                                            <TextToSpeech htmlContent={courseTopicData?.descriptionLang.mr} language="mr" />
                                        </>
                                    ) : (
                                        "-"
                                    )}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        CreatedBy
                                    </th>
                                    <td>{courseTopicData?.updatedBy ? courseTopicData?.updatedBy : "-"}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default ViewCourseTopic;
