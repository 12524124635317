import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

const ViewCategory = () => {
  const location = useLocation();
  const navigate = useNavigate()

  const category = location.state.category;

  return (
    <div className="outletPadding">
      <Container>
        <Button className="backBtn" onClick={() => navigate(-1)}>
          <Icon.ArrowLeft className="me-2" />Back
        </Button>
        <Row className="mb-2 mt-4">
          <h4 className="mb-0 text-center">
            <Icon.Grid className="me-1" /> Category Details
          </h4>
        </Row>
        <Row className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper">
            <table data-aos="fade-up" data-aos-delay="200">
              <tbody>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Category Icon
                  </th>
                  <td><img src={category.iconURL} alt="" width={40} /></td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Category Image
                  </th>
                  <td><img src={category.imgURL ? category.imgURL : '-'} alt="" width={40} /></td>
                </tr>
                <tr>
                  <th >
                    <Icon.Code className="me-2" />
                    Category Name English
                  </th>
                  <td>{category?.categoryNameLang.en ? category?.categoryNameLang.en : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Category Name Marathi
                  </th>
                  <td>{category?.categoryNameLang.mr ? category?.categoryNameLang.mr : "-"}</td>
                </tr>
                <tr className="off">
                  <th >
                    <Icon.Code className="me-2" />
                    Category Name Hindi
                  </th>
                  <td>{category?.categoryNameLang.hin ? category?.categoryNameLang.hin : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Category Type
                  </th>
                  <td>{category?.categoryType ? category?.categoryType : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Priority
                  </th>
                  <td>{category?.priortyNo ? category?.priortyNo : "-"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ViewCategory;
