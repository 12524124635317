import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../ActiveKeySlice';
import { useNavigate } from 'react-router-dom';

const useEventList = (token, setLoader) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [eventList, setEventList] = useState([]);

    const getEventList = async () => {
        setLoader(true);
        await fetch(
            process.env.REACT_APP_BASE_URL + "/priority/titleTypeFilter",
            {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
                body: JSON.stringify({
                    type: "Event",

                })
            }
        )
            .then((res) => res.json())
            .then((response) => {
                if (response.message === "Authorization failed / Forbidden") {
                    setLoader(true);
                    dispatch(setLogout(null));
                    dispatch(setActiveKey(null));

                    localStorage.removeItem('persist:root');
                    navigate('/');
                } else if (response.status === 200) {
                    setLoader(false);
                    setEventList(response?.data);
                } else {
                    setLoader(false)
                    setEventList([])
                }
            })
            .catch((error) => {
                setLoader(false);
            });
    };

    return { eventList, getEventList }
}

export default useEventList