import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { Container, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from '../../Common/Skeleton';
import { useSelector } from 'react-redux';
import Pagination from '../../Common/Pagination';
import { useLocation, useNavigate } from 'react-router-dom';
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import PublishModel from '../../Common/Model/PublishModel';
import UnPublishModel from '../../Common/Model/UnPublishModel';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import DeleteModel from '../../Common/Model/DeleteModel';
import PaginationSequence from '../../Common/Pagination/PaginationSequence';
import moment from 'moment';


const CourseTopic = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const navigate = useNavigate()
    const location = useLocation()
    const { courseId, courseLessonId, courseTitleLang, lessonTitleLang } = location?.state

    const [searchField, setSearchField] = useState("");
    const [loader, setLoader] = useState(false);
    const [topicData, setTopicData] = useState([]);
    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [publishCourseTopicId, setPublishCourseTopicId] = useState(null);

    // Unpublish video variables
    const [isUnpublish, setIsUnpublish] = useState(false);
    const [unpublishId, setUnpublishId] = useState("");


    const [showAdd, setShowAdd] = useState(false);
    const [delId, setDelId] = useState('');
    const [showDel, setShowDel] = useState(false);


    // States for Pagination ----------
    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(25);
    const [noOfPages, setNoOfPages] = useState();

    // Function to handle opening the publish modal
    const openPublishModal = (courseTopicId) => {
        setPublishCourseTopicId(courseTopicId);
        setIsPublishModalOpen(true);
    };

    // Function to handle closing the publish modal
    const closePublishModal = () => {
        setIsPublishModalOpen(false);
    };

    const handlePublishModalCancel = () => {
        setIsPublishModalOpen(false);
    };

    // Function to handle opening the unpublish modal
    const openUnpublishModal = (courseTopicId) => {
        setUnpublishId(courseTopicId);
        setIsUnpublish(true);
    };

    // Function to handle cancelling the unpublish modal
    const closeUnpublishModal = () => {
        setIsUnpublish(false);
    };


    useEffect(() => {
        getAllCourseTopic()
    }, [docPerPage, pageNo])

    // for get all course lesson data
    const getAllCourseTopic = async () => {
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + '/cms/getTopicByCourseIdAndLessonIdD', {
            method: 'POST',
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
            body: JSON.stringify({
                courseLessonId: courseLessonId,
                documentsPerPage: docPerPage,
                title: searchField ? searchField : "",
                page: pageNo
            })
        })
            .then((res) => res.json())
            .then((response) => {
                if (response) {
                    setTopicData(response);
                    setNoOfPages(response?.noOfPages);
                }
                setLoader(false);
            }).catch((err) => {
                console.log("Err while getting users", err);
                setLoader(false);
            })
    }

    // for custome hook call
    const dependencies = [searchField ? searchField : ""];
    useDebouncedApiCall(getAllCourseTopic, dependencies, setLoader);

    // for deleter courseLesson
    const deleteCourseTopic = async () => {
        setLoader(true);

        try {
            const result = await fetch(process.env.REACT_APP_BASE_URL + '/courseTopic/deleteCourseTopic', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({ courseTopicId: delId })
            });
            const response = await result.json();
            if (response.message === 'Data deleted succussfully') {
                toast.success('CourseTopic Deleted Succussfully');
                setShowDel(false);
                setDelId('');
                setLoader(false);
                getAllCourseTopic();
            }
            else {
                toast.error('Failed to delete, try again');
                setShowDel(false);
                setDelId('');
                setLoader(false);
            }
        } catch (Err) {
            console.log("Err while deleting article", Err);
            setLoader(false);
        }
    }


    // function for pagination
    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }

    // for toggle
    const handlePublishAction = async (courseTopicId, isPublish) => {
        try {
            setIsPublishModalOpen(false);
            setIsUnpublish(false);

            const result = await fetch(process.env.REACT_APP_BASE_URL + "/courseTopic/publishCourseTopics", {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.data.token}` },
                body: JSON.stringify({
                    courseTopicId: courseTopicId,
                    isPublish: isPublish
                }),
            });

            const response = await result.json();
            if (response) {
                getAllCourseTopic();
            } else {
                console.log("Error toggling publish status");
            }
        } catch (error) {
            console.log(error);
        }
    };

    // Function to handle confirming publish modal
    const handlePublishModalConfirm = async () => {
        await handlePublishAction(publishCourseTopicId, true);
    };

    // Function to handle confirming unpublish modal
    const handleUnpublishConfirm = async () => {
        await handlePublishAction(unpublishId, false);
    };

    return (
        <div className='Course'>
            <PublishModel
                isOpen={isPublishModalOpen}
                onClose={handlePublishModalCancel}
                onConfirm={handlePublishModalConfirm}
                title="Course topic"
                loader={loader}
            />
            <UnPublishModel
                isOpen={isUnpublish}
                onClose={closeUnpublishModal}
                onConfirm={handleUnpublishConfirm}
                title="Course topic"
                loader={loader}
            />
            <DeleteModel
                show={showDel}
                onHide={() => { setShowDel(false); setDelId(''); }}
                onDelete={deleteCourseTopic}
                loader={loader}
                label="CourseTopic"
            />
            <ToastContainer />
            <Container>
                <Row className='justify-content-end'>
                    <Col md={12} className='d-flex justify-content-between my-auto mt-3 mt-md-0'>
                        <Button className="backBtn" onClick={() => navigate(-1)}>
                            <Icon.ArrowLeft className="me-2" />
                            Back
                        </Button>
                    </Col>
                </Row>

                <Row className='justify-content-between mt-2 mb-2'>
                    <Col md={4} className="mb-2 mb-sm-0">
                        <input
                            className="form-control"
                            type="text"
                            placeholder="Search here"
                            name="search"
                            value={searchField}
                            onChange={(e) => {
                                if (e.target.value.trim()) {
                                    setSearchField(e.target.value)
                                    setPageNo(1);
                                } else if (e.target.value.length === 0) {
                                    setSearchField(e.target.value)
                                    setPageNo(1);
                                }
                            }}
                        ></input>
                    </Col>

                    <Col md={6} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
                        <Button className='primaryBtn' onClick={() => navigate("/dashboard/addtopic", { state: { courseId: courseId, courseLessonId: courseLessonId, courseTitleLang: courseTitleLang, lessonTitleLang: lessonTitleLang } })}>
                            <Icon.JournalPlus className='me-2' size={16} />Add Topic
                        </Button>
                    </Col>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center mb-4'>
                    <Icon.JournalPlus size={20} className='me-2' />Course Topic
                </h3>


                <div className="outer-wrapper mx-auto mt-4">
                    <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
                        {/* <tr><th>Course Title :</th> <td>{courseTitleLang}</td> </tr> */}
                        <tr><th>Lesson Title :</th> <td>{lessonTitleLang}</td> </tr>

                        <table className='mt-2'>
                            <thead>
                                <th>Sr.</th>
                                <th>Image</th>
                                <th>Video</th>
                                <th >Topic Title</th>
                                <th >Topic Header</th>
                                {/* <th>noOfSession</th> */}
                                <th>Duration</th>
                                {/* <th>Content Type</th> */}
                                <th>Priority</th>
                                <th>Publish</th>
                                <th>Publish Date</th>
                                <th>Action</th>
                            </thead>
                            {loader ? (
                                <Skeleton rows={10} cols={11} key={Math.random() * 999999999} />
                            ) :
                                (
                                    topicData?.data !== null && topicData?.data !== undefined && topicData?.data?.length > 0 ? (
                                        topicData?.data?.map((itm, index) => {
                                            const { imageURL, videoURL, titleLang, titleHeaderLang, duration, content, courseTopicId, srNo } = itm;
                                            return (
                                                <tr key={index}>
                                                    <td>{pageNo !== 1 ? (
                                                        <>
                                                            {' '}
                                                            {index + 1 + docPerPage * (pageNo - 1)}
                                                        </>
                                                    ) : (
                                                        <>{index + 1}</>
                                                    )}</td>
                                                    <td key={index} className='d-flex flex-column'>
                                                        {imageURL && (
                                                            <img src={itm?.imageURL} alt={`Course ${index + 1} Img`} className='mb-3' width={40} />
                                                        )}
                                                    </td>
                                                    <td>{videoURL?.length === 0 ?
                                                        (<Icon.SlashCircle size={35} color='red' />) :
                                                        <div className='d-flex flex-column'>{

                                                            <div key={index} className="mb-3">
                                                                <a target="_blank" href={videoURL}>
                                                                    <Icon.PlayBtnFill style={{ width: 40, height: 40 }} />
                                                                </a>
                                                            </div>

                                                        }
                                                        </div>}
                                                    </td>
                                                    <td style={{ width: '200px' }}>{titleLang?.mr}</td>
                                                    <td style={{ width: '200px' }}>{titleHeaderLang?.mr}</td>
                                                    <td>{duration ? duration : "-"}</td>
                                                    {/* <td>{content ? content.map((itm) => itm.type) : "-"}</td> */}

                                                    <td>{srNo}</td>
                                                    <td style={{ width: '120px' }}>
                                                        {itm.isPublish ? (
                                                            <div
                                                                style={{
                                                                    backgroundColor: "#8FBC8F",
                                                                    borderRadius: "8px",
                                                                    padding: "4px 8px",
                                                                    color: "white",
                                                                    textAlign: "center",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => openUnpublishModal(itm.courseTopicId)}
                                                            >
                                                                Published
                                                            </div>
                                                        ) : (
                                                            <div
                                                                className="switch"
                                                                onClick={() => openPublishModal(itm.courseTopicId)}
                                                            >
                                                                <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                                                                <span className="slider round" onClick={() => openPublishModal(itm.courseTopicId)}></span>
                                                            </div>
                                                        )}

                                                    </td>
                                                    <td>{itm?.toPublishDate ? moment
                                                        (itm?.toPublishDate).format("DD-MM-YYYY") : "-"}</td>

                                                    <td className="d-flex">
                                                        <img src={View} alt="View" className="icon me-3"
                                                            onClick={() => navigate("/dashboard/viewcoursetopic", { state: { rowData: itm } })} />

                                                        <img src={Edit} alt="Edit" className="icon me-3" onClick={() => navigate("/dashboard/edittopic", { state: { rowData: itm, courseId: courseId, courseLessonId: courseLessonId, courseTitleLang: courseTitleLang, lessonTitleLang: lessonTitleLang } })}
                                                        />

                                                        <img src={Delete} alt="Delete" className='icon'
                                                            onClick={() => { setShowDel(true); setDelId(courseTopicId); }} />
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <p className='noDataFound'>No Data Found</p>
                                    )
                                )
                            }

                        </table>
                    </div>
                </div>

                <PaginationSequence
                    data={topicData?.count}
                    pageNo={pageNo}
                    noOfPages={noOfPages}
                    handlePageChange={handlePageChange}
                    handleDocsPerPage={handleDocsPerPage}
                    docPerPage={docPerPage}
                />
            </Container>

        </div>
    )
}


export default CourseTopic;