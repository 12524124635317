import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

const ViewHomeBanner = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const homeBannerData = location.state.homebanner;

  return (
    <div className="outletPadding">
      <Container>
        <Button className="primaryBtn" onClick={() => navigate(-1)}>
          <Icon.ArrowLeft className="me-2" />
          Back
        </Button>
        <Row className="mb-2 mt-4">
          <h4 className="mb-0 text-center">
            <Icon.Person className="me-1" /> Home Banner Details
          </h4>
        </Row>
        <Row className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper">
            <table data-aos="fade-up" data-aos-delay="200">
              <tbody>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Content Banner Image Url
                  </th>
                  <td>{homeBannerData?.bannerImgURL ? homeBannerData?.bannerImgURL : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Title in English
                  </th>
                  <td>{homeBannerData?.titleLang?.en ? homeBannerData?.titleLang?.en : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Title in Marathii
                  </th>
                  <td>{homeBannerData?.titleLang?.mr ? homeBannerData?.titleLang?.mr : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Priority
                  </th>
                  <td>{homeBannerData?.priorityNo ? homeBannerData?.priorityNo : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in English
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {homeBannerData?.shortDescriptionLang?.en ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: homeBannerData?.shortDescriptionLang?.en,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>


                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in Marathi
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {homeBannerData?.shortDescriptionLang?.mr ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: homeBannerData?.shortDescriptionLang?.mr,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Full Description in English
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {homeBannerData?.descriptionLang?.en ? (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: homeBannerData?.descriptionLang?.en,
                          }}
                        ></div>
                      </>

                    ) : (
                      "-"
                    )}
                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Full Description in Marathi
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {homeBannerData?.descriptionLang?.mr ? (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: homeBannerData?.descriptionLang?.mr,
                          }}
                        ></div>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row >
      </Container >
    </div >
  );
};

export default ViewHomeBanner;
