import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

const useGetPageList = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData?.data);

    const [pageData, setpageData] = useState([]);

    const getPageData = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/page/getAllPages', {
                method: 'POST',
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState?.token}` },
                body: JSON.stringify({ title: '' })
            });
            const result = await response.json();
            if (response.ok) {
                const pageDataResult = result?.data?.map((itm) => (
                    { label: itm?.pageName, value: itm?.pageId, Lang: itm?.pageNameLang }
                ));
                setpageData(pageDataResult);
            } else {
                setpageData([]);
            }
        } catch (error) {
            console.log("Error:", error);
        }
    };

    useEffect(() => {
        getPageData();
    }, []);

    return { pageData };
}

export default useGetPageList;
