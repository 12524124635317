import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../ActiveKeySlice';
import { useNavigate } from 'react-router-dom';

const UseShoppeeList = (token, setLoader) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [shoppeeList, setShoppeeList] = useState([]);

    const getAllShoppee = async () => {
        try {
            setLoader(true);
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/priority/titleTypeFilter', {
                method: 'POST',
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
                body: JSON.stringify({
                    type: "Product",
                })
            })
            const responseData = await response.json();
            if (response.message === "Authorization failed / Forbidden") {
                setLoader(true);
                dispatch(setLogout(null));
                dispatch(setActiveKey(null));

                localStorage.removeItem('persist:root');
                navigate('/');
            } else {
                setShoppeeList(responseData?.data);
                setLoader(false);
            }

        } catch (Err) {
            console.log("Err while getting activites", Err);
            setLoader(false);
        }
    }

    return { shoppeeList, getAllShoppee }
}


export default UseShoppeeList