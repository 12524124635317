import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Button,
    ToastContainer,
} from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import Skeleton from "../../Common/Skeleton";
import { useNavigate } from "react-router-dom";
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import DeleteModel from "../../Common/Model/DeleteModel";
import AddFAQ from "./Model/AddFAQ";
import EditFAQ from "./Model/EditFAQ";
import { toast } from "react-toastify";
window.Buffer = window.Buffer || require("buffer").Buffer;

const ViewFAQList = () => {

    const navigate = useNavigate();

    const [faqList, setFAQList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [isdelete, setIsDelete] = useState(false);
    const [deleteId, setDeleteId] = useState("");

    const [showAddfaqModal, setShowAddFaqModal] = useState(false);
    const [showeditfaqModal, setShowEditFaqModal] = useState(false);
    const [faqid, setFaqId] = useState('')

    const handlerOpenEditModel = (id) => {
        setFaqId(id);
        setShowEditFaqModal(true)
    }

    useEffect(() => {
        getFAQList()
    }, [])


    // for get faq list
    const getFAQList = async () => {
        setLoader(true);
        await fetch(
            process.env.REACT_APP_BASE_URL + "/faq/getAllFaqs",
            {
                method: "GET",
                headers: { "Content-Type": "application/json" },

            }
        )
            .then((res) => res.json())
            .then((response) => {
                if (response) {
                    setLoader(false);
                    setFAQList(response);
                } else {
                    setLoader(false)
                    setFAQList([])
                }
            })
            .catch((error) => {
                console.log("Error", error);
                setLoader(false);
            });
    };

    // for delete data
    const deleteFAQ = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + "/faq/deleteFaq", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                faqId: deleteId,
            }),
        })
            .then((res) => res.json())
            .then((response) => {
                if (response) {
                    toast.success("Deleted Successfully")
                    setIsDelete(false);
                    getFAQList();
                }

            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className="outletPadding">
            <ToastContainer />
            <AddFAQ
                show={showAddfaqModal}
                onHide={() => setShowAddFaqModal(false)}
                getFAQList={getFAQList}
            />
            <EditFAQ
                show={showeditfaqModal}
                onHide={() => setShowEditFaqModal(false)}
                getFAQList={getFAQList}
                faqid={faqid}
            />
            <DeleteModel
                show={isdelete}
                onHide={() => setIsDelete(false)}
                onDelete={deleteFAQ}
                loader={loader}
                label="FAQ"
            />
            <Container>
                {/* FAQ List */}
                <div className="outer-wrapper mx-auto mt-4">
                    <Row>
                        <Col
                            md={12}
                            className="d-flex justify-content-end my-auto mt-3 mt-md-0"
                        >
                            <Button className="primaryBtn" onClick={() => setShowAddFaqModal(true)}>
                                <Icon.PlusCircle className="me-2" size={15} />
                                Add FAQ
                            </Button>
                        </Col>
                    </Row>
                    <div className="table-wrapper mt-3" style={{ maxHeight: "70vh" }}>
                        <table>
                            <thead>
                                <th>Sr. No.</th>
                                <th>Question</th>
                                <th>Answer</th>
                                <th>Question For</th>
                                <th>Display On</th>
                                <th>Action</th>
                            </thead>
                            {loader ? (
                                <Skeleton rows={10} cols={6} />
                            ) : faqList?.data?.length > 0 && faqList?.data !== null && faqList?.data !== undefined ? (
                                faqList?.data?.map((itm, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td style={{ width: '400px' }}>{itm?.question ? itm?.question : "-"}</td>
                                            <td style={{ width: '400px' }}>
                                                {itm?.answer ? (
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: itm?.answer,
                                                        }}
                                                    ></div>
                                                ) : (
                                                    "-"
                                                )}
                                            </td>
                                            <td>{itm?.questionFor ? itm?.questionFor : "-"}</td>
                                            <td>{itm?.displayOn ? itm?.displayOn : "-"}</td>
                                            <td >
                                                <img src={Edit} alt="Edit" className="icon me-3"
                                                    onClick={() => handlerOpenEditModel(itm)} />
                                                <img src={Delete} alt="Delete" className='icon'
                                                    onClick={() => {
                                                        setIsDelete(true);
                                                        setDeleteId(itm?.faqId);
                                                    }} />
                                            </td>

                                        </tr>
                                    );
                                })
                            ) : (
                                <p className="noDataFound">No Data Found</p>
                            )}
                        </table>
                    </div>
                </div>

            </Container>
        </div>
    );
};

export default ViewFAQList;
