import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Modal, Row, Form, Spinner, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icon from "react-bootstrap-icons";
import normal from "../../../Assets/gif/frame-88.png";
import inactive from "../../../Assets/gif/Ripple-1s-200px (1).gif";
import moment from 'moment';

const ViewSubscriptionUser = () => {
    const navigate = useNavigate()
    const userState = useSelector((state) => state?.cmsLogin?.userData);
    const location = useLocation()
    const userId = location?.state?.userId
    const [userdata, setUserData] = useState('')
    const [isRenewalEnabled, setIsRenewalEnabled] = useState(false);

    useEffect(() => {
        singleUserDetails()
    }, [])

    useEffect(() => {
        // Check if subscription end date has passed
        if (userdata && moment(userdata?.subscriptionEndDate).isBefore(moment())) {
            setIsRenewalEnabled(true);
        } else {
            setIsRenewalEnabled(false);
        }
    }, [userdata])

    const singleUserDetails = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/cms/getUserSubscription', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState?.data?.token}` },
            body: JSON.stringify({
                userId: userId
            })
        })
            .then((response) => response.json())
            .then((res) => {
                setUserData(res?.data)
            })
            .catch((error) => {
                console.log("error", error);
            })
    }

    return (
        <div className='profile outletPadding' >
            <Container>
                <div className='d-flex justify-content-between'>
                    <Button className="backBtn" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-2" />
                        Back
                    </Button>
                    <Button className='primaryBtn w-auto' disabled={!isRenewalEnabled}><Icon.Lock className='me-1' />Renewal</Button>
                </div>

                <Row className='mt-3'>
                    <Col md={5}>
                        <h5>User Subscription Details</h5>
                        <Card>
                            <div>
                                <h6><Icon.PersonFill className='me-2' />FullName -</h6>
                                <p>{userdata ? userdata?.fullName : "-"}</p>
                            </div>

                            <div>
                                <h6><Icon.BriefcaseFill className='me-2' />MembershipPlan - </h6>
                                <p>{userdata ? userdata?.membershipPlan : "-"}</p>
                            </div>

                            <div>
                                <h6><Icon.Calendar2Fill className='me-2' />subscriptionStartDate - </h6>
                                <p>{userdata ? moment(userdata?.subscriptionStartDate).format("DD-MM-YYYY") : "-"}</p>
                            </div>
                            <div>
                                <h6><Icon.Calendar2DayFill className='me-2' />subscriptionEndDate - </h6>
                                <p>{userdata ? moment(userdata?.subscriptionEndDate).format("DD-MM-YYYY") : "-"}</p>
                            </div>
                            <div>
                                <h6>{userdata?.isSbusciption === true ? <Icon.ToggleOn className='me-2' /> : <Icon.ToggleOff className='me-2' />}Subscription Status - </h6>
                                <p>{userdata?.isSbusciption === true ?
                                    <>
                                        <img width={50} height={50} src={normal} alt='Active' data-toggle="tooltip" title="Active" />
                                        <span>Active</span>
                                    </> :
                                    <>
                                        <img width={50} height={50} src={inactive} alt='Inactive' data-toggle="tooltip" title="Inactive" />
                                        <span>Inactive</span>
                                    </>}</p>
                            </div>
                            <div>
                                <h6><Icon.WalletFill className='me-2' />Payment Status - </h6>
                                <p>{userdata?.isPayment === true ? "Paid" : "Not Paid"}</p>
                            </div>

                        </Card>
                    </Col>
                </Row>

            </Container>

        </div>
    )
}

export default ViewSubscriptionUser

