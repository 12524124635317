import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../ActiveKeySlice';
import { useNavigate } from 'react-router-dom';

const useArticleList = (token, setLoader) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [articleList, setArticleList] = useState([]);

    const getArticleList = async () => {
        setLoader(true);
        await fetch(process.env.REACT_APP_BASE_URL + "/priority/titleTypeFilter", {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
            body: JSON.stringify({
                type: "Article",
            }),
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.message === "Authorization failed / Forbidden") {
                    setLoader(true);
                    dispatch(setLogout(null));
                    dispatch(setActiveKey(null));
                    localStorage.removeItem('persist:root');
                    navigate('/');
                } else if (response.status === 200) {
                    setArticleList(response?.data);
                } else {
                    setArticleList([]);
                }
                setLoader(false);
            })
            .catch((error) => {
                console.log(error);
                setLoader(false);
            });
    };

    return { articleList, getArticleList }
}

export default useArticleList