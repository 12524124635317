import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';

const useGetMoodList = () => {
    const userState = useSelector((state) => state?.cmsLogin?.userData?.data);

    const [moodData, setMoodData] = useState([]);

    const getMoodData = async () => {
        try {
            const response = await fetch(process.env.REACT_APP_BASE_URL + '/mood/getAllMoods', {
                method: 'POST',
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState?.token}` },
                body: JSON.stringify({ title: '' })
            });
            const result = await response.json();
            if (response.ok) {
                const moodDataResult = result?.data?.map((itm) => (
                    { label: itm?.moodTitle, value: itm?.moodId }
                ));
                const updatedMood = [{ label: 'No Mood', value: 'no mood' }, ...moodDataResult]
                setMoodData(updatedMood);
            } else {
                setMoodData([]);
            }
        } catch (error) {
            console.log("Error:", error);
        }
    };

    useEffect(() => {
        getMoodData();
    }, []);

    return { moodData };
}

export default useGetMoodList;
