import React, { useState } from 'react'
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Icon from "react-bootstrap-icons";
import { useSelector } from 'react-redux';
import moment from 'moment';
import { toast } from 'react-toastify';

const EditPanchang = () => {
    const location = useLocation();
    const navigate = useNavigate()

    const userState = useSelector((state) => state?.cmsLogin?.userData.data);
    const panchang = location.state.panchang;

    const [traveldirection, setTravelDirection] = useState(panchang?.travelDirection);
    const [marathimonth, setMarathiMonth] = useState(panchang?.marathiMonth)
    const [englishmonth, setEnglishMonth] = useState(panchang?.englishMonth)
    const [day, setDay] = useState(panchang?.day);
    const [date, setDate] = useState(moment(panchang?.date).format("YYYY-MM-DD"));
    const [enery, setEnery] = useState(panchang?.energy);
    const [moonphase, setMoonPhae] = useState(panchang?.moonPhase);
    const [nakshtra, setNakshtra] = useState(panchang?.nakshtra);
    const [moonsigns, setMoonSigns] = useState(panchang?.moonSigns);
    const [others, setOthers] = useState(panchang?.others);
    const [description, setDescription] = useState(panchang?.description)

    // for loader
    const [loader, setLoader] = useState(false);

    // for error
    const [error, setErrors] = useState({})

    // for validation
    const validate = () => {
        let newErrors = {}

        if (!traveldirection) {
            newErrors.travelDirection = " required field"
        }
        if (!marathimonth) {
            newErrors.marathimonth = " required field"
        }
        if (!englishmonth) {
            newErrors.englishmonth = " required field"
        }
        if (!day) {
            newErrors.day = " required field"
        }
        if (!date) {
            newErrors.date = " required field"
        }
        if (!enery) {
            newErrors.enery = " required field"
        }
        if (!moonphase) {
            newErrors.moonphase = " required field"
        }
        if (!nakshtra) {
            newErrors.nakshtra = " required field"
        }
        if (!moonsigns) {
            newErrors.moonsigns = " required field"
        }
        if (!others) {
            newErrors.others = " required field"
        }
        // if (!description) {
        //     newErrors.description = " required field"
        // }

        setErrors(newErrors)
        return Object.keys(newErrors).length === 0;
    }

    // for update panchang function
    const editPanchang = async () => {
        setLoader(true)
        const isValid = validate()
        setLoader(false);
        if (isValid) {
            setLoader(true);
            let payload = {
                panchangId: panchang?.panchangId,
                marathiMonth: marathimonth,
                englishMonth: englishmonth,
                day: day,
                date: date,
                energy: enery,
                vastu: "",
                moonPhase: moonphase,
                meeting: "",
                friendship: "",
                houseEntry: "",
                travelDirection: traveldirection,
                nakshtra: nakshtra,
                moonSigns: moonsigns,
                others: others,
                description: description,
            }

            await fetch(process.env.REACT_APP_BASE_URL + "/cms/updatePanchang", {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
                body: JSON.stringify(payload),
            })
                .then((res) => res.json())
                .then((response) => {
                    if (response.message === "Updated Successfully") {
                        toast.success("Updated Successfully !")
                        navigate("/dashboard/panchang")
                    }
                    setLoader(false)
                })
                .catch((err) => {
                    setLoader(false)
                    console.log("Error", err);
                })
        }
    }

    return (
        <>
            <div className="outletPadding">
                <Row>
                    <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-1" />Back
                    </Button>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center'>
                    <Icon.ChatQuote size={20} className='me-2' />Edit Panchang
                </h3>

                <Row>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Travel Direction<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Enter Travel Direction"
                                value={traveldirection}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(
                                            /[\u0900-\u097F]/
                                        ) &&
                                        e.target.value.trim()
                                    ) {
                                        setTravelDirection(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTravelDirection(e.target.value);
                                    }
                                }}
                            />
                            {error.traveldirection && <div className='error'>{error.traveldirection}</div>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Enery<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Enter Enery"
                                value={enery}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(
                                            /[\u0900-\u097F]/
                                        ) &&
                                        e.target.value.trim()
                                    ) {
                                        setEnery(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setEnery(e.target.value);
                                    }
                                }}
                            />
                            {error.energy && <div className='error'>{error.energy}</div>}

                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Marathi Month<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Enter Marathi Month"
                                value={marathimonth}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(
                                            /[\u0900-\u097F]/
                                        ) &&
                                        e.target.value.trim()
                                    ) {
                                        setMarathiMonth(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setMarathiMonth(e.target.value);
                                    }
                                }}
                            />
                            {error.marathimonth && <div className='error'>{error.marathimonth}</div>}

                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>English Month<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Enter English Month"
                                value={englishmonth}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(
                                            /[\u0900-\u097F]/
                                        ) &&
                                        e.target.value.trim()
                                    ) {
                                        setEnglishMonth(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setEnglishMonth(e.target.value);
                                    }
                                }}
                            />
                            {error.englishmonth && <div className='error'>{error.englishmonth}</div>}

                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Moon Phase<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Enter Moon Phase"
                                value={moonphase}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(
                                            /[\u0900-\u097F]/
                                        ) &&
                                        e.target.value.trim()
                                    ) {
                                        setMoonPhae(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setMoonPhae(e.target.value);
                                    }
                                }}
                            />
                            {error.moonphase && <div className='error'>{error.moonphase}</div>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Moon Signs<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Enter Moon Signs"
                                value={moonsigns}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(
                                            /[\u0900-\u097F]/
                                        ) &&
                                        e.target.value.trim()
                                    ) {
                                        setMoonSigns(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setMoonSigns(e.target.value);
                                    }
                                }}
                            />
                            {error.moonsigns && <div className='error'>{error.moonsigns}</div>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Nakshtra<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Enter Nakshtra"
                                value={nakshtra}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(
                                            /[\u0900-\u097F]/
                                        ) &&
                                        e.target.value.trim()
                                    ) {
                                        setNakshtra(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setNakshtra(e.target.value);
                                    }
                                }}
                            />
                            {error.nakshtra && <div className='error'>{error.nakshtra}</div>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Other<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Enter Other"
                                value={others}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(
                                            /[\u0900-\u097F]/
                                        ) &&
                                        e.target.value.trim()
                                    ) {
                                        setOthers(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setOthers(e.target.value);
                                    }
                                }}
                            />
                            {error.others && <div className='error'>{error.others}</div>}

                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Day<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Enter Day"
                                value={day}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(
                                            /[\u0900-\u097F]/
                                        ) &&
                                        e.target.value.trim()
                                    ) {
                                        setDay(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setDay(e.target.value);
                                    }
                                }}
                            />
                            {error.day && <div className='error'>{error.day}</div>}

                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Date<span>*</span></Form.Label>
                            <Form.Control
                                placeholder="Enter Date"
                                type="date"
                                value={date}
                                min={new Date().toISOString().split("T")[0]}
                                onKeyDown={(event) => event.preventDefault()}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(
                                            /[\u0900-\u097F]/
                                        ) &&
                                        e.target.value.trim()
                                    ) {
                                        setDate(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setDate(e.target.value);
                                    }
                                }}
                            />
                            {error.date && <div className='error'>{error.date}</div>}
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group className='mb-3'>
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={5}
                                placeholder='Enter Description'
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                            {/* {error.description && <div className='error'>{error.description}</div>} */}
                        </Form.Group>
                    </Col>
                </Row>

                {loader ? (
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                        <Spinner variant="primary" />
                    </div>
                ) : (
                    <>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5%", marginTop: "4%" }}>
                            <Button className="primaryBtn me-4" onClick={() => editPanchang(true)}>
                                <Icon.ChatQuote className="me-1" />
                                Update
                            </Button>
                        </div>
                    </>
                )}

            </div>
        </>
    )
}

export default EditPanchang