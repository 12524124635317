import React from 'react';
import ReactApexChart from 'react-apexcharts';

const LineChart = ({ labels, data }) => {

    if (!data || data.length === 0) {
        return (
            <div className="no-data-container">
                <p className="no-data-message">No data found</p>
            </div>
        );
    }
    const series = [{
        name: 'Count',
        data: data || [],
    }];

    const options = {
        chart: {
            type: 'line',
            stacked: false,
            height: 350,
            zoom: {
                enabled: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        markers: {
            size: 4,
            colors: ['#e41a1c'],
            strokeColors: '#fff',
            strokeWidth: 2,
        },
        fill: {
            type: 'gradient',
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [20, 100, 100, 100],
            },
        },
        yaxis: {
            title: {
                text: 'Count', // Added y-axis title
            },
            labels: {
                style: {
                    colors: '#8e8da4',
                },
                offsetX: 0,
                formatter: function (val) {
                    return val?.toFixed(2);
                },
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        xaxis: {
            type: 'category',
            categories: labels || [],
            tickAmount: 8,
            labels: {
                rotate: 0,
                rotateAlways: true,
                style: {
                    colors: 'black',
                },
                offsetY: 5,
                offsetX: 5
            },
            grid: {
                lines: {
                    show: true,
                    borderColor: 'rgba(0, 0, 0, 0)',
                },
            },
        },
        title: {
            align: 'left',
            offsetX: 14,
        },
        tooltip: {
            shared: true,
        },
        legend: {
            position: 'top',
            horizontalAlign: 'right',
            offsetX: -10,
        },
        stroke: {
            curve: 'smooth',
            width: 4,
        },
        toolbar: {
            show: false,
        },
    };

    return (
        <div
            style={{
                // backgroundColor: 'rgba(240, 243, 244, 0.5)',
                // height: '100%',
                // width: '100%',
                // borderRadius: '12px',
                // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                // padding: '10px',
            }}>
            <ReactApexChart options={options} series={series} type="area" height={400} />
            <p className='text-center fw-bold mt-2'>Audit Log Data Graph</p>
        </div>
    );
}

export default LineChart;
