import React from 'react'
import { Button } from 'react-bootstrap'
import * as Icon from "react-bootstrap-icons"

const ButtonComponent = ({ onSave, onSaveAndPublish, saveLabel, savePublish }) => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: "5%", marginTop: '4%' }}>
            <Button className="primaryBtn me-4" onClick={() => onSave(false)}>
                <Icon.Save className="me-1" />
                {saveLabel}
            </Button>

            <Button className="primaryBtn" onClick={() => onSaveAndPublish(true)}>
                <Icon.JournalArrowUp className="me-1" />
                {savePublish}
            </Button>
        </div>
    )
}

export default ButtonComponent