import React, { useState } from "react";
import { Form, Tab, Nav } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
window.Buffer = window.Buffer || require("buffer").Buffer;

const CategoryTab = ({ categoryEn, setCategoryEn, categoryMr, setCategoryMr, categoryHn, setCategoryHn, label, placeholder }) => {

    // for language
    const [key, setkey] = useState('mr');
    const activeKeyRedux = useSelector((state) => state?.cmsActiveData?.activeNumberKey);

    const handleTabSelect = (key) => {
        setkey(key);
    };


    // Check if Marathi category are filled
    const isMarathiFilled = (
        (categoryMr?.trim() !== '' && categoryMr?.trim() !== '<p><br></p>')
    );

    // Check if English category are filled
    const isEnglishFilled = (
        (categoryEn?.trim() !== '' && categoryEn?.trim() !== '<p><br></p>')
    );
    // Check if Hindi category are filled
    const isHindiFilled = (
        ""
        // (categoryHn?.trim() !== '' && categoryHn?.trim() !== '<p><br></p>') 
    );
    return (
        <div className="ChromeTabs">
            <Tab.Container activeKey={key} onSelect={handleTabSelect}>
                <Nav variant="tabs" className="flex-row">
                    <Nav.Item>
                        <Nav.Link eventKey="mr" className={isMarathiFilled ? "filled-tab" : ""}>
                            Marathi
                            {isMarathiFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="en" className={isEnglishFilled ? "filled-tab" : ""}>
                            English {isEnglishFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item >
                        <Nav.Link eventKey="hn" className={isHindiFilled ? "filled-tab" : ""} disabled>
                            Hindi {isHindiFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                </Nav>


                <Tab.Content>
                    <Tab.Pane eventKey="mr" className="mt-3">
                        <div data-aos="fade-up">
                            <Form.Group className="mb-3">
                                <Form.Label>{label} Name in Marathi<span>*</span></Form.Label>
                                <Form.Control
                                    value={categoryMr}
                                    placeholder={`${placeholder} प्रविष्ट करा`}
                                    onChange={(e) => {
                                        const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                        if (e.target.value.length <= 100) {
                                            if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                                setCategoryMr(e.target.value);
                                            } else if (e.target.value.length === 0) {
                                                setCategoryMr(e.target.value);
                                            }
                                        } else {
                                            toast.error('Character limit is 100');
                                        }
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="en" className="mt-3">
                        {
                            key === "en" &&
                            <div data-aos="fade-up">

                                <Form.Group className="mb-3">
                                    <Form.Label>{label} Name in English<span>*</span></Form.Label>
                                    <Form.Control
                                        value={categoryEn}
                                        placeholder={`Enter ${label}`}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const allowedRegex = /[\u0900-\u097F]/;
                                            if (value.length <= 100) {
                                                if (value === '' || !allowedRegex.test(value)) {
                                                    setCategoryEn(value);
                                                } else {
                                                    setCategoryEn('')
                                                }
                                            } else {
                                                toast.error('Character limit is 100');
                                            }
                                        }
                                        }

                                    />
                                </Form.Group>
                            </div>
                        }
                    </Tab.Pane>
                    <Tab.Pane eventKey="hn" className="mt-3" >
                        {
                            key === "hn" &&
                            <div data-aos="fade-up">

                                <Form.Group className="mb-3">
                                    <Form.Label>{label} Name in Hindi</Form.Label>
                                    <Form.Control
                                        value={categoryHn}
                                        placeholder="श्रेणी दर्ज करें"
                                        onChange={(e) => {
                                            const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                            if (e.target.value.length <= 100) {
                                                if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                                    setCategoryHn(e.target.value);
                                                } else if (e.target.value.length === 0) {
                                                    setCategoryHn(e.target.value);
                                                }
                                            } else {
                                                toast.error('Character limit is 100');
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </div>
                        }
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div >
    )
}

export default CategoryTab