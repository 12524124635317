import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Spinner, Modal, } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "../../Common/Skeleton";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import { setLogout } from "../../Auth/LoginSlice";
import { setActiveKey } from "../../Common/ActiveKeySlice";
import useDebouncedApiCall from "../../Common/Reuse/Debounce";
import DeleteModel from "../../Common/Model/DeleteModel";
import { toast } from "react-toastify";
import PaginationSequence from "../../Common/Pagination/PaginationSequence";
window.Buffer = window.Buffer || require("buffer").Buffer;

const Tags = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [selectedCategory, setSelectedCategory] = useState("");
  const [tagName, setTagName] = useState("");
  const [tagCategoryList, setTagCategoriesList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [EditItem, setEditItem] = useState({});
  const [isdelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const [searchField, setSearchField] = useState("");

  //Error Variables -----------
  const [categoryError, setCategoryError] = useState(false);
  const [tagNAmeError, setTagNAmeError] = useState(false);

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(10);
  const [noOfPages, setNoOfPages] = useState();

  useEffect(() => {
    getTagCatList();
  }, [])


  // Add Event and update Modal Handlers ----------
  const [showAdd, setShowAdd] = useState(false);
  const userState = useSelector((state) => state?.cmsLogin?.userData.data);

  const closeModal = () => {
    setShowAdd(false);
  };

  const getTagCatList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/cms/getAllTagCat", {
      method: "GET",
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          let arr = [];
          response.data.map((item) => {
            arr.push({
              ...item,
              label: item.tagCategoryName,
              value: item.tagCategoryName,
            });
          });
          setTagCategoriesList(arr);
        }
      }).catch((error) => {
        console.log("error", error);
      })
  };

  useEffect(() => {
    getAllTags()
  }, [pageNo, docPerPage])

  const getAllTags = async () => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + "/priority/titleFilter", {
      method: "POST",
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
      body: JSON.stringify({
        type: "Tag",
        title: searchField ? searchField : "",
        documentsPerPage: docPerPage,
        page: pageNo
      })
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "Authorization failed / Forbidden") {
          setLoader(true);
          dispatch(setLogout(null));
          dispatch(setActiveKey(null));

          localStorage.removeItem('persist:root');
          navigate('/');
        } else if (response.status === 200) {
          setTagList(response);
          setNoOfPages(response?.noOfPages);
          setLoader(false);
        } else {
          setLoader(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
      });
  };

  // for debounding
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getAllTags, dependencies, setLoader);

  // addTag API
  const addTag = async () => {
    setLoader(true);
    if (selectedCategory && tagName !== "") {
      let payload = {
        tag: tagName,
        tagCategoryName: selectedCategory.tagCategoryName,
        tagCategoryId: selectedCategory.tagCategoryId,
        createdBy: userState?.data?.user?.name,
        updatedBy: userState?.data?.user?.name,
      };

      await fetch(process.env.REACT_APP_BASE_URL + "/tag/createTag", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
            toast.success("Added successfully")
            closeModal();
            setTagName("");
            setSelectedCategory("");
            setLoader(false);
            getAllTags();
          }
        });
    } else {
      setLoader(false);
      if (selectedCategory == "") {
        setCategoryError(true);
      } else {
        setCategoryError(false);
      }

      if (tagName === "") {
        setTagNAmeError(true);
      } else {
        setTagNAmeError(false);
      }
    }
  };

  // update tag
  const UpdateTag = async () => {
    setLoader(true);
    if (selectedCategory && tagName !== "") {
      let payload = {
        tag: tagName,
        tagCategoryName: selectedCategory.tagCategoryName,
        tagCategoryId: selectedCategory.tagCategoryId,
        createdBy: userState?.user?.name,
        updatedBy: userState?.user?.userId,
        tagId: EditItem.tagId
      };

      await fetch(process.env.REACT_APP_BASE_URL + "/tag/updateTag", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify(payload),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
            toast.success("Updated Successfully")
            closeModal();
            setTagName("");
            setSelectedCategory("");
            setLoader(false);
            setIsEdit(false)
            getAllTags();
          }
        });
    } else {
      setLoader(false);
      if (selectedCategory == "") {
        setCategoryError(true);
      } else {
        setCategoryError(false);
      }

      if (tagName === "") {
        setTagNAmeError(true);
      } else {
        setTagNAmeError(false);
      }
    }
  };

  // delete tag
  const deleteTag = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + "/tag/deleteTag", {
      method: "POST",
      headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
      body: JSON.stringify({
        tagId: deleteId,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.message === "Data deleted succussfully") {
          toast.success("tags Deleted Succussfully")
          setIsDelete(false);
          setLoader(false)
          getAllTags();
        }

      })
      .catch((error) => {
        console.log(error);
      });
  };

  const editModal = (itm) => {
    setIsEdit(true);
    setShowAdd(true);
    setEditItem(itm);
    setTagName(itm.tag)
    setSelectedCategory(
      tagCategoryList.filter(
        (row) => row.tagCategoryId === itm.tagCategoryId
      )[0]
    );
  };


  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }

  return (
    <div className="outletPadding">

      <DeleteModel
        show={isdelete}
        onHide={() => setIsDelete(false)}
        onDelete={deleteTag}
        loader={loader}
        label="Tag"
      />
      <Container>
        <Row className="justify-content-between">
          <Col md={4} className="mb-2 mb-sm-0">
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                const marathiRegex = /^[\u0900-\u097F a-zA-Z]*$/;
                if (marathiRegex.test(e.target.value)) {
                  setSearchField(e.target.value);
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value);
                  setPageNo(1);
                }
              }}
            ></input>
          </Col>

          <Col
            md={2}
            className="d-flex justify-content-end my-auto mt-3 mt-md-0"
          >
            <Button
              className="primaryBtn"
              onClick={() => {
                setShowAdd(true);
                setTagName("");
                setSelectedCategory("");
                setIsEdit(false);
              }}
            >
              <Icon.PlusCircle className="me-2" size={16} />
              Add Tag
            </Button>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: "70vh" }}>
            <table>
              <thead>
                <th>Sr. No.</th>
                <th>Tag Name</th>
                <th>Tag Category</th>
                <th>Status</th>
                <th>Created Date</th>
                <th>Action</th>
              </thead>
              {loader ? (
                <Skeleton rows={10} cols={6} />
              ) : tagList?.data?.length > 0 && tagList?.data !== null && tagList?.data !== undefined ? (
                tagList?.data?.map((itm, index) => {
                  return (
                    <tr key={Math.random() * 999999999}>
                      <td>{pageNo !== 1 ? (
                        <>
                          {' '}
                          {index + 1 + docPerPage * (pageNo - 1)}
                        </>
                      ) : (
                        <>{index + 1}</>
                      )}</td>
                      <td style={{ width: '200px' }}>{itm?.tag}</td>
                      <td>{itm?.tagCategoryName ? itm?.tagCategoryName : "-"}</td>
                      <td>{itm?.status}</td>
                      <td>{moment(itm?.createdAt).format("DD-MM-YYYY")}</td>
                      <td className="d-flex">
                        <img src={View} alt="View" className="icon me-3"
                          onClick={() => navigate("/dashboard/viewtaglist", {
                            state: { tagData: itm }
                          })
                          } />

                        <img src={Edit} alt="Edit" className="icon me-3"
                          onClick={() => {
                            editModal(itm);
                          }}

                        />

                        <img src={Delete} alt="Delete" className='icon'
                          onClick={() => {
                            setIsDelete(true);
                            setDeleteId(itm?.tagId);
                          }} />
                      </td>

                    </tr>
                  );
                })
              ) : (
                <p className="noDataFound">No Data Found</p>
              )}
            </table>
          </div>
        </div>

        {/* for pagination */}

        <PaginationSequence
          data={tagList?.count}
          pageNo={pageNo}
          noOfPages={noOfPages}
          handlePageChange={handlePageChange}
          handleDocsPerPage={handleDocsPerPage}
          docPerPage={docPerPage}
        />
      </Container>

      {/* Add Tags */}
      <Modal
        size="sm"
        show={showAdd}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          {" "}
          <h4 className="mb-0">
            <Icon.PlusCircle size={20} className="me-2" />
            Add Tags
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Tag Categories<span className='errMsg'>*</span></Form.Label>
                <Select
                  isMulti={false}
                  isDisabled={isEdit}
                  options={tagCategoryList}
                  value={selectedCategory}
                  onChange={(option) => {
                    setSelectedCategory(option);
                  }}
                />
                {categoryError ? (
                  <p className="errMsg">Please select Tag Category.</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3">
                <Form.Label>Tag Name<span className='errMsg'>*</span></Form.Label>
                <Form.Control
                  disabled={selectedCategory === ''}
                  placeholder="Enter Tags Name"
                  value={tagName}
                  onChange={(e) => {
                    if (
                      !e.target.value.match(
                        /[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/
                      ) &&
                      e.target.value.trim()
                    ) {
                      setTagName(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setTagName(e.target.value);
                    }
                    e.target.value.length > 0
                      ? setTagNAmeError(false)
                      : setTagNAmeError(true);
                  }}
                />
                {tagNAmeError ? (
                  <p className="errMsg">Please enter tag Name</p>
                ) : (
                  ""
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {loader ? (
            <Spinner variant="primary" />
          ) : (
            <Button className="primaryBtn" onClick={isEdit ? UpdateTag : addTag}>
              {isEdit ? (
                <>
                  <Icon.PencilSquare size={20} className="me-2" />
                  Update
                </>
              ) : (
                <>
                  <Icon.PlusCircle className="me-1" />
                  Add Tag
                </>
              )}
            </Button>
          )}
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default Tags;
