import React, { useEffect, useState } from "react";
import { Container, Row, Button, Spinner, Col, Card, ButtonGroup, ToggleButton, } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import moment from "moment";
import TextToSpeech from "../../Common/Reuse/TextToSpeech/TextToSpeech";

const ViewCoursetask = () => {
    const location = useLocation();
    const courseTaskData = location.state.rowData;
    const navigate = useNavigate()

    return (
        <div className="outletPadding">
            <Container>
                <Button className="backBtn" onClick={() => navigate(-1)}>
                    <Icon.ArrowLeft className="me-2" />
                    Back
                </Button>
                <Row className="mb-2 mt-4">
                    <h4 className="mb-0 text-center">
                        <Icon.Person className="me-1" /> Course Task Details
                    </h4>
                </Row>
                <Row className="outer-wrapper mx-auto mt-4">
                    <div className="table-wrapper">
                        <table data-aos="fade-up" data-aos-delay="200">
                            <tbody>
                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Task in english
                                    </th>
                                    <td>{courseTaskData?.titleLang?.en ? courseTaskData?.titleLang?.en : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Task in marathi
                                    </th>
                                    <td>{courseTaskData?.titleLang?.mr ? courseTaskData?.titleLang?.mr : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Task Image Link
                                    </th>
                                    <td>{courseTaskData?.imageUrl ? courseTaskData?.imageUrl : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Content
                                    </th>
                                    <td>{courseTaskData?.content ? courseTaskData?.content : "-"}</td>

                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        MaxPoint
                                    </th>
                                    <td>{courseTaskData?.maxPoint ? courseTaskData?.maxPoint : "-"}</td>

                                </tr>
                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Duration
                                    </th>
                                    <td>{courseTaskData?.duration ? courseTaskData?.duration : "-"}</td>

                                </tr>
                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        CreatedBy
                                    </th>
                                    <td>{courseTaskData?.createdBy ? courseTaskData?.createdBy : "-"}</td>
                                </tr>

                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Full Description in English
                                    </th>
                                    <td>{courseTaskData?.descriptionLang?.en ? (
                                        <>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: courseTaskData?.descriptionLang?.en,
                                                }}
                                            ></div>
                                            <TextToSpeech htmlContent={courseTaskData?.descriptionLang?.en} language="mr" />

                                        </>
                                    ) : (
                                        "-"
                                    )}</td>
                                </tr>
                                <tr>
                                    <th>
                                        <Icon.Code className="me-2" />
                                        Full Description in Marathi
                                    </th>
                                    <td>{courseTaskData?.descriptionLang?.mr ? (
                                        <>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: courseTaskData?.descriptionLang?.mr,
                                                }}
                                            ></div>
                                            <TextToSpeech htmlContent={courseTaskData?.descriptionLang?.mr} language="mr" />

                                        </>
                                    ) : (
                                        "-"
                                    )}</td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                </Row>
            </Container>
        </div>
    );
};

export default ViewCoursetask;
