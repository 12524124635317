import React, { useState } from "react";
import { Form, Tab, Nav } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
window.Buffer = window.Buffer || require("buffer").Buffer;

const QueAnsTab = ({ questionmr, setQuestionmr, answermr, setAnswerMr, question, setQuestion, answer, setAnswer
    , questionhin, setQuestionHin, answerhin, setAnswerHin }) => {

    // for language
    const [key, setkey] = useState('mr');
    const activeKeyRedux = useSelector((state) => state?.cmsActiveData?.activeNumberKey);

    const handleTabSelect = (key) => {
        setkey(key);
    };


    // Check if Marathi descriptions are filled
    const isMarathiFilled = (
        (answermr?.trim() !== '' && answermr?.trim() !== '<p><br></p>') &&
        (questionmr?.trim() !== '' && questionmr?.trim() !== '<p><br></p>')
    );

    // Check if English descriptions are filled
    const isEnglishFilled = (
        (answer?.trim() !== '' && answer?.trim() !== '<p><br></p>') &&
        (question?.trim() !== '' && question?.trim() !== '<p><br></p>')
    );;
    // Check if Hindi descriptions are filled
    const isHindiFilled = (
        ""
        // (questionhin?.trim() !== '' && questionhin?.trim() !== '<p><br></p>') &&
        // (answerhin?.trim() !== '' && answerhin?.trim() !== '<p><br></p>')
    );
    return (
        <div className="ChromeTabs">
            <Tab.Container activeKey={key} onSelect={handleTabSelect}>
                <Nav variant="tabs" className="flex-row">
                    <Nav.Item>
                        <Nav.Link eventKey="mr" className={isMarathiFilled ? "filled-tab" : ""}>
                            Marathi
                            {isMarathiFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                            {/* <Icon.InfoCircleFill /> */}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="en" className={isEnglishFilled ? "filled-tab" : ""}>
                            English {isEnglishFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item >
                        <Nav.Link eventKey="hn" className={isHindiFilled ? "filled-tab" : ""} disabled>
                            Hindi {isHindiFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                    {activeKeyRedux?.activeTab === "Article List" &&
                        <p className="mt-2 fs-6">
                            (Recommanded image resolution - 4/5 (1080*1350 px))
                        </p>}
                </Nav>


                <Tab.Content>
                    <Tab.Pane eventKey="mr" className="mt-3">
                        <div data-aos="fade-up">
                            <Form.Group className="mb-3">
                                <Form.Label>Question in Marathi<span>*</span></Form.Label>
                                <Form.Control
                                    placeholder="Enter Question in marathi"
                                    value={questionmr}
                                    onChange={(e) => {
                                        if (
                                            e.target.value.match(/^[\u0000-\u007F\u0900-\u097F]*$/) ||
                                            e.target.value.length === 0
                                        ) {
                                            setQuestionmr(e.target.value);
                                        }
                                    }}
                                />
                            </Form.Group>

                            <Form.Group className="mb-1">
                                <Form.Label>Answer in Marathi<span>*</span></Form.Label>
                                <Form.Control
                                    placeholder="Enter Answer in marathi"
                                    value={answermr}
                                    onChange={(e) => {
                                        if (
                                            e.target.value.match(/^[\u0000-\u007F\u0900-\u097F]*$/) ||
                                            e.target.value.length === 0
                                        ) {
                                            setAnswerMr(e.target.value);
                                        }
                                    }}
                                />
                            </Form.Group>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="en" className="mt-3">
                        {
                            key === "en" &&
                            <div data-aos="fade-up">
                                <Form.Group className="mb-3">
                                    <Form.Label>Question in English<span>*</span></Form.Label>
                                    <Form.Control
                                        placeholder="Enter Question in english"
                                        value={question}
                                        onChange={(e) => {
                                            if (
                                                !e.target.value.match(
                                                    /[\u0900-\u097F]/
                                                ) &&
                                                e.target.value.trim()
                                            ) {
                                                setQuestion(e.target.value);
                                            } else if (e.target.value.length === 0) {
                                                setQuestion(e.target.value);
                                            }
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-5">
                                    <Form.Label>Answer in English<span>*</span></Form.Label>
                                    <Form.Control
                                        placeholder="Enter Answer in english"
                                        value={answer}
                                        onChange={(e) => {
                                            if (
                                                !e.target.value.match(
                                                    /[\u0900-\u097F]/
                                                ) &&
                                                e.target.value.trim()
                                            ) {
                                                setAnswer(e.target.value);
                                            } else if (e.target.value.length === 0) {
                                                setAnswer(e.target.value);
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </div>
                        }
                    </Tab.Pane>
                    <Tab.Pane eventKey="hn" className="mt-3" >
                        {
                            key === "hn" &&
                            <div data-aos="fade-up">
                                <Form.Group className="mb-3">
                                    <Form.Label>Question in Hindi<span>*</span></Form.Label>
                                    <Form.Control
                                        placeholder="Enter Question in hindi"
                                        value={questionhin}
                                        onChange={(e) => {
                                            if (
                                                !e.target.value.match(
                                                    /[\u0900-\u097F]/
                                                ) &&
                                                e.target.value.trim()
                                            ) {
                                                setQuestionHin(e.target.value);
                                            } else if (e.target.value.length === 0) {
                                                setQuestionHin(e.target.value);
                                            }
                                        }}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-5">
                                    <Form.Label>Answer in Hindi<span>*</span></Form.Label>
                                    <Form.Control
                                        placeholder="Enter Answer in hindi"
                                        value={answerhin}
                                        onChange={(e) => {
                                            if (
                                                !e.target.value.match(
                                                    /[\u0900-\u097F]/
                                                ) &&
                                                e.target.value.trim()
                                            ) {
                                                setAnswerHin(e.target.value);
                                            } else if (e.target.value.length === 0) {
                                                setAnswerHin(e.target.value);
                                            }
                                        }}
                                    />
                                </Form.Group>
                            </div>
                        }
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default QueAnsTab