import React, { useEffect, useState } from "react";
import * as Icon from "react-bootstrap-icons";
import { useSpeechSynthesis } from "react-speech-kit";

const TextToSpeech = ({ htmlContent, language }) => {
    const { speak, voices, cancel } = useSpeechSynthesis();
    const [isSpeaking, setIsSpeaking] = useState(false);

    useEffect(() => {
        return () => {
            cancel();
        };
    }, []);

    const handleSpeak = () => {
        const tempElement = document.createElement('div');
        tempElement.innerHTML = htmlContent;
        const textContent = tempElement.textContent || tempElement.innerText || '';

        const languageVoices = voices.filter(
            (voice) =>
                // (language === 'en' && voice.lang === 'en-US') ||
                (language === 'mr' && (voice.lang === 'hi-IN' || voice.lang === 'mr-IN'))
        );

        if (languageVoices.length > 0) {
            if (isSpeaking) {
                cancel();
                setIsSpeaking(false);
            } else {
                speak({ text: textContent, voice: languageVoices[0] });
                setIsSpeaking(true);
            }
        } else {
            console.warn('No voice found for the selected language.');
        }
    };

    const handleIconClick = () => {
        if (language === 'mr') {
            // Stop speaking English if it's speaking
            cancel();
            setIsSpeaking(false);
        }
        handleSpeak();
    };

    return (
        <Icon.VolumeDownFill
            className={`ms-2 ${isSpeaking ? 'text-primary' : 'text-black'}`}
            size={30}
            style={{ cursor: 'pointer' }}
            onClick={handleIconClick}
        />
    );
};

export default TextToSpeech;





// import React, { useEffect, useState } from "react";
// import * as Icon from "react-bootstrap-icons";
// import { useSpeechSynthesis } from "react-speech-kit";

// const TextToSpeech = ({ htmlContent, language }) => {
//     const { speak, voices, cancel } = useSpeechSynthesis();
//     const [isSpeakingEnglish, setIsSpeakingEnglish] = useState(false);
//     const [isSpeakingMarathi, setIsSpeakingMarathi] = useState(false);


//     useEffect(() => {
//         return () => {
//             cancel();
//         };
//     }, []);

//     const handleSpeak = () => {
//         const tempElement = document.createElement('div');
//         tempElement.innerHTML = htmlContent;
//         const textContent = tempElement.textContent || tempElement.innerText || '';

//         const languageVoices = voices.filter(
//             (voice) =>
//                 (language === 'en' && voice.lang === 'en-US') ||
//                 (language === 'mr' && (voice.lang === 'hi-IN' || voice.lang === 'mr-IN'))
//         );

//         if (languageVoices.length > 0) {
//             if (language === 'en') {
//                 if (isSpeakingEnglish) {
//                     cancel();
//                     setIsSpeakingEnglish(false);
//                 } else {
//                     speak({ text: textContent, voice: languageVoices[0] });
//                     setIsSpeakingEnglish(true);
//                 }
//             } else if (language === 'mr') {
//                 if (isSpeakingMarathi) {
//                     cancel();
//                     setIsSpeakingMarathi(false);
//                 } else {
//                     speak({ text: textContent, voice: languageVoices[0] });
//                     setIsSpeakingMarathi(true);
//                 }
//             }
//         } else {
//             console.warn('No voice found for the selected language.');
//         }
//     };
//     return (
//         <Icon.VolumeDownFill
//             className={`ms-2 ${isSpeakingMarathi ? 'text-primary' : isSpeakingEnglish ? 'text-primary' : 'text-black'}`}
//             // className={`ms-2 ${isSpeaking ? 'text-primary' : 'text-black'}`}
//             size={30}
//             style={{ cursor: 'pointer' }}
//             onClick={handleSpeak}
//         />
//     );
// };

// export default TextToSpeech;

