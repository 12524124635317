import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "./Features/Common/ScrollToTop";
import Profile from "./Features/Common/Profile";
import PageNotFound from "./Features/Common/PageNotFound";
import CloudeFlareVideoUpload from "./Utils/CloudeFlareVideoUpload";
import Login from "./Features/Auth/Login";
import Home from "./Features/Common/Home";
import PrivacyPolicy from "./Features/Common/PrivacyPolicy";
import HelpAndSupport from "./Features/Admin/HelpAndSupport";
import Priority from "./Features/Admin/Priority/Priority";
import Transactions from "./Features/Admin/Transactions/Transactions";
import AuditLog from "./Analytics/AuditLog";

import ManageUsers from "./Features/Admin/User/ManageUsers";
import ViewUser from "./Features/Admin/User/ViewUser";

import AddActivity from "./Features/Admin/Activity/AddActivity";
import EditActivity from "./Features/Admin/Activity/EditActivity";
import ViewActivities from "./Features/Admin/Activity/ViewActivities";
import Activities from "./Features/Admin/Activity/Activities";

import AddShoppee from "./Features/Admin/Product/AddShoppee";
import EditShoppee from "./Features/Admin/Product/EditShoppee";
import ViewShopee from "./Features/Admin/Product/ViewShopee";
import Shoppee from "./Features/Admin/Product/Shoppee";

import AddArticle from "./Features/Admin/Article/AddArticle";
import EditArticle from "./Features/Admin/Article/EditArticle";
import ViewArticle from "./Features/Admin/Article/ViewArticle";
import ArticleList from "./Features/Admin/Article/ArticleList";

import AddEvent from "./Features/Admin/Event/AddEvent";
import EditEvent from "./Features/Admin/Event/EditEvent";
import ViewEventList from "./Features/Admin/Event/ViewEventList";
import EventList from "./Features/Admin/Event/EventList";

import Tags from "./Features/Admin/Tags/Tags";
import ViewTagList from "./Features/Admin/Tags/ViewTagList";

import AddCategory from "./Features/Admin/Category/AddCategory";
import EditCategory from "./Features/Admin/Category/EditCategory";
import ViewCategory from "./Features/Admin/Category/ViewCategory";
import Category from "./Features/Admin/Category/Category";

import AddCourse from "./Features/Admin/Course/AddCourse";
import EditCourse from "./Features/Admin/Course/EditCourse";
import Course from "./Features/Admin/Course/Course";

import AddLesson from "./Features/Admin/Course/AddLesson";
import EditCourseLesson from "./Features/Admin/Course/EditCourseLesson";
import CourseLesson from "./Features/Admin/Course/CourseLesson";

import AddTopic from "./Features/Admin/Course/AddTopic";
import EditTopic from "./Features/Admin/Course/EditTopic";
import ViewCourseTopic from "./Features/Admin/Course/ViewCourseTopic";

import AddTask from "./Features/Admin/Course/AddTask";
import EditTask from "./Features/Admin/Course/EditTask";
import ViewCoursetask from "./Features/Admin/Course/ViewCourseTask";
import TopicAndTaskList from "./Features/Admin/Course/TopicAndTaskList";

import AddVideo from "./Features/Admin/Video/AddVideo";
import EditVideo from "./Features/Admin/Video/EditVideo";
import ViewVideo from "./Features/Admin/Video/ViewVideo";
import Video from "./Features/Admin/Video/Video";

import AddTeacher from "./Features/Admin/Teacher/AddTeacher";
import EditTeacher from "./Features/Admin/Teacher/EditTeacher";
import ViewTeacher from "./Features/Admin/Teacher/ViewTeacher";
import Teachers from "./Features/Admin/Teacher/Teachers";

import Panchang from "./Features/Admin/Panchang/Panchang";
import EditPanchang from "./Features/Admin/Panchang/EditPanchang";

import GuruAndQuotes from "./Features/Admin/GuruAndQuotes/GuruAndQuotes";

import SubscriptionUnsubscription from "./Features/Admin/Subscription/SubscriptionUnsubscription";
import ViewNotSubscriptionUser from "./Features/Admin/Subscription/ViewNotSubscriptionUser";
import ViewSubscriptionUser from "./Features/Admin/Subscription/ViewSubscriptionUser";

import PrivateCommonLayout from "./Features/Common/Reuse/Hoc/PrivateCommonLayout";
import PrivateRoutes from "./Features/Auth/PrivateRoutes";
import ViewFAQList from "./Features/Admin/FAQ/ViewFAQList";

import AgnihotraList from "./Features/Admin/Agnihotra/AgnihotraList";
import ViewAgnihotra from "./Features/Admin/Agnihotra/ViewAgnihotra";
import AddAgnihotra from "./Features/Admin/Agnihotra/AddAgnihotra";
import EditAgnihotra from "./Features/Admin/Agnihotra/EditAgnihotra";
import PageList from "./Features/Admin/Page/PageList";
import AddPage from "./Features/Admin/Page/AddPage";
import ViewPage from "./Features/Admin/Page/ViewPage";
import EditPage from "./Features/Admin/Page/EditPage";
import NotificationHistory from "./Features/Admin/Notification/NotificationHistory";
import Notification from "./Features/Admin/Notification/Notification";
import HomeBannerList from "./Features/Admin/HomeBanner/HomeBannerList";
import AddHomeBanner from "./Features/Admin/HomeBanner/AddHomeBanner";
import EditHomeBanner from "./Features/Admin/HomeBanner/EditHomeBanner";
import ViewHomeBanner from "./Features/Admin/HomeBanner/ViewHomeBanner";
import Mood from "./Features/Admin/Mood/Mood";
import AddMood from "./Features/Admin/Mood/AddMood";
import ViewMood from "./Features/Admin/Mood/ViewMood";
import EditMood from "./Features/Admin/Mood/EditMood";

function App() {
  const userState = useSelector((state) => state?.cmsLogin?.userData);
  const roles = ['admin', 'superadmin'];
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer />
        <ScrollToTop />
        <Routes>
          {/* for text to speech demo end */}
          <Route path="/" element={<Login />} />
          <Route path="/video-upload" element={<CloudeFlareVideoUpload />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path="*" element={<PrivateRoutes><PageNotFound /></PrivateRoutes>} />

          <Route path='/dashboard' element={<PrivateCommonLayout />}>
            {roles.includes(userState?.data?.user?.userType) &&
              <>
                <Route path='/dashboard' element={<Home />} />
                <Route path='/dashboard/priority' element={<Priority />} />

                {/* Home Banner */}
                <Route path='/dashboard/homebanner' element={<HomeBannerList />} />
                <Route path='/dashboard/add-homebanner' element={<AddHomeBanner />} />
                <Route path='/dashboard/edit-homebanner' element={<EditHomeBanner />} />
                <Route path="/dashboard/view-homebanner" element={<ViewHomeBanner />} />


                {/* Agnihotra */}
                <Route path="/dashboard/agnihotra" element={<AgnihotraList />} />
                <Route path="/dashboard/view-agnihotra" element={<ViewAgnihotra />} />
                <Route path="/dashboard/add-agnihotra" element={<AddAgnihotra />} />
                <Route path="/dashboard/edit-agnihotra" element={<EditAgnihotra />} />

                {/* Article */}
                <Route path="/dashboard/articleList" element={<ArticleList />} />
                <Route path="/dashboard/view-article" element={<ViewArticle />} />
                <Route path="/dashboard/add-article" element={<AddArticle />} />
                <Route path="/dashboard/edit-article" element={<EditArticle />} />

                {/* Event */}
                <Route path="/dashboard/eventList" element={<EventList />} />
                <Route path="/dashboard/add-event" element={<AddEvent />} />
                <Route path="/dashboard/edit-event" element={<EditEvent />} />
                <Route path="/dashboard/view-event" element={<ViewEventList />} />

                {/* Help and Support */}
                <Route path="/dashboard/help-and-support" element={<HelpAndSupport />} />

                {/* Activity */}
                <Route path="/dashboard/activities" element={<Activities />} />
                <Route path="/dashboard/add-activity" element={<AddActivity />} />
                <Route path="/dashboard/edit-activity" element={<EditActivity />} />
                <Route path="/dashboard/view-activities" element={<ViewActivities />} />

                {/* Shoppee / Product */}
                <Route path="/dashboard/shoppee" element={<Shoppee />} />
                <Route path="/dashboard/view-shoppee" element={<ViewShopee />} />
                <Route path="/dashboard/add-shoppee" element={<AddShoppee />} />
                <Route path="/dashboard/edit-shoppee" element={<EditShoppee />} />

                {/* Profile */}
                <Route path='/dashboard/profile' element={<Profile />} />

                {/* Category */}
                <Route path='/dashboard/category' element={<Category />} />
                <Route path='/dashboard/add-category' element={<AddCategory />} />
                <Route path='/dashboard/view-category' element={<ViewCategory />} />
                <Route path='/dashboard/edit-category' element={<EditCategory />} />

                {/* Page */}
                <Route path='/dashboard/page' element={<PageList />} />
                <Route path='/dashboard/add-page' element={<AddPage />} />
                <Route path='/dashboard/view-page' element={<ViewPage />} />
                <Route path='/dashboard/edit-page' element={<EditPage />} />

                {/* Video */}
                <Route path="/dashboard/content-list" element={<Video />} />
                <Route path="/dashboard/add-content" element={<AddVideo />} />
                <Route path="/dashboard/viewcontentlist" element={<ViewVideo />} />
                <Route path="/dashboard/editcontentlist" element={<EditVideo />} />

                {/* Tags */}
                <Route path="/dashboard/tags" element={<Tags />} />
                <Route path="/dashboard/viewtaglist" element={<ViewTagList />} />

                {/* Teacher */}
                <Route path="/dashboard/teacher" element={<Teachers />} />
                <Route path="/dashboard/view-teacher" element={<ViewTeacher />} />
                <Route path="/dashboard/add-teacher" element={<AddTeacher />} />
                <Route path="/dashboard/edit-teacher" element={<EditTeacher />} />

                {/* Guru Quote */}
                <Route path="/dashboard/guru-and-quote" element={<GuruAndQuotes />} />

                {/* Course */}
                <Route path="/dashboard/course" element={<Course />} />
                <Route path="/dashboard/Addcourse" element={<AddCourse />} />
                <Route path="/dashboard/editcourse" element={<EditCourse />} />

                {/* Course Lesson */}
                <Route path="/dashboard/courselesson" element={<CourseLesson />} />
                <Route path="/dashboard/addlesson" element={<AddLesson />} />
                <Route path="/dashboard/editlesson" element={<EditCourseLesson />} />

                {/* Topic */}
                <Route path="/dashboard/addtopic" element={<AddTopic />} />
                <Route path="/dashboard/edittopic" element={<EditTopic />} />
                <Route path="/dashboard/viewcoursetopic" element={<ViewCourseTopic />} />
                <Route path="/dashboard/coursetopic" element={<TopicAndTaskList />} />

                {/* Task */}
                <Route path="/dashboard/viewcoursetask" element={<ViewCoursetask />} />
                <Route path="/dashboard/addTask" element={<AddTask />} />
                <Route path="/dashboard/edittask" element={<EditTask />} />

                {/* Notification */}
                <Route path="/dashboard/notification" element={<Notification />} />
                <Route path="/dashboard/notificationhistory" element={<NotificationHistory />} />

                {/* Panchang */}
                <Route path="/dashboard/panchang" element={<Panchang />} />
                <Route path="/dashboard/editpanchang" element={<EditPanchang />} />

                {/* Faq */}
                <Route path="/dashboard/faq" element={<ViewFAQList />} />

                {/* mood */}
                <Route path='/dashboard/mood' element={<Mood />} />
                <Route path='/dashboard/add-mood' element={<AddMood />} />
                <Route path='/dashboard/view-mood' element={<ViewMood />} />
                <Route path='/dashboard/edit-mood' element={<EditMood />} />

              </>
            }
            {
              userState?.data?.user?.userType === "superadmin" &&
              <>
                {/* Audit Log */}
                <Route path='/dashboard/auditlog' element={<AuditLog />} />
                {/* User Management */}
                <Route path='/dashboard/manage-users' element={<ManageUsers />} />
                <Route path='/dashboard/view-users' element={<ViewUser />} />
                {/* Transactions */}
                <Route path="/dashboard/transaction" element={<Transactions />} />
                {/* Subscription Management */}
                <Route path='/dashboard/subscription-user' element={<SubscriptionUnsubscription />} />
                <Route path='/dashboard/view-subscription-user' element={<ViewSubscriptionUser />} />
                <Route path='/dashboard/view-notsubscription-user' element={<ViewNotSubscriptionUser />} />

              </>
            }
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;





