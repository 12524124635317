import React, { useState } from "react";
import { useEffect } from "react";
import { Row, Button, Card, Col, Modal, Form, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { quillModules, quillFormats } from '../../Utils/GlobalConfigs';
import { useNavigate } from "react-router-dom";
import { setLogout } from "../Auth/LoginSlice";
import { setActiveKey } from "../Common/ActiveKeySlice";

const HelpAndSupport = () => {

  const userState = useSelector((state) => state?.cmsLogin?.userData.data);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  // get Help and Support data
  const [helpData, setHelpData] = useState({});
  const [supportData, setSupportData] = useState({});

  // Modal help state
  const [showSupport, setShowSupport] = useState(false);
  const [contact1, setContact1] = useState("");
  const [contact2, setContact2] = useState("");
  const [email1, setemail1] = useState("");
  const [email2, setemail2] = useState("");

  // Modal Support variables
  const [showHelp, setShowHelp] = useState(false);
  const [title, settitle] = useState("");
  const [description, setdescription] = useState("");
  const [loader, setLoader] = useState(false);

  //Error Variables
  const [error, setError] = useState({});

  useEffect(() => {
    getHelpAndSupport("support");
    getHelpAndSupport("help");
  }, []);


  // for get help&support data
  const getHelpAndSupport = async (getItem) => {
    try {
      const result = await fetch(process.env.REACT_APP_BASE_URL + "/helpsupport/getHelpSupport",
        {
          method: "POST",
          headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState?.token}` },
          body: JSON.stringify({
            types: getItem,
          }),
        });

      const response = await result.json();
      if (response.message === "Authorization failed / Forbidden") {
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));

        localStorage.removeItem('persist:root');
        navigate('/');
      } else if (response.status === 200) {
        if (getItem === "support") {
          setSupportData(response.data[0]);
        } else if (getItem === "help") {
          setHelpData(response.data[0]);
        }
      }
      else {
        toast.error("fetch to failed to data");
      }
    }
    catch (Err) {
      console.log("Err while getting  help and support : ", Err);

    }
  };

  const handleClick = (phoneNumber) => {
    window.open(`tel:+91${phoneNumber}`);
  };

  const closeSupportModal = () => {
    setShowSupport(false);
    setContact1("");
    setContact2("");
    setemail1("");
    setemail2("");
    setError({})

  };

  const closeHelpModal = () => {
    setShowHelp(false);
    settitle("");
    setdescription("");
    setError({})
  };


  // for update support data
  const updateSupport = async () => {
    const isValid = supportValidate();
    if (!isValid) return;

    setLoader(true);
    let payload = {
      helpsupport_id: supportData?._id,
      supportContact: contact1,
      supportContact2: contact2,
      supportEmail: email1,
      supportEmail2: email2,
      updatedBy: "Admin",
    };
    try {
      const res = await fetch(process.env.REACT_APP_BASE_URL + "/helpsupport/updateSupport", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify(payload),
      });
      const response = await res.json();
      if (response.status === 200) {
        getHelpAndSupport("support");
        closeSupportModal();
        setLoader(false);
      } else {
        closeSupportModal();
        setLoader(false);
      }
    } catch (Err) {
      console.log("Err while updating supoort", Err);
      toast.error("Error updating support, please try again");
      setLoader(false);
    }
  }


  const ShowSupportModal = () => {
    setShowSupport(true);
    setContact1(supportData?.supportContact);
    setContact2(supportData?.supportContact2);
    setemail1(supportData?.supportEmail);
    setemail2(supportData?.supportEmail2);
  };

  const ShowHelpModal = () => {
    setShowHelp(true);
    settitle(helpData?.helpTitle);
    setdescription(helpData?.helpDescription);
  };


  // for update help data
  const updateHelp = async () => {
    const isValid = helpValidate();
    if (!isValid) return;

    setLoader(true);
    let payload = {
      helpsupport_id: helpData?._id,
      helpTitle: title,
      helpDescription: description,
      updatedBy: "Admin",
    };

    try {
      const res = await fetch(process.env.REACT_APP_BASE_URL + "/helpsupport/updateHelp", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify(payload),
      })
      const response = await res.json();
      if (response.status === 200) {
        getHelpAndSupport("help");
        closeHelpModal();
        setLoader(false);
      } else {
        setLoader(false);
        closeHelpModal();
      }
    } catch (Err) {
      console.log("Err while updating help", Err);
      setLoader(false);
    }
  }

  // for support validation
  const supportValidate = () => {
    let newErrors = {};

    if (!contact1) {
      newErrors.contact1 = "*Support Contact 1 is Required";
    }

    if (!contact2) {
      newErrors.contact2 = "*Support Contact 2 is Required";
    }

    if (!email1) {
      newErrors.email1 = "*Support Email 1 is Required";
    }

    if (!email2) {
      newErrors.email2 = "*Support Email 2 is Required";
    }

    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  // for help validation
  const helpValidate = () => {
    let newErrors = {};

    if (!title) {
      newErrors.title = "*Help Title is Required";
    }

    if (!description || description === '<p><br></p>') {
      newErrors.description = "*Help Description is Required";
    }

    setError(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <div className="outletPadding">
      <ToastContainer />
      <Row className="justify-content-end">
        <Col md={6} className="d-flex justify-content-end my-auto mt-3 mt-md-0">
          <Button className="primaryBtn m-2" onClick={() => ShowSupportModal()}>
            <Icon.PlusCircle className="me-2" size={17} />
            Update Support
          </Button>

          <Button className="primaryBtn m-2" onClick={() => ShowHelpModal()}>
            <Icon.PlusCircle className="me-2" size={17} />
            Update Help
          </Button>
        </Col>
      </Row>
      <Row className="d-flex justify-content-evenly mb-5">
        <h4>Support Members : </h4>

        <Card style={{ width: "25rem" }}>
          <Icon.FileEarmarkPerson size={30} className="m-2" />
          <Card.Body>
            <Card.Title>Support Person 1</Card.Title>
            <Card.Text>Email Id : {supportData?.supportEmail}</Card.Text>
            <Card.Text>Contact : +91{supportData?.supportContact}</Card.Text>
            <Button
              variant="success"
              onClick={() => handleClick(supportData?.supportContact)}
            >
              <Icon.TelephoneOutboundFill className="me-2" />
              Call +91{supportData?.supportContact}
            </Button>
          </Card.Body>
        </Card>
        <Card style={{ width: "25rem" }}>
          <Icon.FileEarmarkPerson size={30} className="m-2" />
          <Card.Body>
            <Card.Title>Support Person 2</Card.Title>
            <Card.Text>Email Id : {supportData?.supportEmail2}</Card.Text>
            <Card.Text>Contact : +91{supportData?.supportContact2}</Card.Text>
            <Button
              variant="success"
              onClick={() => handleClick(supportData?.supportContact2)}
            >
              <Icon.TelephoneOutboundFill className="me-2" />
              Call +91{supportData?.supportContact2}
            </Button>
          </Card.Body>
        </Card>
      </Row>
      <Row className="d-flex justify-content-center align-items-center">
        <h4>Help Details : </h4>
        <Card style={{ width: "90%" }} className="text-center">
          <Card.Body>
            <Card.Title>{helpData?.helpTitle}</Card.Title>
            <Card.Text
              dangerouslySetInnerHTML={{ __html: helpData?.helpDescription }}
            ></Card.Text>
          </Card.Body>
        </Card>
      </Row>

      {/* Support Modal */}
      <Modal
        size="md"
        show={showSupport}
        onHide={closeSupportModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          {" "}
          <h4 className="mb-0">
            <Icon.PlusCircle size={20} className="me-2" />
            Update Support Data
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Support 1 Contact<span>*</span></Form.Label>
                <Form.Control
                  placeholder="Support 1 Contact"
                  value={contact1}
                  onChange={(e) => {
                    if (
                      e.target.value.match(/^[0-9]+$/) &&
                      e.target.value.length <= 10
                    ) {
                      setContact1(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setContact1(e.target.value);
                    }
                  }}
                />
                {error.contact1 && (
                  <div className="error">{error.contact1}</div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Support 2 Contact<span>*</span></Form.Label>
                <Form.Control
                  placeholder="Support 2 Contact"
                  value={contact2}
                  onChange={(e) => {
                    if (
                      e.target.value.match(/^[0-9]+$/) &&
                      e.target.value.length <= 10
                    ) {
                      setContact2(e.target.value);
                    } else if (e.target.value.length === 0) {
                      setContact2(e.target.value);
                    }
                  }}
                />
                {error.contact2 && (
                  <div className="error">{error.contact2}</div>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Support 1 Email<span>*</span></Form.Label>
                <Form.Control
                  placeholder="Support 1 Email"
                  value={email1}
                  onChange={(e) => {
                    // if (
                    //   e.target.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) &&
                    //   e.target.value.trim()
                    // ) {
                    setemail1(e.target.value);
                    // } else if (e.target.value.length === 0) {
                    //   setemail1(e.target.value);
                    // }
                  }}
                />
                {error.email1 && (
                  <div className="error">{error.email1}</div>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Support 2 Email<span>*</span></Form.Label>
                <Form.Control
                  placeholder="Support 2 Email"
                  value={email2}
                  onChange={(e) => {
                    // if (
                    //   e.target.value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/) &&
                    //   e.target.value.trim()
                    // ) {
                    setemail2(e.target.value);
                    // } else if (e.target.value.length === 0) {
                    //   setemail2(e.target.value);
                    // }
                  }}
                />
                {error.email2 && (
                  <div className="error">{error.email2}</div>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {loader ? (
            <Spinner variant="primary" />
          ) : (
            <Button className="primaryBtn" onClick={updateSupport}>
              Update Support
            </Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* help Modal */}
      <Modal
        size="md"
        show={showHelp}
        onHide={closeHelpModal}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          {" "}
          <h4 className="mb-0">
            <Icon.PlusCircle size={20} className="me-2" />
            Update Help Details
          </h4>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Form.Group className="mb-3">
              <Form.Label>Help Title<span>*</span></Form.Label>
              <Form.Control
                placeholder="Help Title"
                value={title}
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/
                    ) &&
                    e.target.value.trim()
                  ) {
                    settitle(e.target.value);
                  } else if (e.target.value.length === 0) {
                    settitle(e.target.value);
                  }
                }}
              />
              {error.title && (
                <div className="error">{error.title}</div>
              )}
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mb-3">
              <Form.Label>Description<span>*</span></Form.Label>
              <ReactQuill
                style={{ height: 150 }}
                theme="snow"
                modules={quillModules}
                formats={quillFormats}
                value={description}
                onChange={setdescription}
              />
              <br />
              <br />
              <br />
              {error.description && (
                <div className="error">{error.description}</div>
              )}
            </Form.Group>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          {loader ? (
            <Spinner variant="primary" />
          ) : (
            <Button className="primaryBtn" onClick={updateHelp}>
              Update Support
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default HelpAndSupport;
