import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Pagination from '../Pagination'
import * as Icon from "react-bootstrap-icons"

const PaginationSequence = ({ data, pageNo, noOfPages, handlePageChange, handleDocsPerPage, docPerPage }) => {

    return (
        <>
            <Row>
                <Col md={4} className='d-flex align-items-center mt-3 mt-md-0'>
                    <h6 className='text-start mb-0'>
                        <Icon.People className='me-2' />Total - <strong>{data ? data : '0'}</strong>
                    </h6>
                </Col>
                <Col md={8} className='d-flex justify-content-end'>
                    <Pagination currentPage={pageNo} totalPages={noOfPages} onPageChange={handlePageChange}
                        onDocsPerPage={handleDocsPerPage} docsPerPage={docPerPage} />
                </Col>
            </Row>
        </>
    )
}

export default PaginationSequence