import { combineReducers, configureStore } from '@reduxjs/toolkit';
import LoginReducer from '../Features/Auth/LoginSlice';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import sidebarReducer from '../Features/Common/ActiveKeySlice';
import getAllCategoriesReducer from "../Features/Common/Categories"

const reducers = combineReducers({
  cmsLogin: LoginReducer,
  cmsActiveData: sidebarReducer,
  categories: getAllCategoriesReducer 
})

const rootReducer = (state, action) => {
  if (action.type === "userData/setLogout") {
    storage.removeItem('persist:root')
    localStorage.clear();
    return reducers(undefined, action)
  }

  return reducers(state, action);
}

const persistConfig = {
  key: 'root',
  storage 
}

// for empty state after logout
// const rootReducer = (state,action) =>{
//   if(action.type === "userData/setLogout"){
//     state = undefined
//   }
//   return reducers(state,action)
// }

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
