import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

const ViewMood = () => {
  const location = useLocation();
  const navigate = useNavigate()

  const moodData = location.state.mood;

  return (
    <div className="outletPadding">
      <Container>
        <Button className="backBtn" onClick={() => navigate(-1)}>
          <Icon.ArrowLeft className="me-2" />Back
        </Button>
        <Row className="mb-2 mt-4">
          <h4 className="mb-0 text-center">
            <Icon.Grid className="me-1" /> Mood Details
          </h4>
        </Row>
        <Row className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper">
            <table data-aos="fade-up" data-aos-delay="200">
              <tbody>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Mood Image
                  </th>
                  <td><img src={moodData.moodImgURL} alt="" width={40} /></td>
                </tr>


                <tr>
                  <th >
                    <Icon.Code className="me-2" />
                    Mood Name English
                  </th>
                  <td>{moodData?.moodTitleLang.en ? moodData?.moodTitleLang.en : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Mood Name Marathi
                  </th>
                  <td>{moodData?.moodTitleLang.mr ? moodData?.moodTitleLang.mr : "-"}</td>
                </tr>
                <tr className="off">
                  <th >
                    <Icon.Code className="me-2" />
                    Mood Name Hindi
                  </th>
                  <td>{moodData?.moodTitleLang.hin ? moodData?.moodTitleLang.hin : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Priority
                  </th>
                  <td>{moodData?.priorityNo ? moodData?.priorityNo : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in English
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {moodData?.moodMessageLang?.en ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: moodData?.moodMessageLang?.en,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}

                  </td>
                </tr>


                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in Marathi
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {moodData?.moodMessageLang?.mr ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: moodData?.moodMessageLang?.mr,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}

                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in Hindi
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {moodData?.moodMessageLang?.hin ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: moodData?.moodMessageLang?.hin,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ViewMood;
