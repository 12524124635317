import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import noimg from "../../../Assets/noimg.svg";
import { categoryTypes } from '../../../Utils/GlobalConfigs';

const Priority = () => {

  const userState = useSelector((state) => state?.cmsLogin?.userData?.data);

  // for loader
  const [loader, setLoader] = useState(false);

  const [data, setData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [selected, setSelected] = useState('');
  const [priorityOptions, setPriorityOptions] = useState([]);
  const [priorityNo, setPriorityNo] = useState('');
  const [category, setCategory] = useState("")
  const [error, setErrors] = useState('');

  const [rightActive, setRightActive] = useState();
  const [leftActive, setLeftActive] = useState();

  const [activeTab, setActiveTab] = useState('Product');
  const ref = useRef(null);

  const scroll = (scrollOffset, direction) => {

    if (direction === "left") {
      ref.current.scrollLeft -= scrollOffset;
    }
    if (direction === "right") {
      ref.current.scrollLeft += scrollOffset;
    }

    if (ref.current.scrollLeft >= 4) {
      setLeftActive(true);
    }
    else {
      setLeftActive(false);
    }

    let maxScrollValue = ref.current.scrollWidth - ref.current.clientWidth - 20;
    if (ref.current.scrollLeft >= maxScrollValue) {
      setRightActive(true);
    }
    else {
      setRightActive(false);
    }
  }

  const priorityFields = [
    "Product", "Article", "Event", "Course", "Video", "Category"
  ]

  useEffect(() => {
    getPriorityData();
    getPriority();
  }, [activeTab, category])

  // Reset category when tab changes
  useEffect(() => {
    setCategory("");
  }, [activeTab]);


  const getPriorityData = async () => {
    setLoader(true);
    try {
      const result = await fetch(process.env.REACT_APP_BASE_URL + '/priority/titleFilter', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userState?.token}`
        },
        body: JSON.stringify({
          type: activeTab,
          title: '',
          categoryType: category
        })
      })
      const response = await result.json();
      setData(response);
      setLoader(false);
    } catch (Err) {
      console.log("Err while getting  priority data", Err);
      setLoader(false);
    }
  }

  const getPriority = async () => {
    try {
      const result = await fetch(process.env.REACT_APP_BASE_URL + '/priority/getPriority', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${userState?.token}`
        },
        body: JSON.stringify({
          type: activeTab,
          categoryType: category
        })
      })
      const response = await result.json();
      setPriorityOptions(response?.data);
    } catch (Err) {
      console.log("Err while getting  priority data", Err);
    }
  }

  const getSelectedData = (activeTab, itm) => {
    switch (activeTab) {
      case 'Product':
        return { imageURL: itm.imageURL, title: itm.nameLang?.en };
      case 'Article':
        return { imageURL: itm.imageURLLang?.en, title: itm.title };
      case 'Event':
        return { imageURL: itm.event_images, title: itm.event_title };
      case 'Course':
        return { imageURL: itm.imageURL, title: itm.title };
      case 'Video':
        return { imageURL: itm.imgURL, title: itm.title };
      case 'Category':
        return { imageURL: itm?.imgURL || itm?.iconURL, title: itm.categoryName };
      default:
        return null;
    }
  };

  const sliceString = (str) => {
    if (!str) return "-";
    return str.length > 15 ? str.slice(0, 15) + "..." : str;
  };

  const getSelectedId = (activeTab, itm) => {
    switch (activeTab) {
      case 'Product':
        return itm.shopeeId;
      case 'Article':
        return itm.articleId;
      case 'Event':
        return itm.event_id;
      case 'Course':
        return itm.courseId;
      case 'Video':
        return itm.contentId;
      case 'Category':
        return itm.categoryId;
      default:
        return null;
    }
  };

  const handleChange = (itm) => {
    const selectedId = getSelectedId(activeTab, itm);
    setSelected(prevSelected => prevSelected === selectedId ? '' : selectedId);
  };

  const setValidate = () => {
    let newErrors = {};

    if (!priorityNo) {
      newErrors.priorityNo = "Please select priority";
    }

    else if (!selected) {
      newErrors.selected = "Please select any one of above"
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  const setPriority = async () => {
    if (setValidate()) {
      try {
        setLoader(true);
        const res = await fetch(process.env.REACT_APP_BASE_URL + '/priority/setPriority', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userState?.token}`
          },
          body: JSON.stringify({
            type: activeTab,
            priorityNo: priorityNo,
            articleId: activeTab === 'Article' ? selected : "",
            courseId: activeTab === 'Course' ? selected : "",
            contentId: activeTab === 'Video' ? selected : "",
            shopeeId: activeTab === 'Product' ? selected : "",
            event_id: activeTab === 'Event' ? selected : "",
            categoryId: activeTab === 'Category' ? selected : ""
          })
        })
        const response = await res.json();
        if (response?.message === "Priority Updated") {
          toast.success('Priority Set Successfully !');
          getPriority();
          getPriorityData();
          setPriorityNo('');
          setSelected('');
          setLoader(false);
        }
        else if (response?.message === "Data Not Found") {
          toast.error("Failed to set, please try again");
          setPriorityNo('');
          setSelected('');
          setLoader(false);
        }
      } catch (Err) {
        console.log("Err while setting priority", Err);
        setLoader(false);
      }
    }
  }

  useEffect(() => {
    if (data?.data) {
      const sorted = data?.data?.sort((a, b) => {
        if (a.priorityNo && b.priorityNo) {
          return a.priorityNo - b.priorityNo; // Sort by priorityNo
        } else if (a.priorityNo) {
          return -1; // a comes before b (a has priorityNo, b doesn't)
        } else if (b.priorityNo) {
          return 1; // b comes before a (b has priorityNo, a doesn't)
        } else {
          return 0; // both have no priorityNo, maintain original order
        }
      });
      setSortedData(sorted);
    }
  }, [data]);


  return (
    <div className='Priority outletPadding'>
      <Container className='pt-3'>

        <Row className='justify-content-center align-items-center'>
          <Col md={5}>
            <div className='scrollable-tabs-container'>
              <div className={leftActive ? 'left-arrow active' : 'left-arrow'} >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" onClick={() => scroll(300, "left")}>
                  <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
              </div>

              <ul ref={ref}>
                {
                  priorityFields.map((row, index) => {
                    return (
                      <li key={index + 1} className={activeTab === row ? 'active' : ''}
                        onClick={() => { setActiveTab(row); setPriorityNo('') }}>{row}</li>
                    )
                  })
                }
              </ul>

              <div className={rightActive ? 'right-arrow' : 'right-arrow active'}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6" onClick={() => scroll(300, "right")}>
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </div>
            </div>
          </Col>

          {activeTab === "Category" &&
            <Col md={2}>
              <Form.Group className="mt-3 mt-md-0 px-5 px-md-2">
                <Form.Select
                  value={category}
                  onChange={(e) => { setCategory(e.target.value) }}>
                  <option value="" selected>Select Category type</option>
                  {categoryTypes.map((itm) => (
                    <option key={itm.value} value={itm.value}>{itm.label}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          }

          <Col md={2} className='mt-3 mt-md-0 px-5 px-md-2'>
            <Form.Select value={priorityNo} onChange={(e) => setPriorityNo(e.target.value)}
              disabled={activeTab === "Category" && !category}
            >
              <option value="" selected disabled>Select priority</option>
              {
                priorityOptions?.length > 0 ? priorityOptions?.map((itm) => {
                  return (<option value={itm}>{itm}</option>)
                }) : <option value="" disabled>Not Found</option>
              }
            </Form.Select>
            {error.priorityNo && <p className="errMsg">{error.priorityNo}</p>}
          </Col>
        </Row>


        {
          loader ? (<center><h5 className='my-5'>Loading...</h5></center>) :
            activeTab === 'Category' && !category ? (
              <center><h5 className='my-5'>Please select category type.</h5></center>
            ) :
              <>
                <div className="priorityCards mt-5 pb-4">
                  {/* <p className='hint text-left'><Icon.InfoCircleFill className='me-1'/>Click on any card to select</p> */}
                  <Row className='px-3'>
                    {
                      sortedData !== null && sortedData !== undefined && sortedData.length > 0 ?
                        sortedData?.map((itm, index) => {
                          const { priorityNo } = itm;
                          const { imageURL, title } = getSelectedData(activeTab, itm);
                          return (
                            <Col md={4} lg={2} key={index} className='mb-4' >
                              <label htmlFor={index}>
                                <Card className='h-100'>
                                  {priorityNo ? <h6 className='priority'>{priorityNo}</h6> : null}
                                  <input type="checkbox" name="checkbox" checked={selected === getSelectedId(activeTab, itm)}
                                    id={index} value={selected} onChange={() => handleChange(itm)} />
                                  <img src={imageURL ? imageURL : noimg} alt='' className='card-img-top' />
                                  <Card.Body>
                                    <p className='mb-0'>{sliceString(title)}</p>
                                  </Card.Body>
                                </Card>
                              </label>
                            </Col>
                          )
                        }) : <p>No Data Found</p>
                    }
                  </Row>
                </div>
                {error.selected && <p className="errMsg text-center mt-3">{error.selected}</p>}
                {
                  loader ? <center><Spinner variant='primary' /></center> :
                    <Button className='primaryBtn w-auto mx-auto my-4' onClick={setPriority}>
                      <Icon.BorderWidth className='me-2' />Set Priority
                    </Button>
                }
              </>
        }


      </Container>
    </div>
  )
}

export default Priority;