import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";

const ViewPage = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const PageData = location.state.page;

  return (
    <div className="outletPadding">
      <Container>
        <Button className="backBtn" onClick={() => navigate(-1)}>
          <Icon.ArrowLeft className="me-2" />Back
        </Button>
        <Row className="mb-2 mt-4">
          <h4 className="mb-0 text-center">
            <Icon.Grid className="me-1" /> Page Details
          </h4>
        </Row>
        <Row className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper">
            <table data-aos="fade-up" data-aos-delay="200">
              <tbody>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Page Icon
                  </th>
                  <td><img src={PageData.iconURL} alt="" width={40} /></td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Page Image
                  </th>
                  <td><img src={PageData.imgURL ? PageData.imgURL : '-'} alt="" width={40} /></td>
                </tr>
                <tr>
                  <th >
                    <Icon.Code className="me-2" />
                    Page Name English
                  </th>
                  <td>{PageData?.pageNameLang.en ? PageData?.pageNameLang.en : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Page Name Marathi
                  </th>
                  <td>{PageData?.pageNameLang.mr ? PageData?.pageNameLang.mr : "-"}</td>
                </tr>
                <tr className="off">
                  <th >
                    <Icon.Code className="me-2" />
                    Page Name Hindi
                  </th>
                  <td>{PageData?.pageNameLang.hin ? PageData?.pageNameLang.hin : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Page Type
                  </th>
                  <td>{PageData?.pageType ? PageData?.pageType : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Priority No
                  </th>
                  <td>{PageData?.priorityNo ? PageData?.priorityNo : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Header Color
                  </th>
                  <td>{PageData?.headerColor ? PageData?.headerColor : "-"}</td>
                </tr>
                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Background Color
                  </th>
                  <td>{PageData?.backgroundColor ? PageData?.backgroundColor : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in English
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {PageData?.shortDescriptionLang?.en ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: PageData?.shortDescriptionLang?.en,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}

                  </td>
                </tr>


                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in Marathi
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {PageData?.shortDescriptionLang?.mr ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: PageData?.shortDescriptionLang?.mr,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}

                  </td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />
                    Short Description in Hindi
                  </th>
                  <td style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'wrap' }}>
                    {PageData?.shortDescriptionLang?.hin ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: PageData?.shortDescriptionLang?.hin,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}

                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default ViewPage;
