import React, { useState } from "react";
import { Form, Tab, Nav } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "react-quill/dist/quill.snow.css";
import ShortDescription from "../Description/ShortDescription";
import FullDescription from "../Description/FullDescription";
window.Buffer = window.Buffer || require("buffer").Buffer;

const ChromeTabShortDesc = ({ shortdescriptionMr, setShortDescriptionMr, shortdescription, setShortDescription, shortdescriptionHn, setShortDescriptionHn, label, errorSign }) => {

    // for language
    const [key, setkey] = useState('mr');

    const handleTabSelect = (key) => {
        setkey(key);
    };


    // Check if Marathi descriptions are filled
    const isMarathiFilled = (
        (shortdescriptionMr?.trim() !== '' && shortdescriptionMr?.trim() !== '<p><br></p>')
    );
    // Check if English descriptions are filled
    const isEnglishFilled = (
        (shortdescription?.trim() !== '' && shortdescription?.trim() !== '<p><br></p>')
    );
    // Check if Hindi descriptions are filled
    const isHindiFilled = (
        ""
        // (shortdescriptionHn?.trim() !== '' && shortdescriptionHn?.trim() !== '<p><br></p>')
    );

    return (
        <div className="ChromeTabs">
            <Tab.Container activeKey={key} onSelect={handleTabSelect}>
                <Nav variant="tabs" className="flex-row">
                    <Nav.Item>
                        <Nav.Link eventKey="mr" className={isMarathiFilled ? "filled-tab" : ""}>
                            Marathi
                            {isMarathiFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="en" className={isEnglishFilled ? "filled-tab" : ""}>
                            English {isEnglishFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item >
                        <Nav.Link eventKey="hn" className={isHindiFilled ? "filled-tab" : ""} disabled>
                            Hindi {isHindiFilled && <Icon.CheckCircleFill className="ms-2" size={14} color="green" />}
                        </Nav.Link>
                    </Nav.Item>
                </Nav>

                <Tab.Content>
                    <Tab.Pane eventKey="mr" className="mt-3">
                        <div data-aos="fade-up">
                            <Form.Group className="mb-3">
                                <Form.Label>{label} Description in Marathi <span>*</span></Form.Label>
                                <ShortDescription
                                    value={shortdescriptionMr}
                                    onChange={(text) => setShortDescriptionMr(text)}
                                    key="mr-quill"
                                />
                            </Form.Group>

                        </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="en" className="mt-3">
                        {
                            key === "en" &&
                            <div data-aos="fade-up">
                                <Form.Group className="mb-3">
                                    <Form.Label>{label} Description in English <span>*</span></Form.Label>
                                    <ShortDescription
                                        value={shortdescription}
                                        onChange={(text) => setShortDescription(text)}
                                        key="en-quill"
                                    />
                                </Form.Group>

                            </div>
                        }
                    </Tab.Pane>
                    <Tab.Pane eventKey="hn" className="mt-3" >
                        {
                            key === "hn" &&
                            <div data-aos="fade-up">
                                <Form.Group className="mb-3">
                                    <Form.Label>{label} Description in Hindi <span>*</span></Form.Label>
                                    <ShortDescription
                                        value={shortdescriptionHn}
                                        onChange={(text) => setShortDescriptionHn(text)}
                                        key="hn-quill"
                                    />
                                </Form.Group>


                            </div>
                        }
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </div>
    )
}

export default ChromeTabShortDesc