import React, { useState } from 'react';
import { Col, Form, Row, Button, Spinner, Container, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import * as Icon from "react-bootstrap-icons";
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import ChromeTabShortDesc from '../../Common/langugageToggle/ChromeTabShortdesc';
import ButtonComponent from '../../Common/Button/ButtonComponent';
import CategoryTab from '../../Common/langugageToggle/CategoryTab';

const EditPage = () => {
  const navigate = useNavigate()
  const userState = useSelector((state) => state?.cmsLogin?.userData.data);
  const location = useLocation();
  const pageData = location?.state?.item;

  const [pageEn, setPageEn] = useState(pageData?.pageNameLang?.en || "")
  const [pageMr, setPageMr] = useState(pageData?.pageNameLang?.mr || "")
  const [pageHn, setPageHn] = useState(pageData?.pageNameLang?.hin || "")
  const [iconUrl, setIconUrl] = useState(pageData?.iconURL || "")
  const [imageurl, setImageurl] = useState(pageData?.imgURL || "")
  const [shortdescription, setShortDescription] = useState(pageData?.shortDescriptionLang?.en || "");
  const [shortdescriptionMr, setShortDescriptionMr] = useState(pageData?.shortDescriptionLang?.mr || "");
  const [shortdescriptionHn, setShortDescriptionHn] = useState(pageData?.shortDescriptionLang?.hin || "");
  const [headercolor, setHeaderColor] = useState(pageData?.headerColor);
  const [bgcolor, setBGColor] = useState(pageData?.backgroundColor);
  const [priority, setPriority] = useState(pageData?.priorityNo)
  const [loader, setLoader] = useState(false)
  const [fileEvent, setFileEvent] = useState('');
  const [fileimageEvent, setFileImageEvent] = useState('');
  const [error, setErrors] = useState({});

  const handleReactS3UltraResIcon = (url) => {
    setIconUrl(url);
  }

  const handleReactS3UltraResImage = (url) => {
    setImageurl(url)
  }


  const validate = () => {
    let newErrors = {};

    if ((!pageEn || pageEn.trim() === '<p><br></p>') ||
      (!pageMr || pageMr.trim() === '<p><br></p>')
      // ||(!pageHn || pageHn.trim() === '<p><br></p>')
    ) {
      newErrors.category = "*Page name in English and Marathi are required";
    }

    if (!iconUrl) {
      newErrors.iconUrl = "* Icon is Required"
    }

    if (!imageurl) {
      newErrors.imageurl = "* Image is Required"
    }

    if (!headercolor) {
      newErrors.headercolor = "* Please select at lease one header color"
    }

    if (!bgcolor) {
      newErrors.bgcolor = "* Please select at lease one backgorund color"
    }

    if (!priority) {
      newErrors.priority = "*Please set priority"
    }


    if ((!shortdescriptionMr || shortdescriptionMr.trim() === '<p><br></p>') ||
      (!shortdescription || shortdescription.trim() === '<p><br></p>'))
    //  ||(!shortdescriptionHn || shortdescriptionHn.trim() === '<p><br></p>') 
    {
      newErrors.description = "*short descriptions in English and Marathi are required";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addPage = async (status) => {
    const isValid = validate();
    if (!isValid) return;
    setLoader(true);
    try {
      let payload = {
        pageId: pageData?.pageId,
        pageName: pageEn,
        pageNameLang: {
          en: pageEn,
          mr: pageMr,
          hin: pageHn,
        },
        iconURL: iconUrl,
        imgURL: imageurl,
        shortDescription: shortdescription,
        shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
        backgroundColor: bgcolor,
        headerColor: headercolor,
        priorityNo: priority,
        isPublish: status
      }
      const res = await fetch(process.env.REACT_APP_BASE_URL + "/page/updatePage", {
        method: "POST",
        headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState?.token}` },
        body: JSON.stringify(payload),
      })

      const response = await res.json();
      if (response.message === "Page updated successfully") {
        toast.success("Updated Successfully !");
        navigate("/dashboard/page")
        setLoader(false);
      } else if (response.message === "you can only publish 6 pages") {
        toast.error("you can only publish 6 pages")
      } else {
        toast.error("Failed to edit page.");
        setLoader(false);
      }
    } catch (Err) {
      console.log("Err while adding page", Err);
      toast.error('Failed to create page. Please try again.');
      setLoader(false);
    } finally {
      setLoader(false);
    }
  }

  return (
    <>
      <div className="outletPadding">
        <Container>
          <Row>
            <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
              <Icon.ArrowLeft className="me-2" />Back
            </Button>
          </Row>

          <h3 className='text-center d-flex justify-content-center align-items-center'>
            <Icon.Clipboard2Plus size={20} className='me-2' />Edit Page
          </h3>
          <Row className='mt-4'>
            <CategoryTab
              categoryEn={pageEn}
              setCategoryEn={setPageEn}
              categoryMr={pageMr}
              setCategoryMr={setPageMr}
              categoryHn={pageHn}
              setCategoryHn={setPageHn}
              label="Page"
              placeholder="पृष्ठ"
            />
            {error.category && (
              <p className="errMsg">{error.category}</p>
            )}


            <Col md={6}>
              <Form.Group className="mb-3" id="img">
                <Form.Label>Page Icon<span>*</span></Form.Label> <br />
                {!iconUrl ?
                  <>
                    <label htmlFor="fileInput" id="uploadBtn">
                      <Icon.CloudArrowUp size={20} className="me-2" />Upload Icon Image
                    </label>
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/gif"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={(event) => setFileEvent(event)} />
                    <p id='imgNote'>Recommended image resolution - 1:1</p>
                    {error.iconUrl && <p className="errMsg">{error.iconUrl}</p>}
                  </>
                  :
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    <Card className="uploadedFile m-1">
                      <img src={iconUrl} style={{ width: 60, height: 60 }} alt="Uploaded URL" className='mx-auto' />
                      <Icon.XCircleFill className='removeIcon' size={18}
                        onClick={() => setIconUrl('')} />
                    </Card>
                  </div>
                }
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3" id="img">
                <Form.Label>Page Image<span>*</span></Form.Label> <br />
                {!imageurl ?
                  <>
                    <label htmlFor="fileInput" id="uploadBtn">
                      <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                    </label>
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/gif"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={(event) => setFileImageEvent(event)} />
                    <p id='imgNote'>Recommended image resolution - 1:1</p>
                    {error.imageurl && <p className="errMsg">{error.imageurl}</p>}
                  </>
                  :
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    <Card className="uploadedFile m-1">
                      <img src={imageurl} style={{ width: 60, height: 60 }} alt="Uploaded URL" className='mx-auto' />
                      <Icon.XCircleFill className='removeIcon' size={18}
                        onClick={() => setImageurl('')} />
                    </Card>
                  </div>
                }
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Priority<span>*</span></Form.Label>
                <Form.Control
                  value={priority}
                  placeholder="Enter priority"
                  onChange={(e) => {
                    if (
                      !e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/)
                    ) { setPriority(e.target.value); }
                  }}
                />

                {error.priority && <p className="error">{error.priority}</p>}
              </Form.Group>
            </Col>

            <Col md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>Select Icon Background Color<span>*</span></Form.Label>
                <Form.Control
                  type="color"
                  placeholder="Select icon background color"
                  value={bgcolor}
                  onChange={(e) => setBGColor(e.target.value)
                  }
                />
                {error.bgcolor && <p className="errMsg">{error.bgcolor}</p>}
              </Form.Group>
            </Col>



            <Col md={4}>
              <Form.Group className='mb-3'>
                <Form.Label>Select Image Header Color<span>*</span></Form.Label>
                <Form.Control
                  type="color"
                  placeholder="select image header color"
                  value={headercolor}
                  onChange={(e) => setHeaderColor(e.target.value)
                  }
                />
                {error.headercolor && <p className="errMsg">{error.headerColor}</p>}
              </Form.Group>
            </Col>

            {/* for short description  */}
            <ChromeTabShortDesc
              shortdescriptionMr={shortdescriptionMr}
              setShortDescriptionMr={setShortDescriptionMr}
              shortdescription={shortdescription}
              setShortDescription={setShortDescription}
              shortdescriptionHn={shortdescriptionHn}
              setShortDescriptionHn={setShortDescriptionHn}
              label="Short"
            />

            {error.description && (
              <p className="errMsg mt-1">{error.description}</p>
            )}
          </Row>

          <Row className='mb-5'>
            {
              loader ? <center><Spinner variant='primary' /></center> :
                <>
                  <ButtonComponent
                    onSave={addPage}
                    onSaveAndPublish={addPage}
                    saveLabel="Save"
                    savePublish="Save & Publish"
                  />
                </>

            }
          </Row>
        </Container>
        {
          fileEvent &&
          <ReactS3Ultra fileEvent={fileEvent} fileType={process.env.REACT_APP_IMAGE}
            directory="icon" onFileUpload={handleReactS3UltraResIcon} />
        }

        {
          fileimageEvent &&
          <ReactS3Ultra fileEvent={fileimageEvent} fileType={process.env.REACT_APP_IMAGE}
            directory="image" onFileUpload={handleReactS3UltraResImage} />
        }
      </div>
    </>
  )
}

export default EditPage
