import React from "react";
import { Container, Row, Button } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import moment from 'moment';

const ViewTeacher = () => {

  const location = useLocation();
  const navigate = useNavigate()
  const teacher = location.state;

  return (
    <div className='outletPadding'>
      <Container>

        <Button className="backBtn" onClick={() => navigate(-1)}>
          <Icon.ArrowLeft className="me-2" />
          Back
        </Button>
        <Row className="mb-2 mt-4">
          <h4 className="mb-0 text-center">
            <Icon.BagDash className="me-1" /> Teacher Details
          </h4>
        </Row>


        <Row className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper">
            <table data-aos="fade-up" data-aos-delay="200">
              <tbody>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />Image
                  </th>
                  <td>{<img src={teacher?.imageURL} width={50} alt="Teacher" />}</td>
                </tr>

                <tr>
                  <th> <Icon.Code className="me-2" /> Name </th>
                  <td>{teacher?.firstName + " " + teacher?.lastName}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />Qualification
                  </th>
                  <td>{teacher?.qualification ? teacher?.qualification : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />Status
                  </th>
                  <td>{teacher?.status ? teacher?.status : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />DOB
                  </th>
                  <td>{teacher?.dob ? moment(teacher?.dob).format('DD-MM-YYYY') : "-"}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />Course Categories
                  </th>
                  <td>{teacher.courseCategories?.map((row) => row).join(', ')}</td>
                </tr>

                <tr>
                  <th>
                    <Icon.Code className="me-2" />Summary
                  </th>
                  <td>
                    {teacher?.summary ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: teacher?.summary,
                        }}
                      ></div>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default ViewTeacher;