import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Spinner, Card } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { getAllCategories } from "../../Common/Categories";
import ReactS3Ultra from "../../../Utils/ReactS3Ultra";
import CloudeFlareVideoUpload from "../../../Utils/CloudeFlareVideoUpload";
import ChromeTab from "../../Common/langugageToggle/ChromeTab";
import ButtonComponent from "../../Common/Button/ButtonComponent";
import { moodOption } from "../../../Utils/GlobalConfigs";
import moment from "moment";
import useGetPageList from "../../Common/Reuse/useGetPageList";
import useGetMoodList from "../../Common/Reuse/useGetMoodList";
window.Buffer = window.Buffer || require("buffer").Buffer;

const EditVideos = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userState = useSelector((state) => state?.cmsLogin?.userData?.data?.user);
    const token = useSelector((state) => state?.cmsLogin?.userData?.data?.token);
    const getData = useSelector(state => state?.categories?.categoryList?.data)
    const location = useLocation();
    const videoData = location.state;

    // call custom hook for page title
    const { pageData } = useGetPageList();
    const { moodData } = useGetMoodList();

    const [allTeachers, setAllTeachers] = useState([]);
    const [author, setAuthor] = useState(userState?.fullName);
    //Add Content variables
    const [loader, setLoader] = useState(false);
    // Error variables
    const [error, setErrors] = useState({});

    const [titleEn, setTitleEn] = useState(videoData.titleLang.en);
    const [titleMr, setTitleMr] = useState(videoData.titleLang.mr);
    const [titleHn, setTitleHn] = useState(videoData.titleLang.hin);

    const [descriptionEn, setDescriptionEn] = useState(videoData.descriptionLang.en);
    const [descriptionMr, setDescriptionMr] = useState(videoData.descriptionLang.mr);
    const [descriptionHn, setDescriptionHn] = useState(videoData.descriptionLang.hin);
    const [shortdescription, setShortDescription] = useState(videoData?.shortDescriptionLang?.en);
    const [shortdescriptionMr, setShortDescriptionMr] = useState(videoData?.shortDescriptionLang?.mr);
    const [shortdescriptionHn, setShortDescriptionHn] = useState(videoData?.shortDescriptionLang?.hin);
    const [tags, setTags] = useState(
        videoData?.tags?.map((tag) => ({ label: tag, value: tag })) || []
    );
    const [category, setCategory] = useState({ label: videoData?.contentCategory, value: videoData?.contentCategory })
    const [selectedCategory, setSelectedCategory] = useState({
        label: videoData?.tagsCategory,
        value: videoData?.tagsCategory
    })

    const [VideoImage, setVideoImage] = useState(videoData?.imgURL || "");
    const [Video, SetVideo] = useState(videoData?.videoURL || "");
    const [fileEventVideoImg, setFileEventVideoImg] = useState('');
    const [fileEventGIF, setFileEventGIF] = useState('');
    const [videotype, setVideoType] = useState({ label: videoData?.contentType, value: videoData?.contentType })
    const [mood, setMood] = useState({ label: videoData?.moods, value: videoData?.moods })
    const [publishdate, setPublishDate] = useState((moment(videoData?.toPublishDate)).format('YYYY-MM-DD'))
    const [GIFs, setGIFs] = useState(videoData?.gifURL || "");
    const [pageTitle, setpageTitle] = useState({ label: videoData?.pageName, value: videoData?.pageId, Lang: videoData?.pageNameLang })


    // Dropdown variables
    const [TagList, setTagList] = useState(videoData?.tags || []);
    const [tagCategoryList, setTagCategoriesList] = useState([]);


    useEffect(() => {
        getTagCategoryList();
        dispatch(getAllCategories({ payload: { categoryType: "feed" }, token: token }));
        getAllTeacher();

    }, []);

    const getAllTeacher = async () => {
        try {
            const res = await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllTeacher', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
                body: JSON.stringify({
                    courseTeacherId: '',
                    courseCategories: ''
                })
            })
            const response = await res.json();
            let arr = [];
            response.data.map((item) => {
                arr.push({
                    ...item,
                    label: item.firstName + " " + item.lastName,
                    value: item.courseTeacherId,
                });
            });
            setAllTeachers(arr);
        }
        catch (Err) {
            console.log("Err while getting all teacher", Err);
        }
    }

    const getContentCategory = getData?.data?.length > 0 && getData?.data !== null && getData?.data !== undefined ? getData.data.map(itm => ({ label: itm?.categoryName, value: itm?.categoryName })) : [];

    useEffect(() => {
        if (selectedCategory !== "") {
            getTagNameList();
        }
    }, [selectedCategory]);

    const handleChange = (selected) => {
        setTags(selected);
    };


    // for tagcategorylist
    const getTagCategoryList = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + "/cms/getAllTagCat", {
            method: "GET",
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.status === 200) {
                    let arr = [];
                    response.data.map((item) => {
                        arr.push({
                            ...item,
                            label: item.tagCategoryName,
                            value: item.tagCategoryName,
                        });
                    });
                    setTagCategoriesList(arr);
                } else {
                    setTagCategoriesList([]);
                }
            }).catch((error) => {
                console.log("Error:", error)
            })
    };

    const getTagNameList = async () => {
        await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllTags2',
            {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${token}` },
                body: JSON.stringify({ tagCategory: selectedCategory?.value })
            }
        )
            .then((res) => res.json())
            .then((response) => {
                if (response?.status === 200) {
                    let arr = [];
                    response.data.map((item) => {
                        arr.push({
                            ...item,
                            label: item.tag,
                            value: item.tag,
                        });
                    });
                    setTagList(arr);
                } else {
                    setTagList([]);
                }
            }).catch((error) => {
                console.log("Error:", error)
            })
    };

    // for validation
    const validate = () => {
        let newErrors = {};

        if (!titleEn) {
            newErrors.titleEn = "*Title in english is required";
        }

        if (!titleMr) {
            newErrors.titleMr = "*मराठीत शीर्षक आवश्यक आहे";
        }

        //  if (!titleHn) {
        //     newErrors.titleHn = "*शीर्षक हिन्दी में आवश्यक है";
        // }

        // if (!pageTitle) {
        //     newErrors.pageTitle = "*Page Title is Required";
        // }

        if (!VideoImage) {
            newErrors.VideoImage = "*Please upload image";
        }

        if (!Video) {
            newErrors.Video = "*Please upload video";
        }

        if (!category) {
            newErrors.category = "*Please select content category";
        }

        if (!selectedCategory) {
            newErrors.selectedCategory = "*Please Select Tag category";
        }

        if (tags.length === 0) {
            newErrors.tags = "*At least Select one Tag for Content";
        }

        if (!mood) {
            newErrors.mood = "*Required Field";
        }

        if (!publishdate) {
            newErrors.publishdate = "*Required field"
        }

        // if (!GIFs) {
        //     newErrors.GIFs = "*Please upload Gif";
        // }

        if ((!shortdescriptionMr || shortdescriptionMr.trim() === '<p><br></p>') ||
            (!descriptionMr || descriptionMr.trim() === '<p><br></p>') ||
            (!shortdescription || shortdescription.trim() === '<p><br></p>') ||
            (!descriptionEn || descriptionEn.trim() === '<p><br></p>'))
        //  ||(!shortdescriptionHn || shortdescriptionHn.trim() === '<p><br></p>') ||
        //     (!descriptionHn || descriptionHn.trim() === '<p><br></p>')) 
        {
            newErrors.description = "*Both short and full descriptions in English and Marathi are required";
        }

        setErrors(newErrors);
        if (newErrors) {
            document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
        }
        return Object.keys(newErrors).length === 0;
    };

    // for add video
    const EditVideo = async (status) => {
        const isvalid = validate();
        if (isvalid) {
            setLoader(true);
            let payload = {
                contentId: videoData?.contentId,
                contentType: videotype?.value,
                title: titleEn,
                titleLang: { en: titleEn, mr: titleMr, hin: titleHn },
                description: descriptionMr,
                descriptionLang: { en: descriptionEn, mr: descriptionMr, hin: descriptionHn },
                shortDescription: shortdescriptionMr,
                shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
                imgURL: VideoImage,
                imgURLLang: { en: VideoImage, mr: VideoImage, hin: "" },
                videoURL: Video,
                videoURLLang: { en: Video, mr: Video, hin: "" },
                gifURL: GIFs,
                gifURLLang: { en: GIFs, mr: GIFs, hin: GIFs },
                categories: selectedCategory,
                courseTeacherId: author,
                priorityNo: 100,
                tags: tags.map((item) => item.label),
                isHome: true,
                contentCategory: category?.label,
                tagsCategory: selectedCategory?.label,
                createdById: userState.name,
                moods: mood?.label,
                moodId: mood?.value,
                isLandscape: false,
                updatedBy: userState?.userId,
                toPublishDate: publishdate,
                isPublish: status,
                pageId: pageTitle?.value ? pageTitle?.value : "",
                pageName: pageTitle?.label ? pageTitle?.label : "",
                pageNameLang: pageTitle?.Lang,
            };

            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/content/updateContent`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify(payload),
                });

                if (response) {
                    const responseData = await response.json();
                    if (responseData.message === 'Updated Successfully') {
                        toast.success('Updated Successfully');
                        navigate("/dashboard/content-list");
                        setLoader(false);

                    }
                    else if (responseData.message === "Content already exist") {
                        toast.error('Content already exist');
                        setLoader(false);
                    }
                    else {
                        throw new Error(responseData.message || 'Failed to update content');
                        setLoader(false);
                    }
                } else {
                    throw new Error('Network response was not ok.');
                    setLoader(false);
                }
            } catch (error) {
                console.error("Error while creating content:", error);
                setLoader(false);
            }
        }
    }

    const handleReactS3UltraVideoImg = (url) => {
        if (url) {
            setVideoImage(url);
        }
    }

    const uploadOnCloudFlare = async (data) => {
        SetVideo(data?.preview);
    }

    const handleReactS3UltraGIF = (url) => {
        setGIFs(url);
    }


    //React Select
    const Option = (props) => {
        return (
            <div>
                <components.Option {...props}>
                    <input
                        type="checkbox"
                        checked={props.isSelected}
                        onChange={() => null}
                    />{" "}
                    <label>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    return (
        <div className="AddVideo outletPadding">
            <ToastContainer />
            <Container>

                <Row>
                    <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
                        <Icon.ArrowLeft className="me-2" />Back
                    </Button>
                </Row>

                <h3 className='text-center d-flex justify-content-center align-items-center'>
                    <Icon.PlayBtn size={24} className='me-2' />Update Video
                </h3>


                <Row className="mt-4" >
                    <Col md={6} >
                        <Form.Group className="mb-3">
                            <Form.Label>Title in English<span>*</span></Form.Label>
                            <Form.Control
                                id="titleEn"
                                placeholder="Enter Title"
                                value={titleEn}
                                onChange={(e) => {
                                    if (
                                        !e.target.value.match(/[\u0900-\u097F]/
                                        ) &&
                                        e.target.value.trim()
                                    ) {
                                        setTitleEn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleEn(e.target.value);
                                    }
                                }}
                            />
                            {error.titleEn && <p className="errMsg">{error.titleEn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Title in Marathi<span>*</span></Form.Label>
                            <Form.Control
                                id="titleMr"
                                placeholder="शीर्षक प्रविष्ट करा"
                                value={titleMr}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleMr(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleMr(e.target.value);
                                    }
                                }}

                            />
                            {error.titleMr && <p className="errMsg">{error.titleMr}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={4} className="off">
                        <Form.Group className="mb-3">
                            <Form.Label>Title in Hindi</Form.Label>
                            <Form.Control
                                id="titleHn"
                                placeholder="शीर्षक दर्ज करें"
                                value={titleHn}
                                onChange={(e) => {
                                    const marathiRegex = /^[\u0900-\u097F\s@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""]+$/;
                                    if (marathiRegex.test(e.target.value) && e.target.value.trim()) {
                                        setTitleHn(e.target.value);
                                    } else if (e.target.value.length === 0) {
                                        setTitleHn(e.target.value);
                                    }
                                }}
                            />
                            {error.titleHn && <p className="errMsg">{error.titleHn}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Video Image<span>*</span></Form.Label> <br />
                            {!VideoImage ?
                                <>
                                    <label htmlFor="fileInput" id="uploadBtn">
                                        <Icon.CloudArrowUp size={20} className="me-2" />
                                        Upload Image
                                    </label>

                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        id="fileInput"
                                        style={{ display: "none" }}
                                        onChange={(event) => {
                                            setFileEventVideoImg(event);
                                        }} />
                                    <p id='imgNote'>Recommended image resolution - 4/5 (1080*1350 px)</p>
                                    {error.VideoImage && <p className="errMsg">{error.VideoImage}</p>}
                                </>
                                :

                                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                    <Card className="uploadedFile my-1 me-3">
                                        <img src={VideoImage} style={{ width: 60, height: 60 }} className='mx-auto' />
                                        <Icon.XCircleFill className='removeIcon' size={18}
                                            onClick={() => setVideoImage("")} />
                                    </Card>
                                </div>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Video<span>*</span></Form.Label> <br />
                            {
                                !Video ?
                                    <>
                                        <CloudeFlareVideoUpload onFileUpload={uploadOnCloudFlare} orientation={"portrait"} />
                                        {<p id='imgNote'>Recommended Video resolution - 9/16 (1080*1920 px)</p>}
                                        {error.Video && <p className="errMsg">{error.Video}</p>}
                                    </>
                                    :
                                    <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                        <Card className="d-flex uploadedFile justify-content-center align-items-center me-4">
                                            <a target="_blank" href={Video}>
                                                <Icon.PlayBtnFill style={{ width: 60, height: 60 }} />
                                            </a>
                                            <Icon.XCircleFill className='removeIcon' size={18}
                                                onClick={() => SetVideo("")} />
                                        </Card>
                                    </div>
                            }
                        </Form.Group>
                    </Col>


                    <Col md={6}>
                        <Form.Group className="mb-3" id="VideoImage">
                            <Form.Label>Upload GIF</Form.Label> <br />
                            {!GIFs ?
                                <>
                                    <label htmlFor="fileInputs" id="uploadBtn">
                                        <Icon.CloudArrowUp size={20} className="me-2" />
                                        Upload GIF
                                    </label>

                                    <input
                                        type="file"
                                        accept="image/jpeg, image/png, image/gif"
                                        id="fileInputs"
                                        style={{ display: "none" }}
                                        onChange={(event) => {
                                            setFileEventGIF(event);
                                        }} />
                                    {/* {error.GIFs && <p className="errMsg">{error.GIFs}</p>} */}
                                </>
                                :

                                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                                    <Card className="uploadedFile my-1 me-3">
                                        <img src={GIFs} style={{ width: 60, height: 60 }} className='mx-auto' />
                                        <Icon.XCircleFill className='removeIcon' size={18}
                                            onClick={() => setGIFs("")} />
                                    </Card>

                                </div>
                            }
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Feed Category<span>*</span></Form.Label>
                            <Select
                                id="category"
                                value={category}
                                options={getContentCategory}
                                onChange={(option) => setCategory(option)}
                            />
                            {error.category && <p className="errMsg">{error.category}</p>}
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Tag Category<span>*</span></Form.Label>
                            <Select
                                id="selectedCategory"
                                isMulti={false}
                                options={tagCategoryList}
                                value={selectedCategory}
                                onChange={(option) => {
                                    setSelectedCategory(option);
                                    setTags([])
                                }}
                            />
                            {error.selectedCategory && (
                                <p className="errMsg">{error.selectedCategory}</p>
                            )}
                        </Form.Group>
                    </Col>


                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Tags Name<span>*</span></Form.Label>
                            <ReactSelect
                                id="tags"
                                isDisabled={selectedCategory === ""}
                                options={TagList}
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                components={{
                                    Option,
                                }}
                                onChange={handleChange}
                                allowSelectAll={true}
                                value={tags}
                            />
                            {error.tags && <p className="errMsg">{error.tags}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Mood<span>*</span></Form.Label>
                            <Select
                                placeholder="Select mood"
                                value={mood}
                                onChange={(option) => setMood(option)}
                                options={moodData}
                            />
                            {error.mood && <p className="errMsg">{error.mood}</p>}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Page</Form.Label>
                            <Select
                                isMulti={false}
                                options={pageData}
                                value={pageTitle}
                                onChange={(option) => {
                                    setpageTitle(option);
                                }}
                            />
                            {/* {error.pageTitle && (<p className="error">{error.pageTitle}</p>)} */}
                        </Form.Group>
                    </Col>

                    <Col md={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Publish Date<span>*</span></Form.Label>
                            <Form.Control
                                className=""
                                type="date"
                                name="publish Date"
                                value={publishdate}
                                min={new Date().toISOString().split("T")[0]}
                                onKeyDown={(event) => event.preventDefault()}
                                onChange={(e) => setPublishDate(e.target.value)}
                            />
                            {error.publishdate && <p className="errMsg">{error.publishdate}</p>}
                        </Form.Group>
                    </Col>



                    {/* for description  */}
                    <ChromeTab
                        shortdescriptionMr={shortdescriptionMr}
                        setShortDescriptionMr={setShortDescriptionMr}
                        descriptionMr={descriptionMr}
                        setDescriptionMr={setDescriptionMr}
                        shortdescription={shortdescription}
                        setShortDescription={setShortDescription}
                        descriptionEn={descriptionEn}
                        setDescriptionEn={setDescriptionEn}
                        shortdescriptionHn={shortdescriptionHn}
                        setShortDescriptionHn={setShortDescriptionHn}
                        descriptionHn={descriptionHn}
                        setDescriptionHn={setDescriptionHn}
                    />

                    {error.description && (
                        <p className="errMsg mt-1">{error.description}</p>
                    )}


                </Row>

                {loader ? (
                    <Spinner variant="primary" />
                ) : (
                    <>
                        <ButtonComponent
                            onSave={EditVideo}
                            onSaveAndPublish={EditVideo}
                            saveLabel="Update"
                            savePublish="Update & Publish"
                        />
                    </>
                )}
            </Container>

            {
                fileEventVideoImg &&
                <ReactS3Ultra fileEvent={fileEventVideoImg} fileType={process.env.REACT_APP_IMAGE}
                    directory="VideoImage" onFileUpload={handleReactS3UltraVideoImg} type={4 / 5} />
            }

            {
                fileEventGIF &&
                <ReactS3Ultra fileEvent={fileEventGIF} fileType={process.env.REACT_APP_IMAGE}
                    directory="GIFImage" onFileUpload={handleReactS3UltraGIF} type={1 / 1} />
            }

        </div>
    );
}

export default EditVideos;




