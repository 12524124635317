import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap';
import * as Icon from "react-bootstrap-icons"
import { useDispatch, useSelector } from 'react-redux';
import Skeleton from "../../Common/Skeleton";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../../Common/ActiveKeySlice';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import DeleteModel from '../../Common/Model/DeleteModel';
import PaginationSequence from '../../Common/Pagination/PaginationSequence';
import PublishModel from '../../Common/Model/PublishModel';
import UnPublishModel from '../../Common/Model/UnPublishModel';

const Mood = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userState = useSelector((state) => state?.cmsLogin?.userData.data);

    const [listLoader, setListLoader] = useState(false);
    const [isdelete, setIsDelete] = useState(false);

    const [deleteId, setDeleteId] = useState("");
    const [Mood, setMood] = useState("")
    const [searchField, setSearchField] = useState("");

    const [getAllMood, setGetAllMood] = useState([])

    const [pageNo, setPageNo] = useState(1);
    const [docPerPage, setDocPerPage] = useState(25);
    const [noOfPages, setNoOfPages] = useState();

    const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
    const [publishmoodId, setPublishMoodId] = useState(null);

    // Unpublish video variables
    const [isUnpublish, setIsUnpublish] = useState(false);
    const [unpublishId, setUnpublishId] = useState("");

    // function for pagination
    const handlePageChange = (pageNumber) => {
        setPageNo(pageNumber);
    };

    const handleDocsPerPage = (docsPageProp) => {
        setPageNo(1)
        setDocPerPage(docsPageProp);
    }

    // Function to handle opening the publish modal
    const openPublishModal = (moodId) => {
        setPublishMoodId(moodId);
        setIsPublishModalOpen(true);
    };

    // Function to handle opening the unpublish modal
    const openUnpublishModal = (moodId) => {
        setUnpublishId(moodId);
        setIsUnpublish(true);
    };

    // Function to handle cancelling the unpublish modal
    const closeUnpublishModal = () => {
        setIsUnpublish(false);
    };

    const handlePublishModalCancel = () => {
        setIsPublishModalOpen(false);
    };


    useEffect(() => {
        getMood()
    }, [docPerPage, pageNo])

    // for gett all mood data
    const getMood = async () => {
        setListLoader(true)
        const payload = {
            title: searchField ? searchField : "",
            documentsPerPage: docPerPage,
            page: pageNo,
        }
        await fetch(process.env.REACT_APP_BASE_URL + "/mood/getAllMoodsPGN", {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
            body: JSON.stringify(payload),
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.message === "Authorization failed / Forbidden") {
                    setListLoader(true);
                    dispatch(setLogout(null));
                    dispatch(setActiveKey(null));

                    localStorage.removeItem('persist:root');
                    navigate('/');
                } else {
                    setGetAllMood(response)
                    setNoOfPages(response?.noOfPages);
                    setListLoader(false)
                }

            })
            .catch((error) => {
                setListLoader(false)
                console.log("error", error);
            })
    }

    // for custome hook call
    const dependencies = [Mood ? Mood : "", searchField ? searchField : ""];
    useDebouncedApiCall(getMood, dependencies, setListLoader);

    // for delete mood
    const deleteMood = async () => {
        const payload = {
            moodId: deleteId
        }
        await fetch(process.env.REACT_APP_BASE_URL + "/mood/deleteMood", {
            method: "POST",
            headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
            body: JSON.stringify(payload),
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.message === "Mood deleted successfully") {
                    toast.success("Deleted successfully.")
                    setIsDelete(false)
                    getMood()
                }
            }).catch((error) => {
                console.log("error", error);
            })
    }

    // for toggle
    const handlePublishAction = async (moodId, isPublish) => {

        try {
            setIsPublishModalOpen(false);
            setIsUnpublish(false);

            const result = await fetch(process.env.REACT_APP_BASE_URL + "/mood/publishMood", {
                method: "POST",
                headers: { "Content-Type": "application/json", 'Authorization': `Bearer ${userState.token}` },
                body: JSON.stringify({
                    moodId: moodId,
                    isPublish: isPublish
                }),
            });

            const response = await result.json();
            if (response) {
                getMood();
            } else {
                console.log("Error toggling publish status");
            }
        } catch (error) {
            console.log(error);
        }
    };
    // Function to handle confirming publish modal
    const handlePublishModalConfirm = async () => {
        await handlePublishAction(publishmoodId, true);
    };

    // Function to handle confirming unpublish modal
    const handleUnpublishConfirm = async () => {
        await handlePublishAction(unpublishId, false);
    };

    return (
        <>
            <div className="outletPadding">

                <PublishModel
                    isOpen={isPublishModalOpen}
                    onClose={handlePublishModalCancel}
                    onConfirm={handlePublishModalConfirm}
                    title="Mood"
                    loader={listLoader}
                />
                <UnPublishModel
                    isOpen={isUnpublish}
                    onClose={closeUnpublishModal}
                    onConfirm={handleUnpublishConfirm}
                    title="Mood"
                    loader={listLoader}
                />

                {/* Delete Mood */}
                <DeleteModel
                    show={isdelete}
                    onHide={() => setIsDelete(false)}
                    onDelete={deleteMood}
                    loader={listLoader}
                    label="Mood"
                />
                <Container>
                    <Row className="d-flex justify-content-between">
                        <Col md={4} className='mb-3 mb-md-0'>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search here"
                                name="search"
                                value={searchField}
                                onChange={(e) => {
                                    if (e.target.value.trim()) {
                                        setSearchField(e.target.value)
                                        setPageNo(1);
                                    } else if (e.target.value.length === 0) {
                                        setSearchField(e.target.value)
                                        setPageNo(1);
                                    }
                                }}
                            />
                        </Col>

                        <Col md={4} className="d-flex justify-content-end align-items-center">
                            <div className='d-flex flex-row'>
                                <Button className="primaryBtn w-100 my-auto" onClick={() => navigate("/dashboard/add-mood")}>
                                    <Icon.PlusCircle className="me-2" size={15} />
                                    Add Mood
                                </Button>
                            </div>
                        </Col>

                    </Row>

                    {/* category List Table */}
                    <div className="outer-wrapper mx-auto mt-4">
                        <div className="table-wrapper" style={{ maxHeight: "68vh" }}>
                            <table>
                                <thead>
                                    <th>Sr. No.</th>
                                    <th>Mood Image</th>
                                    <th>Mood Name</th>
                                    <th>Priority</th>
                                    <th>Publish</th>
                                    <th>Action</th>
                                </thead>
                                {listLoader ? (
                                    <Skeleton rows={10} cols={6} />
                                ) : getAllMood?.data?.length > 0 && getAllMood?.data !== null && getAllMood?.data !== undefined ? (
                                    getAllMood?.data?.map((itm, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{pageNo !== 1 ? (<>{index + 1 + docPerPage * (pageNo - 1)}</>) : (<>{index + 1}</>)}</td>
                                                <td><img src={itm?.moodImgURL} alt="Mood Icon" width={40} /></td>
                                                <td style={{ width: "18vw" }}>{itm?.moodTitle}</td>
                                                <td>{itm?.priorityNo}</td>
                                                <td style={{ width: '120px' }}>
                                                    {itm.isPublish ? (
                                                        <div
                                                            style={{
                                                                backgroundColor: "#8FBC8F",
                                                                borderRadius: "8px",
                                                                padding: "4px 8px",
                                                                color: "white",
                                                                textAlign: "center",
                                                                cursor: "pointer"
                                                            }}
                                                            onClick={() => openUnpublishModal(itm.moodId)}
                                                        >
                                                            Published
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="switch"
                                                            onClick={() => openPublishModal(itm.moodId)}
                                                        >
                                                            <input type="checkbox" checked={itm.publish} onChange={() => { }} />
                                                            <span className="slider round" onClick={() => openPublishModal(itm.moodId)}></span>
                                                        </div>
                                                    )}

                                                </td>
                                                <td className="d-flex">
                                                    <img src={View} alt="View" className="icon me-3"
                                                        onClick={() => navigate("/dashboard/view-mood", {
                                                            state: { mood: itm }
                                                        })
                                                        } />

                                                    <img src={Edit} alt="Edit" className="icon me-3"
                                                        onClick={() => navigate("/dashboard/edit-mood", { state: { item: itm } })}
                                                    />

                                                    <img src={Delete} alt="Delete" className='icon'
                                                        onClick={() => {
                                                            setIsDelete(true);
                                                            setDeleteId(itm?.moodId);
                                                        }} />
                                                </td>

                                            </tr>
                                        );
                                    })
                                ) : (
                                    <p className="noDataFound">No Data Found</p>
                                )}
                            </table>
                        </div>
                    </div>

                    <PaginationSequence
                        data={getAllMood?.count}
                        pageNo={pageNo}
                        noOfPages={noOfPages}
                        handlePageChange={handlePageChange}
                        handleDocsPerPage={handleDocsPerPage}
                        docPerPage={docPerPage}
                    />
                </Container>

            </div>
        </>
    )
}

export default Mood
