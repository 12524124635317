import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button, Spinner, Card } from 'react-bootstrap';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import * as Icon from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { getAllCategories } from '../../Common/Categories';
import { useNavigate } from 'react-router-dom';
import ReactS3Ultra from '../../../Utils/ReactS3Ultra';
import ChromeTab from '../../Common/langugageToggle/ChromeTab';
import ButtonComponent from '../../Common/Button/ButtonComponent';
import { moodOption, productTypeList } from '../../../Utils/GlobalConfigs';
import useGetPageList from '../../Common/Reuse/useGetPageList';
import useGetMoodList from '../../Common/Reuse/useGetMoodList';

const AddShoppee = () => {

  const userState = useSelector((state) => state?.cmsLogin?.userData?.data);
  const productCategories = useSelector(state => state?.categories?.categoryList?.data?.data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // call custom hook for page title
  const { pageData } = useGetPageList();
  const { moodData } = useGetMoodList();

  const [createLoader, setCreateLoader] = useState(false);
  const [name, setName] = useState('');
  const [img, setImg] = useState("");
  const [marketPlaceURL, setMarketPlaceURL] = useState('');
  const [category, setCategory] = useState("");
  const [productType, setProductType] = useState('');
  const [tagCategory, setTagCategory] = useState('');
  const [tags, setTags] = useState([]);
  const [descriptionEn, setDescriptionEn] = useState('');
  const [descriptionMr, setDescriptionMr] = useState('');
  const [descriptionHn, setDescriptionHn] = useState('');
  const [shortdescription, setShortDescription] = useState("");
  const [shortdescriptionMr, setShortDescriptionMr] = useState("");
  const [shortdescriptionHn, setShortDescriptionHn] = useState("");
  const [sourcename, setSourceName] = useState("");
  const [error, setErrors] = useState({});
  const [tagCatgoriesList, setTagCategoriesList] = useState([]);
  const [tagList, setTagsList] = useState([]);
  const [fileEvent, setFileEvent] = useState('');
  const [bannerImages, setBannerImages] = useState("");
  const [fileEventBanner, setFileEventBanner] = useState('');
  const [sourceIconURL, setSourceIconURL] = useState("");
  const [fileSourseIconBanner, setFileSourceIconBanner] = useState('');
  const [mood, setMood] = useState('');
  const [pageTitle, setpageTitle] = useState('')


  useEffect(() => {
    getTagCategories();
    dispatch(getAllCategories({ payload: { categoryType: "product" }, token: userState.token }))
  }, [])

  useEffect(() => {
    if (tagCategory) {
      getTagList();
    }
  }, [tagCategory])

  // get category data
  const getShoppyCategory = productCategories !== undefined && productCategories !== null && productCategories?.length > 0 ? productCategories?.map((itm) => {
    return {
      label: itm.categoryName,
      value: itm.categoryName
    };
  }) : [];

  const getTagCategories = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllTagCat', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${userState?.token}` },
      });
      const responseData = await response.json();
      let arr = [];
      responseData?.data?.map((item) => {
        arr.push({
          ...item,
          label: item.tagCategoryName,
          value: item.tagCategoryId,
        });
      });
      setTagCategoriesList(arr);
    }
    catch (Err) {
      console.log("Err while getting tag categories", Err);
    }
  }

  const getTagList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/cms/getAllTags2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({ tagCategory: tagCategory?.tagCategoryId ? tagCategory?.tagCategoryId : tagCategory?.tagCategoryName })
      });
      const responseData = await response.json();
      let arr = [];
      responseData?.data?.map((item) => {
        arr.push({
          ...item,
          label: item.tag,
          value: item.tagId,
        });
      });
      setTagsList(arr);
    }
    catch (Err) {
      console.log("Err while getting tag categories", Err);
    }
  }

  const createValidate = () => {
    let newErrors = {};

    if (!name) {
      newErrors.name = "*Product name is Required";
    }

    // if (!pageTitle) {
    //   newErrors.pageTitle = "*Page Title is Required";
    // }

    if (!img) {
      newErrors.img = "*Please upload prodcut image";
    }

    if (!bannerImages) {
      newErrors.bannerImages = "*Select Atleast one  one image";
    }

    if (!category) {
      newErrors.category = "*Product category is required";
    }

    if (!marketPlaceURL) {
      newErrors.marketPlaceURL = "*MarketPlace URL is Required";
    }

    if (!productType) {
      newErrors.productType = "*Product type is required";
    }

    if (!tagCategory) {
      newErrors.tagCategory = "*Tag category is required";
    }
    if (!sourcename) {
      newErrors.sourcename = "*Source name is required";
    }

    if (tags.length === 0) {
      newErrors.tags = "*Tags are Required";
    }
    if (!mood) {
      newErrors.mood = "*Required Field";
    }

    if ((!shortdescriptionMr || shortdescriptionMr.trim() === '<p><br></p>') ||
      (!descriptionMr || descriptionMr.trim() === '<p><br></p>') ||
      (!shortdescription || shortdescription.trim() === '<p><br></p>') ||
      (!descriptionEn || descriptionEn.trim() === '<p><br></p>'))
    //  ||(!shortdescriptionHn || shortdescriptionHn.trim() === '<p><br></p>') ||
    //     (!descriptionHn || descriptionHn.trim() === '<p><br></p>')) 
    {
      newErrors.description = "*Both short and full descriptions in English and Marathi are required";
    }

    setErrors(newErrors);
    if (newErrors) {
      document.getElementById(Object.keys(newErrors)[0])?.scrollIntoView({ behavior: 'smooth' });
    }
    return Object.keys(newErrors).length === 0;
  }

  const createShoppee = async (status) => {
    const isValid = createValidate();
    if (!isValid) return
    setCreateLoader(true);
    if (isValid) {
      await fetch(process.env.REACT_APP_BASE_URL + '/shoppee/createShoppee', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.token}` },
        body: JSON.stringify({
          name: name,
          imageURL: img,
          bigImageURL: bannerImages,
          marketPlaceURL: marketPlaceURL,
          category: category.label,
          productType: productType?.value,
          priorityNo: 100,
          tags: tags.map((row) => row.label),
          tagsCategory: tagCategory?.label,
          descrption: descriptionMr,
          descriptionLang: { en: descriptionEn, mr: descriptionMr, hin: descriptionHn },
          shortDescription: shortdescriptionMr,
          shortDescriptionLang: { en: shortdescription, mr: shortdescriptionMr, hin: shortdescriptionHn },
          sourceIconURL: sourceIconURL,
          sourceName: sourcename,
          sourcePriceArray: [{ sourceName: sourcename, sourcePrice: 0, marketPlaceURL: sourceIconURL[0] }],
          isHome: true,
          moods: mood?.label,
          moodId: mood?.value,
          createdBy: userState?.data?.user?.name,
          isPublish: status,
          pageId: pageTitle?.value ? pageTitle?.value : "",
          pageName: pageTitle?.label ? pageTitle?.label : "",
          pageNameLang: pageTitle?.Lang,
        })
      }).then((res) => res.json()).then((response) => {
        if (response.message === "shopee Created Successfully") {
          toast.success("Product Created Successfully");
          setCreateLoader(false);
          navigate(-1);
        }
        else if (response.message === "shopee already exist") {
          toast.error("Product already exist");
          setCreateLoader(false);
        }
      }).catch((Err) => {
        console.log("Error While Creating Product", Err);
        setCreateLoader(false);
      })
    }
  }

  const handleReactS3UltraRes = (url) => {
    setImg(url);
  }

  const handleReactS3UltraResBanner = (url) => {
    setBannerImages(url)
  }

  const handleReactS3UltraResSourceIcon = (url) => {
    setSourceIconURL(url)
  }

  return (

    <div className='AddShoppee outletPadding'>
      <ToastContainer />
      <Container>
        <Row>
          <Button className="backBtn w-auto" onClick={() => navigate(-1)}>
            <Icon.ArrowLeft className="me-2" />Back
          </Button>
        </Row>

        <h3 className='text-center d-flex justify-content-center align-items-center'>
          <Icon.BagPlus size={20} className='me-2' />Add Product
        </h3>

        <Row className="mt-4">
          <Col md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>Product Name<span className='errMsg'>*</span></Form.Label>
              <Form.Control placeholder="Enter Name" id="name" value={name} onChange={(e) => {
                if (!e.target.value.match(/[0-9+@#$&%!~=^_:()/\/{}\[\]|/*./\//\-/?<>,;`'""/]/) &&
                  e.target.value.trim()
                ) {
                  setName(e.target.value);
                } else if (e.target.value.length === 0) {
                  setName(e.target.value);
                }
              }} />
              {error.name && <p className="errMsg">{error.name}</p>}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3" id="img">
              <Form.Label>Image<span>*</span></Form.Label> <br />
              {!img ?
                <>
                  <label htmlFor="productImg" id="uploadBtn">
                    <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/gif"
                    id="productImg"
                    style={{ display: "none" }}
                    onChange={(event) => setFileEvent(event)} />
                  <p id='imgNote'>Recommanded image resolution - 1:1</p>
                  {error.img && <p className="errMsg">{error.img}</p>}
                </>
                :
                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                  <Card className="uploadedFile m-1">
                    <img src={img} style={{ width: 60, height: 60 }} className='mx-auto' />
                    <Icon.XCircleFill className='removeIcon' size={18}
                      onClick={() => setImg('')} />
                  </Card>
                </div>
              }
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Banner Image<span>*</span></Form.Label> <br />
              <>
                {!bannerImages ?
                  <>
                    <label htmlFor="fileInput" id="uploadBtn">
                      <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                    </label>
                    <input
                      type="file"
                      accept="image/jpeg, image/png, image/gif"
                      id="fileInput"
                      style={{ display: "none" }}
                      onChange={(event) => setFileEventBanner(event)} />
                    <p id='imgNote'>Recommanded image resolution -320*256px(2:1)</p>
                    {error.bannerImages && <p className="error">{error.bannerImages}</p>}
                  </>
                  :
                  <div className="d-flex" style={{ flexWrap: "wrap" }}>
                    <Card className="uploadedFile m-1">
                      <img src={bannerImages} style={{ width: 60, height: 60 }} className='mx-auto' />
                      <Icon.XCircleFill className='removeIcon' size={18}
                        onClick={() => setBannerImages('')} />
                    </Card>
                  </div>
                }
              </>
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className="mb-3">
              <Form.Label>Product Category<span>*</span></Form.Label>
              <Select id="category"
                value={category}
                options={getShoppyCategory}
                onChange={(option) => setCategory(option)}
              />
              {error.category && <p className="errMsg">{error.category}</p>}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>MarketPlace URL<span className='errMsg'>*</span></Form.Label>
              <Form.Control placeholder="Enter marketplace URL" id="marketPlaceURL" value={marketPlaceURL} onChange={(e) => {
                setMarketPlaceURL(e.target.value)
              }} />
              {error.marketPlaceURL && <p className="errMsg">{error.marketPlaceURL}</p>}
            </Form.Group>
          </Col>

          <Col md={4}>
            <Form.Group className='mb-3'>
              <Form.Label>Source name<span className='errMsg'>*</span></Form.Label>
              <Form.Control placeholder="Enter source name" id="sourceName" value={sourcename} onChange={(e) => {
                setSourceName(e.target.value)
              }} />
              {error.sourcename && <p className="errMsg">{error.sourcename}</p>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3" id="img">
              <Form.Label>Source Icon</Form.Label> <br />
              {!sourceIconURL ?
                <>
                  <label htmlFor="sourceIcon" id="uploadBtn">
                    <Icon.CloudArrowUp size={20} className="me-2" />Upload Image
                  </label>
                  <input
                    type="file"
                    accept="image/jpeg, image/png, image/gif"
                    id="sourceIcon"
                    style={{ display: "none" }}
                    onChange={(event) => setFileSourceIconBanner(event)} />
                </>
                :
                <div className="d-flex" style={{ flexWrap: "wrap" }}>
                  <Card className="uploadedFile m-1">
                    <img src={sourceIconURL} style={{ width: 60, height: 60 }} className='mx-auto' />
                    <Icon.XCircleFill className='removeIcon' size={18}
                      onClick={() => setSourceIconURL('')} />
                  </Card>
                </div>
              }
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Product Type<span>*</span></Form.Label>
              <Select id="productType"
                value={productType}
                options={productTypeList}
                onChange={(option) => setProductType(option)}
              />
              {error.productType && <p className="errMsg">{error.productType}</p>}
            </Form.Group>
          </Col>


          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>Tag Category<span className='errMsg'>*</span></Form.Label>
              <Select id="tagCategory" isMulti={false} options={tagCatgoriesList} isSearchable={true} value={tagCategory}
                placeholder={<div style={{ fontSize: '15px' }}>Select Tag Category</div>}
                onChange={(option) => { setTagCategory(option); }} />
              {error.tagCategory && <p className="errMsg">{error.tagCategory}</p>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className='mb-3'>
              <Form.Label>Tags<span className='errMsg'>*</span></Form.Label>
              <Select id="tags" isDisabled={tagCategory === ""} isMulti={true} options={tagList} isSearchable={true} value={tags}
                closeMenuOnSelect={false} placeholder={<div style={{ fontSize: '15px' }}>Select Tags</div>}
                onChange={(option) => { setTags(option); }} />
              {error.tags && <p className="errMsg">{error.tags}</p>}
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Mood<span>*</span></Form.Label>
              <Select
                placeholder="Select mood"
                value={mood}
                onChange={(option) => setMood(option)}
                options={moodData}
              />
              {error.mood && <div className="error">{error.mood}</div>}
            </Form.Group>
          </Col>

          <Col md={6}>
            <Form.Group className="mb-3">
              <Form.Label>Page</Form.Label>
              <Select
                isMulti={false}
                options={pageData}
                value={pageTitle}
                onChange={(option) => {
                  setpageTitle(option);
                }}
              />
              {/* {error.pageTitle && (<p className="error">{error.pageTitle}</p>)} */}
            </Form.Group>
          </Col>

          {/* for description  */}
          <ChromeTab
            shortdescriptionMr={shortdescriptionMr}
            setShortDescriptionMr={setShortDescriptionMr}
            descriptionMr={descriptionMr}
            setDescriptionMr={setDescriptionMr}
            shortdescription={shortdescription}
            setShortDescription={setShortDescription}
            descriptionEn={descriptionEn}
            setDescriptionEn={setDescriptionEn}
            shortdescriptionHn={shortdescriptionHn}
            setShortDescriptionHn={setShortDescriptionHn}
            descriptionHn={descriptionHn}
            setDescriptionHn={setDescriptionHn}
          />
          {error.description && (
            <p className="errMsg mt-1">{error.description}</p>
          )}

          <Row className='mb-5'>
            {
              createLoader ? <center><Spinner variant='primary' /></center> :
                <>
                  <ButtonComponent
                    onSave={createShoppee}
                    onSaveAndPublish={createShoppee}
                    saveLabel="Save"
                    savePublish="Save & Publish"
                  />
                </>

            }
          </Row>
        </Row>
      </Container>

      {
        fileEvent &&
        <ReactS3Ultra fileEvent={fileEvent} fileType={process.env.REACT_APP_IMAGE}
          directory="productImages" onFileUpload={handleReactS3UltraRes} />
      }

      {
        fileEventBanner &&
        <ReactS3Ultra fileEvent={fileEventBanner} fileType={process.env.REACT_APP_IMAGE}
          directory="productBanners" onFileUpload={handleReactS3UltraResBanner} type={2 / 1} />
      }

      {
        fileSourseIconBanner &&
        <ReactS3Ultra fileEvent={fileSourseIconBanner} fileType={process.env.REACT_APP_IMAGE}
          directory="sourceIcon" onFileUpload={handleReactS3UltraResSourceIcon} />
      }

    </div>
  )
}

export default AddShoppee