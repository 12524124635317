import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Col, Button, Modal, Spinner } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import Skeleton from '../../Common/Skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import View from '../../../Assets/View.svg';
import Edit from '../../../Assets/Edit.svg';
import Delete from '../../../Assets/Delete.svg';
import { setLogout } from '../../Auth/LoginSlice';
import { setActiveKey } from '../../Common/ActiveKeySlice';
import useDebouncedApiCall from '../../Common/Reuse/Debounce';
import DeleteModel from '../../Common/Model/DeleteModel';
import PaginationSequence from '../../Common/Pagination/PaginationSequence';

const Teachers = () => {

  const userState = useSelector((state) => state?.cmsLogin?.userData);
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState([]);
  const [searchField, setSearchField] = useState("");

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(25);
  const [noOfPages, setNoOfPages] = useState();

  const [delId, setDelId] = useState('');
  const [showDel, setShowDel] = useState(false);
  const [createLoader, setCreateLoader] = useState(false);

  useEffect(() => {
    getAllTeachers()
  }, [pageNo, docPerPage])

  // ------- Get All Teacher List ----------------------
  const getAllTeachers = async () => {
    try {
      setLoader(true);
      const response = await fetch(process.env.REACT_APP_BASE_URL + '/priority/titleFilter', {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${userState.data.token}`
        },
        body: JSON.stringify({
          courseTeacherId: '',
          courseCategories: '',
          type: "Teacher",
          title: searchField ? searchField : "",
          documentsPerPage: docPerPage,
          page: pageNo
        })
      })
      const responseData = await response.json();
      setData(responseData);
      setNoOfPages(responseData?.noOfPages);
      setLoader(false);
    } catch (Err) {
      console.log("Err while getting teachers data", Err);
      setLoader(false);
    }
  }

  // for debounding
  const dependencies = [searchField ? searchField : ""];
  useDebouncedApiCall(getAllTeachers, dependencies, setLoader);

  const deleteTeacher = async () => {
    setCreateLoader(true);
    try {
      const result = await fetch(process.env.REACT_APP_BASE_URL + '/course/deleteTeacher', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${userState.data.token}` },
        body: JSON.stringify({ courseTeacherId: delId })
      });
      const response = await result.json();
      if (response.message === "Authorization failed / Forbidden") {
        setLoader(true);
        dispatch(setLogout(null));
        dispatch(setActiveKey(null));

        localStorage.removeItem('persist:root');
        navigate('/');
      } else if (response.message === 'Teacher Deleted Successfully') {
        toast.success('Teacher Deleted Succussfully');
        setShowDel(false);
        setDelId('');
        setCreateLoader(false);
        getAllTeachers();
      }
      else {
        toast.error('Failed to delete, try again');
        setShowDel(false);
        setDelId('');
        setCreateLoader(false);
      }
    } catch (Err) {
      console.log("Err while deleting teacher", Err);
      setCreateLoader(false);
    }
  }


  // function for pagination
  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1)
    setDocPerPage(docsPageProp);
  }


  return (
    <div className='Teachers outletPadding'>

      <DeleteModel
        show={showDel}
        onHide={() => { setShowDel(false); setDelId(''); }}
        onDelete={deleteTeacher}
        loader={createLoader}
        label="Teacher"
      />
      <Container>
        <Row className='justify-content-between'>
          <Col md={4} className="mb-2 mb-sm-0">
            <input
              className="form-control"
              type="text"
              placeholder="Search here"
              name="search"
              value={searchField}
              onChange={(e) => {
                if (e.target.value.trim()) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                } else if (e.target.value.length === 0) {
                  setSearchField(e.target.value)
                  setPageNo(1);
                }

              }}
            ></input>
          </Col>

          <Col md={2} className='d-flex justify-content-end my-auto mt-3 mt-md-0'>
            <Button className='primaryBtn' onClick={() => navigate('/dashboard/add-teacher')}>
              <Icon.PersonCircle className='me-2' size={16} />Add Teacher
            </Button>
          </Col>
        </Row>

        <div className="outer-wrapper mx-auto mt-4">
          <div className="table-wrapper" style={{ maxHeight: '70vh' }}>
            <table>
              <thead>
                <th>Sr.</th>
                <th>Image</th>
                <th>Name</th>
                <th style={{ width: '200px' }}>Qualification</th>
                <th>Status</th>
                <th>Date of Birth</th>
                <th>Action</th>
              </thead>
              {
                loader ? <Skeleton rows={10} cols={7} /> :
                  data?.data !== null && data?.data !== undefined && data?.data.length > 0 ? data?.data?.map((itm, index) => {
                    const { imageURL, firstName, lastName, qualification, status, dob, courseTeacherId } = itm
                    return (

                      <tr key={Math.random() * 999999999}>
                        <td>{pageNo !== 1 ? (
                          <>
                            {' '}
                            {index + 1 + docPerPage * (pageNo - 1)}
                          </>
                        ) : (
                          <>{index + 1}</>
                        )}</td>
                        <td><img src={imageURL} alt="" width={40} /></td>
                        <td>{firstName + ' ' + lastName}</td>
                        <td>{qualification}</td>
                        <td>{status}</td>
                        <td>{moment(dob).format('DD-MM-YYYY')}</td>
                        <td className="d-flex">
                          <img src={View} alt="View" className="icon me-3"
                            onClick={() => navigate("/dashboard/view-teacher", { state: itm })} />

                          <img src={Edit} alt="Edit" className="icon me-3"
                            onClick={() => navigate("/dashboard/edit-teacher", { state: itm })} />

                          <img src={Delete} alt="Delete" className='icon'
                            onClick={() => { setShowDel(true); console.log(courseTeacherId); setDelId(courseTeacherId) }} />
                        </td>
                      </tr>
                    )
                  })
                    : <p className='noDataFound'>No Data Found</p>
              }
            </table>
          </div>
        </div>

        <PaginationSequence
          data={data?.count}
          pageNo={pageNo}
          noOfPages={noOfPages}
          handlePageChange={handlePageChange}
          handleDocsPerPage={handleDocsPerPage}
          docPerPage={docPerPage}
        />

      </Container>
    </div>
  )
}

export default Teachers;