import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeNumberKey: { activeKey: 1, subActivekey: 1, activeTab: 'Dashboard' },
  activeNav: 'subscribed',
  activecourseTab: 'topic'
};

const sidebarSlice = createSlice({
  name: 'activeKey',
  initialState,
  reducers: {
    setActiveKey(state, action) {
      state.activeNumberKey = { ...state.activeNumberKey, ...action.payload }
    },
    setActiveNav: (state, action) => {
      state.activeNav = action.payload;
    },
    setActiveCourseTab: (state, action) => {
      state.activecourseTab = action.payload;
    },

  },
});

export const { setActiveKey, setActiveNav, setActiveCourseTab } = sidebarSlice.actions;
export default sidebarSlice.reducer;